import React, { useState } from "react";
import CustomDataTable from "../../Components/custom/CustomDataTable";
import SideNavigation from "../../Components/layout/SideNavigation";
import TopNavigation from "../../Components/layout/TopNavigation";
import { useNavigate } from "react-router-dom";

const Certifications = () => {
  const navigate = useNavigate();
  const [contents] = useState([]);

  const columns = [
    {
      dataField: "governingBody",
      text: "Governing Body",
      sort: false,
    },
    {
      dataField: "certification",
      text: "Certification",
      sort: false,
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Certifications" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <div className="d-flex justify-content-end p-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        navigate("/add-certifications", {
                          state: {
                            value: "certification",
                            label: "Certifications",
                            pathName: window.location.pathname,
                          },
                        })
                      }
                    >
                      Add New Certifications
                    </button>
                  </div>
                  <CustomDataTable
                    columns={columns}
                    editUrl="/edit-certifications"
                    deleteUrl={"v1/certification"}
                    value="certification"
                    label="Certifications"
                    role="admin"
                    searchQuery={contents}
                    pathName={window.location.pathname}
                    fetchContentsUrl={`v1/certification?`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
