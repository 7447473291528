import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import LoadingScreen from "./LoadingScreen";
import axios from "../../util/axiosInst";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { unknownErrorMessage } from "../../util/constants";

export default function UptodateArticleList({ keyword, onSectionSelected }) {
  const [show, setShow] = useState(false);
  const fetchContentsUrl = `/v1/uptodate/keywordSearch?q=${keyword}`;
  const [loading, setLoading] = useState(false);
  const [loadingArticle, setLoadingArticle] = useState(false);
  const [articleDetails, setArticleDetails] = useState(null);
  const [sections, setSections] = useState([]);
  const [contents, setContents] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const fColumns = [
    { dataField: "contentMeta.id", text: "Id", sort: false, hidden: true },
    { dataField: "title", text: "Title", sort: true },
    {
      dataField: "",
      text: "Action",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <span
              role="button"
              className="p-1 btn-success"
              onClick={() => fetchArticleById(row.contentMeta.id, true)}
            >
              Show Sections
            </span>

            <span
              role="button"
              className="ml-2 p-1 btn-secondary"
              onClick={async () => {
                await fetchArticleById(row.contentMeta.id, false);
                handleShow();
              }}
            >
              View Article
            </span>
          </>
        );
      },
    },
  ];
  const sColumns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "title", text: "Title", sort: true },
    {
      dataField: "",
      text: "Action",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <span
              role="button"
              className="p-1 btn-success"
              onClick={(e) => {
                onSectionSelected({
                  ...row,
                  articleId: articleDetails?.contentMeta.id,
                });
              }}
            >
              Select
            </span>
          </>
        );
      },
    },
  ];
  const fetchArticleById = async (articleId, setSection) => {
    try {
      setLoadingArticle(true);
      setArticleDetails(null);
      setSections([]);
      const { data } = await axios.get(
        `/v1/uptodate/getArticle?id=${articleId}`
      );
      setArticleDetails(data);
      if (setSection) {
        setSections(data.sectionsHtml);
      }
      setLoadingArticle(false);
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };

  const fetchContents = async () => {
    try {
      setContents([]);
      setArticleDetails(null);
      setSections([]);
      setLoading(true);
      const { data } = await axios.get(`${fetchContentsUrl}`);
      setLoading(false);
      setContents(data.results);
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  useEffect(() => {
    fetchContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);
  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen centered>
        <Modal.Header closeButton>
          <Modal.Title>{articleDetails?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{ __html: articleDetails?.fullHtml }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <BootstrapTable
        remote={{ pagination: false, filter: false, sort: false }}
        bootstrap4
        noDataIndication={loading ? <LoadingScreen /> : <h6>No Data</h6>}
        keyField="contentMeta.id"
        data={contents}
        columns={fColumns}
      />
      {loadingArticle && <LoadingScreen />}
      {sections.length > 0 && (
        <>
          <div className="row">
            <div className="col-sm-6">
              <span role="button">
                <h5>
                  {`${articleDetails.title} - Sections Available`}
                  <i
                    className="ml-1 fa fa-window-close"
                    onClick={() => setSections([])}
                  ></i>
                </h5>
              </span>
            </div>
          </div>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={sections}
            columns={sColumns}
          />
        </>
      )}
    </>
  );
}
