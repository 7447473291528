import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import axios from "../../util/axiosInst";
import { AddVideoSchema } from "../../util/schema";
import { SwalMessage } from "../../util/utils";
import TextInput from "../common/TextInput";
import MediaPreview from "../custom/MediaPreview";
import SideNavigation from "../layout/SideNavigation";
import TopNavigation from "../layout/TopNavigation";
import { Spinner } from "react-bootstrap";
import LoadingScreen from "../custom/LoadingScreen copy";

const AddVideo = () => {
  // variables and constants
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [editId, setEditId] = useState(null);
  const [video, setVideo] = useState("");
  const [loading, setLoading] = useState(
    location.pathname.includes("edit") ? true : false
  );
  const [blogCategories, setBlogCategories] = useState([]);

  //validation schema
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddVideoSchema(video)),
  });
  const isCategoryAvailable = getValues("categoryId");

  // getting list of categories
  const getBlogCategories = () => {
    axios.get(`v1/public/blog/categories/no-blogs`).then(({ data }) => {
      setBlogCategories(data?.results);
      //setValue("categoryId", data?.results[0]?.id);
    });
  };
  //get the image url
  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/exercise/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
        //setValue("banner", data.url);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  //form submit
  const onSubmit = async (formData) => {
    try {
      const dataToSend = {
        ...formData,
        createdBy: location.state.id,
        type: "post",
      };
      if (editId) {
        await axios.patch(`/v1/exercise/${editId}`, dataToSend);
        SwalMessage("success", "Updated successfully");
      } else {
        await axios.post("/v1/exercise", dataToSend);
        SwalMessage("success", `Created successfully.`);
      }
      navigate(`/video/${location.state.id}`, {
        state: location.state.pathName,
      });
    } catch (e) {}
  };
  //getting details of blog
  const getBlogDetails = async () => {
    if (params && params.id) {
      setEditId(params.id);
      const { data } = await axios.get(`/v1/exercise/${params.id}`);
      setValue("categoryId", data?.categoryId);
      if (data.video) {
        setVideo(data.video);
      } else {
        setVideo(data.videoUrl);
      }
      delete data.id;
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      reset(data);
    }
  };

  useEffect(() => {
    getBlogCategories();

    reset();
    getBlogDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //show the banner image
  const FileUploadComponent = ({ fileName, accept }) => {
    const file = useWatch({
      control,
      name: fileName,
    });
    const [upload, setUpload] = useState(0);
    return (
      <>
        <div
          className={`w-100 image-wraper mb-3 ${
            errors.video ? "border-danger" : ""
          }`}
        >
          <div className="media-container">
            <MediaPreview
              fileName={file || watch(fileName)}
              type={accept === "image/*" ? "image" : "video"}
            />
          </div>
          <div className="upload-control">
            <ReactS3Uploader
              accept={accept}
              id={fileName}
              className="d-none"
              getSignedUrl={getSignedUrl}
              signingUrlWithCredentials={true}
              uploadRequestHeaders={{}}
              onProgress={(e) => {
                setUpload(e);
              }}
              onFinish={async (result, file) => {
                const { data } = await axios.get("/v1/s3/get-presigned-url", {
                  params: {
                    fileName: `/exercise/${file.name}`,
                  },
                });
                clearErrors(["video", "link"]);
                setVideo(data.url);
                setValue(fileName, data.url);
              }}
              contentDisposition="auto"
              scrubFilename={(filename) =>
                filename.replace(/[^\w\d_\-.]+/gi, "")
              }
              autoUpload={true}
            />
            <label htmlFor={fileName}>
              <span className="border btn btn-primary" role="button">
                {fileName === "photo"
                  ? "Upload Hospital Photo"
                  : upload > 0
                  ? `${upload > 0 ? `${upload}%` : ""} Uploading`
                  : "Upload Video"}
              </span>
              {getValues(fileName) &&
                (fileName === "photo" || fileName === "video") && (
                  <span
                    className="border ml-3 btn btn-danger"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setVideo("");
                      setValue(fileName, "");
                    }}
                  >
                    Remove
                  </span>
                )}
            </label>
          </div>
        </div>
        {errors.video && (
          <div className="text-danger text-start">{errors.video.message}</div>
        )}
      </>
    );
  };
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <TopNavigation title="Videos" />
        <div>
          <div className="wrapper mb-4 flex-fill">
            <section className="h-100">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="d-flex justify-content-between">
                        {params && params.id ? (
                          <h4>Edit Video</h4>
                        ) : (
                          <h4>Create Video</h4>
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="bg-white border rounded row p-3">
                      <LoadingScreen />
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-9">
                        <div className="bg-white border rounded row p-3">
                          <div className="row container-fluid">
                            <div className="col-md-12 mb-3">
                              <div className="row mb-3">
                                <div className="mb-3 col-sm-6">
                                  <label>Video Title</label>
                                  <input
                                  placeholder="Title"
                                    className={clsx(
                                      "form-control shadow-none px-2 ",
                                      errors.title && "is-invalid"
                                    )}
                                    {...register("title")}
                                    label="Video Title"
                                  />
                                  <div className="invalid-feedback">
                                    {errors.title?.message}
                                  </div>
                                </div>
                                <div className="mb-3 col-sm-6">
                                  <label
                                    className="form-label"
                                    style={{ marginBottom: ".48rem" }}
                                  >
                                    Categories
                                  </label>

                                  {
                                    <select
                                      className={clsx(
                                        `form-control ${"text-secondary shadow-none"} ${
                                          getValues("categoryId") &&
                                          "text-black "
                                        }`,
                                        errors.categoryId &&
                                          !isCategoryAvailable &&
                                          "is-invalid"
                                      )}
                                      {...register("categoryId")}
                                      autofocus="false"
                                    >
                                      <option
                                        value=""
                                        hidden
                                        className="text-black "
                                        autofocus="false"
                                      >
                                        Select Category
                                      </option>
                                      {blogCategories.map((cat, index) => {
                                        return (
                                          <option
                                            className="text-black"
                                            value={cat._id}
                                            key={index}
                                          >
                                            {cat.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  }
                                  <div className="invalid-feedback">
                                    {errors.categoryId?.message}
                                  </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <div
                                    style={{
                                      display: getValues("video")
                                        ? "none"
                                        : "block",
                                    }}
                                  > 
                                    <label className="form-label">
                                      Video Url
                                    </label>
                                    <input
                                    placeholder="Video Url"
                                      errors={errors}
                                      className={clsx(
                                        "form-control px-2 shadow-none",
                                        errors.link && "is-invalid"
                                      )}
                                      value={getValues("link")}
                                      onChange={(e) => {
                                        setVideo(e.target.value);
                                        clearErrors(["video", "link"]);
                                        setValue("link", e.target.value);
                                      }}
                                      label="Video Url"
                                      name={`link`}
                                    />
                                  </div>
                                </div>
                                {(getValues("link") === undefined ||
                                  getValues("link")?.length == 0) && (
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">Video</label>
                                    <div className="text-center text-light rounded">
                                      <FileUploadComponent
                                        name={`video`}
                                        fileName={`video`}
                                        accept={"video/*"}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="mb-3 ml-3 p-3">
                            <input
                              id="flexCheckDefault"
                              className="form-check-input"
                              type="checkbox"
                              {...register("active")}
                            />
                            <label className="mb-1" for="flexCheckDefault">
                              Active
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="border rounded bg-white mb-3 p-3">
                          <div className="d-flex flex-column">
                            {/* {/* <BannerImage control={control} /> */}
                            <Link
                              to={`/video/${location.state.id}`}
                              className="btn btn-outline-danger mt-3"
                              state={location.state.pathName}
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              className="btn btn-outline-primary mt-3"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
