import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import axios from "../../util/axiosInst";
import SideNavigation from "../../Components/layout/SideNavigation";
import TopNavigation from "../../Components/layout/TopNavigation";
import TextInput from "../../Components/common/TextInput";

const CertificationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const AddCertificationSchema = yup.object({
    governingBody: yup.string().required("Governing Body is a required field"),
    certification: yup.string().required("Certification is a required field"),
  });
  const onSubmit = async (formData) => {
    const { governingBody, certification } = formData;
    if (!location?.pathname?.includes("edit")) {
      await axios.post(`/v1/certification`, {
        governingBody,
        certification,
      });
    } else {
      await axios.patch(`/v1/certification/${id}`, {
        governingBody,
        certification,
      });
    }
    navigate("/certifications");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddCertificationSchema),
  });
  const getCertificationDetails = async () => {
    if (location?.pathname?.includes("edit")) {
      const { data } = await axios.get(`v1/certification/${id}`);
      reset(data);
    }
  };

  useEffect(() => {
    getCertificationDetails();
  }, []);
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <div className="container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row bg-white border  rounded p-3">
                  <div className="col-lg-6">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Governing Body"
                      mandatory={true}
                      name="governingBody"
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Certification"
                      mandatory={true}
                      name="certification"
                    />
                  </div>
                </div>
                <Button className="mt-3" type="submit">
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationForm;
