export const hospitalAffiliatedInitialValue = {
  hospital_name: "",
  hospital_city: "",
};
export const educationInitValue = {
  degree: "",
  college: "",
  completionyear: "",
};
export const certificationIntitialValue = {
  label: "",
  value: "",
};
export const trainingInitValue = {
  trainingType: "",
  location: "",
  completionyear: "",
};
export const BusinessHoursInitValue = {
  startDay: "",
  endDay: "",
  startTime: "",
  endTime: "",
};
export const ClinicInitValue = {
  name: "",
  addressLine: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
  businessHours: [BusinessHoursInitValue],
};
