import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
// import Dashboard from "./pages/Dashboard";
import ContentForm from "./Components/forms/ContentForm";
import UserForm from "./Components/forms/UserForm";
import AdminStaff from "./pages/AdminStaff";
import Newsletter from "./pages/Newsletter";
import Providers from "./pages/Providers";
import AthleticTrainer from "./pages/AthleticTrainer";
import EducationContents from "./pages/EducationContents";
import Surgion from "./pages/OrthoSurgion";
import Patient from "./pages/Patient";
import PersonalTrainer from "./pages/PersonalTrainer";
import Physicians from "./pages/Physicians";
import Therapists from "./pages/Therapists";
import WellnessProvider from "./pages/WellnessProvider";

import "react-toastify/dist/ReactToastify.css";
import AddPost from "./Components/post/AddPost";
import RehabilitationCenter from "./pages/RehabilitationCenter";
import RehabilitationCenterForm from "./Components/forms/Rehabilitation Center/RehabilitationCenterForm";
import ListPost from "./Components/post/ListPost";
import SymptomCheckerResults from "./pages/SymptomCheckerResults";
import AthleticTrainerForm from "./Components/forms/Atheletic Trainer/AthleticTrainerForm";
import AddVideo from "./Components/videos/AddVideo";
import ListVideo from "./Components/videos/ListVideo";
import Gym from "./pages/Gym";
import GymProfile from "./Components/forms/Gym/GymForm";
import Hospital from "./pages/Hospital";
import HospitalProfile from "./Components/forms/Hospital/HospitalForm";
import NursePractitioner from "./pages/NursePractitioner";
import PersonalTrainerProfile from "./Components/forms/Personal Trainer/PersonalTrainerForm";
import WellnessProviderProfile from "./Components/forms/Wellness Provider/WellnessProviderForm";
import PhysicalTherapistProfile from "./Components/forms/Physical Therapist/PhysicalTherapistForm";

import ProviderProfile from "./Components/forms/Provider/ProviderForm";
import "./styles/style.css";
import ClaimRequestProfile from "./pages/claimRequestProfile";
import NeuroSurgeon from "./pages/NeuroSurgeon";
import ClinicProfile from "./Components/forms/Clinic/ClinicForm";
import Clinic from "./pages/Clinic";
import Chiropractor from "./pages/Chiropractor";
import Painmanagementspecialist from "./pages/Painmanagementspecialist";
import Massagetherapist from "./pages/Massagetherapist";
import LatestBlogsList from "./pages/LatestBlogsList";
import LatestBlogProfile from "./pages/LatestBlogProfile";
import NewsletterProfile from "./pages/NewsletterProfile";
import UserInformation from "./pages/UserInformation";
import TestimoniesList from "./pages/TestimoniesList";
import TestimoniesForm from "./pages/TestimoniesForm";
import PressReleasesList from "./pages/PressReleasesList";
import PressReleasesForm from "./pages/PressReleasesForm";
import Certifications from "./pages/certifications/Certifications";
import CertificationForm from "./pages/certifications/CertificationForm";
import Userresults from "./pages/Userresults";

function RequireAuth({ children }) {
  let isAuthenticated = localStorage.getItem("mop-admin-token");
  return isAuthenticated ? children : <Navigate to={"/login"} />;
}

function AuthRoute({ children }) {
  let isAuthenticated = localStorage.getItem("mop-admin-token");
  return !isAuthenticated ? children : <Navigate to={"/"} />;
}

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/contents"
          element={
            <RequireAuth>
              <EducationContents />
            </RequireAuth>
          }
        />
        <Route
          path="/createcontent"
          element={
            <RequireAuth>
              <ContentForm />
            </RequireAuth>
          }
        />
        <Route
          path="/editcontent/:id"
          element={
            <RequireAuth>
              <ContentForm />
            </RequireAuth>
          }
        />
        <Route
          path="/adminstaff"
          element={
            <RequireAuth>
              <AdminStaff />
            </RequireAuth>
          }
        />
        <Route
          path="/createadmin"
          element={
            <RequireAuth>
              <UserForm />
            </RequireAuth>
          }
        />
        <Route
          path="/editadmin/:id"
          element={
            <RequireAuth>
              <UserForm />
            </RequireAuth>
          }
        />
        <Route
          path="/patients"
          element={
            <RequireAuth>
              <Patient />
            </RequireAuth>
          }
        />
        <Route
          path="/editpatient/:id"
          element={
            <RequireAuth>
              <UserForm />
            </RequireAuth>
          }
        />

        <Route
          path="/testimonies"
          element={
            <RequireAuth>
              <TestimoniesList />
            </RequireAuth>
          }
        />
        <Route
          path="/orthosurgion"
          element={
            <RequireAuth>
              <Surgion />
            </RequireAuth>
          }
        />
        <Route
          path="/neurosurgeon"
          element={
            <RequireAuth>
              <NeuroSurgeon />
            </RequireAuth>
          }
        />
        <Route
          path="/post/:id"
          element={
            <RequireAuth>
              <ListPost />
            </RequireAuth>
          }
        />
        <Route
          path="/add/post"
          element={
            <RequireAuth>
              <AddPost />
            </RequireAuth>
          }
        />
        <Route
          path="/edit/post/:id"
          element={
            <RequireAuth>
              <AddPost />
            </RequireAuth>
          }
        />
        <Route
          path="/video/:id"
          element={
            <RequireAuth>
              <ListVideo />
            </RequireAuth>
          }
        />
        <Route
          path="/claimrequestprofile/:id"
          element={
            <RequireAuth>
              <ClaimRequestProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-testimonies"
          element={
            <RequireAuth>
              <TestimoniesForm />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-testimonies/:id"
          element={
            <RequireAuth>
              <TestimoniesForm />
            </RequireAuth>
          }
        />
        <Route
          path="/add/video"
          element={
            <RequireAuth>
              <AddVideo />
            </RequireAuth>
          }
        />
        <Route
          path="/edit/video/:id"
          element={
            <RequireAuth>
              <AddVideo />
            </RequireAuth>
          }
        />
        <Route
          path="/addProvider"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/addPersonalTrainer"
          element={
            <RequireAuth>
              <PersonalTrainerProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/addWellnessProvider"
          element={
            <RequireAuth>
              <WellnessProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/addPhysicalTherapist"
          element={
            <RequireAuth>
              <PhysicalTherapistProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-athletic-trainer"
          element={
            <RequireAuth>
              <AthleticTrainerForm />
            </RequireAuth>
          }
        />
        <Route
          path="/add-gym"
          element={
            <RequireAuth>
              <GymProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/personal_trainer/:id"
          element={
            <RequireAuth>
              <PersonalTrainerProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/wellness_provider/:id"
          element={
            <RequireAuth>
              <WellnessProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/physical_therapist/:id"
          element={
            <RequireAuth>
              <PhysicalTherapistProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-athletic-trainer/:id"
          element={
            <RequireAuth>
              <AthleticTrainerForm />
            </RequireAuth>
          }
        />
        <Route
          path="/gym/:id"
          element={
            <RequireAuth>
              <GymProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/gym"
          element={
            <RequireAuth>
              <Gym />
            </RequireAuth>
          }
        />
        <Route
          path="/add-hospital"
          element={
            <RequireAuth>
              <HospitalProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/hospital/:id"
          element={
            <RequireAuth>
              <HospitalProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/hospital"
          element={
            <RequireAuth>
              <Hospital />
            </RequireAuth>
          }
        />
        <Route
          path="/add-clinic"
          element={
            <RequireAuth>
              <ClinicProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-chiropractor"
          element={
            <RequireAuth>
              <PhysicalTherapistProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-pain-management-specialist"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-Massage-therapist"
          element={
            <RequireAuth>
              <WellnessProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/chiropractor/:id"
          element={
            <RequireAuth>
              <PhysicalTherapistProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/athletic_trainer/:id"
          element={
            <RequireAuth>
              <AthleticTrainerForm />
            </RequireAuth>
          }
        />
        <Route
          path="/pain_management_specialist/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/massage_therapist/:id"
          element={
            <RequireAuth>
              <WellnessProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/clinic/:id"
          element={
            <RequireAuth>
              <ClinicProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/clinic"
          element={
            <RequireAuth>
              <Clinic />
            </RequireAuth>
          }
        />
        <Route
          path="/chiropractor"
          element={
            <RequireAuth>
              <Chiropractor />
            </RequireAuth>
          }
        />
        <Route
          path="/pain-management-specialist"
          element={
            <RequireAuth>
              <Painmanagementspecialist />
            </RequireAuth>
          }
        />
        <Route
          path="/massage-therapist"
          element={
            <RequireAuth>
              <Massagetherapist />
            </RequireAuth>
          }
        />
        <Route
          path="/add-athletic-trainer/:id"
          element={
            <RequireAuth>
              <AthleticTrainerForm />
            </RequireAuth>
          }
        />
        <Route
          path="/orthosurgionDetails/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/neurosurgeon/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/physician"
          element={
            <RequireAuth>
              <Physicians />
            </RequireAuth>
          }
        />
        <Route
          path="/primary_care/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/wellnessProvider"
          element={
            <RequireAuth>
              <WellnessProvider />
            </RequireAuth>
          }
        />
        <Route
          path="/physicalTherapist"
          element={
            <RequireAuth>
              <Therapists />
            </RequireAuth>
          }
        />
        <Route
          path="/therapist"
          element={
            <RequireAuth>
              <Therapists />
            </RequireAuth>
          }
        />
        <Route
          path="/therapistDetails/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/personalTrainer"
          element={
            <RequireAuth>
              <PersonalTrainer />
            </RequireAuth>
          }
        />

        <Route
          path="/wellnessProviders"
          element={
            <RequireAuth>
              <WellnessProvider />
            </RequireAuth>
          }
        />

        <Route
          path="/Physical-Therapists"
          element={
            <RequireAuth>
              <Therapists />
            </RequireAuth>
          }
        />
        <Route
          path="/nursePractitioner"
          element={
            <RequireAuth>
              <NursePractitioner />
            </RequireAuth>
          }
        />
        <Route
          path="/nursePractitioner/:id"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/wellnessProvider/:id"
          element={
            <RequireAuth>
              <WellnessProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/athleticTrainers"
          element={
            <RequireAuth>
              <AthleticTrainer />
            </RequireAuth>
          }
        />
        <Route
          path="/athleticTrainers/:id"
          state="athleticTrainers"
          element={
            <RequireAuth>
              <ProviderProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Navigate to={"/adminstaff"} />
            </RequireAuth>
          }
        />
        <Route
          path="/rehabilitation-center"
          element={
            <RequireAuth>
              <RehabilitationCenter />
            </RequireAuth>
          }
        />
        <Route
          path="/hospital/:id"
          element={
            <RequireAuth>
              <HospitalProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/add-rehabilitation-center"
          element={
            <RequireAuth>
              <RehabilitationCenterForm />
            </RequireAuth>
          }
        />

        <Route
          path="/add-hospital"
          element={
            <RequireAuth>
              <HospitalProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/rehabilitation_center/:id"
          element={
            <RequireAuth>
              <RehabilitationCenterForm />
            </RequireAuth>
          }
        />

        <Route
          path="/hospital"
          element={
            <RequireAuth>
              <Hospital />
            </RequireAuth>
          }
        />
        <Route
          path="/latest-blogs"
          element={
            <RequireAuth>
              <LatestBlogsList />
            </RequireAuth>
          }
        />
        <Route
          path="/add-latest-blogs"
          element={
            <RequireAuth>
              <LatestBlogProfile />
            </RequireAuth>
          }
        />
        <Route
          path={`/edit-latest-blogs/:id`}
          element={
            <RequireAuth>
              <LatestBlogProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/news"
          element={
            <RequireAuth>
              <PressReleasesList />
            </RequireAuth>
          }
        />
        <Route
          path="/add-news"
          element={
            <RequireAuth>
              <PressReleasesForm />
            </RequireAuth>
          }
        />
        <Route
          path={`/edit-news/:id`}
          element={
            <RequireAuth>
              <PressReleasesForm />
            </RequireAuth>
          }
        />
        <Route
          path="/certifications"
          element={
            <RequireAuth>
              <Certifications />
            </RequireAuth>
          }
        />
        <Route
          path="/userresults"
          element={
            <RequireAuth>
              <Userresults />
            </RequireAuth>
          }
        />
        <Route
          path="/add-certifications"
          element={
            <RequireAuth>
              <CertificationForm />
            </RequireAuth>
          }
        />
        <Route
          path={`/edit-certifications/:id`}
          element={
            <RequireAuth>
              <CertificationForm />
            </RequireAuth>
          }
        />
        <Route
          path="/newsletter"
          element={
            <RequireAuth>
              <Newsletter />
            </RequireAuth>
          }
        />
        <Route
          path="/providers"
          element={
            <RequireAuth>
              <Providers />
            </RequireAuth>
          }
        />
        <Route
          path="/newsletter/:tab"
          element={
            <RequireAuth>
              <Newsletter />
            </RequireAuth>
          }
        />
        <Route
          path="/add-newsletter"
          element={
            <RequireAuth>
              <NewsletterProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/edit-newsletter/:id"
          element={
            <RequireAuth>
              <NewsletterProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/users-information"
          element={
            <RequireAuth>
              <UserInformation />
            </RequireAuth>
          }
        />
        <Route
          path="/results/:id"
          element={
            <RequireAuth>
              <SymptomCheckerResults />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
