import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Image from "../../util/Image";
import axios from "../../util/axiosInst";
export default function MediaPreview({ fileName, type, gender, blog }) {
  const [video, setVideo] = useState("");
  const getVideo = async () => {
    if (fileName && type == "video") {
      const result = await axios.get(`/v1/s3/signed/url?fileName=${fileName}`);
      setVideo(result?.data?.url);
    }
  };
  useEffect(() => {
    if (fileName !== undefined && type === "video") {
      getVideo();
    }
  }, [fileName]);
  return (
    <>
      {fileName ? (
        type === "image" ? (
          fileName?.includes("https://dev-mop.s3.us-east-2.amazonaws.com//") ||
          !fileName?.length ? (
            <Image
              src={fileName}
              type="provider"
              alt={fileName}
              gender={gender}
              height="200px"
              width={blog && "90%"}
            ></Image>
          ) : fileName.includes("/static/media") ? (
            <img
              src={fileName}
              alt={fileName}
              height="200px"
              width={blog && "90%"}
            ></img>
          ) : (
            <Image
              src={fileName}
              alt={fileName}
              height="200px"
              width={blog && "90%"}
            ></Image>
          )
        ) : (
          video !== "" && (
            <video controls className="w-100">
              <source src={video}></source>
            </video>
          )
        )
      ) : fileName ? (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : null}
    </>
  );
}
