import React, { useState } from "react";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";

const Userresults = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("User Results");
  const [contents] = useState([]);

  const downloadExcel = async () => {
    let response;
    response = await axios.get(
      `v1/evaluation/history-report?&sortBy=createdAt:desc&&limit=100000`
    );
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ age,gender,result}) => ({
          Age: age,
          Gender: gender,
          Condition: result.conditions.name,
          "Confidence %" :result.conditions.confidence
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD HH:mm:ss")}.xlsx`);
  };

  const columns = [
    {
      dataField: "age",
      text: "Age",
      sort: false,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: false,
    },
    {
      dataField: "result.conditions.name",
      text: "Condition",
      sort: false,
    },
    {
      dataField: "result.conditions.confidence",
      text: "Confidence %",
      sort: false,
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="User Results" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
              <div className="d-flex justify-content-end mb-3">
                  <button
                    type="button"
                    className="btn btn-primary ms-3 rounded"
                    onClick={() => downloadExcel()}
                    style={{ borderRadius: 0 }}
                  >
                    Download as Excel
                  </button>
                </div>
                <div className="box-content">
                  <CustomDataTable
                    columns={columns}
                    searchQuery={contents}
                    fetchContentsUrl={`v1/evaluation/history-report?`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userresults;
