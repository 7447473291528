import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../util/axiosInst";
import {
  deleteSuccessMessage,
  unknownErrorMessage,
} from "../../util/constants";
import LoadingScreen from "./LoadingScreen";

const CustomDataTable = ({
  columns,
  fetchContentsUrl,
  editUrl,
  type,
  searchQuery,
  deleteUrl,
  value,
  label,
  pathName,
  role = "",
  setTotalRecord,
  setexcelsort
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [hideId, setHideId] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("updatedAt:desc");
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [contents, setContents] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseHide = () => setShowHide(false);
  const handleShowHide = () => setShowHide(true);
  const [hideMsg, setHideMsg] = useState(null);
  const fColumns =
    editUrl || deleteUrl
      ? [
          ...columns,
          {
            dataField: "",
            text: "Action",
            headerStyle: { width: 100 },
            sort: false,
            formatter: (cell, row, rowIndex, formatExtraData) => {
              return (
                <>
                  {editUrl && (
                    <span
                      role="button"
                      onClick={() => handleEditContent(row.id || row._id)}
                    >
                      <i className="fa fa-edit"></i>
                    </span>
                  )}
                  {deleteUrl && (
                    <span
                      role="button"
                      className="p-3"
                      onClick={() => {
                        setDeleteId(
                          !!role && role === "admin" ? row.id : row.userId
                        );
                        handleShow();
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  )}
                  {/* {hideUrl && (
                    <span
                      role="button"
                      className="p-3"
                      onClick={() => {
                        setHideId(
                          !!role && role === "admin" ? row.id : row.userId
                        );
                        handleShowHide();
                      }}
                    >
                      <i className="fa fa-eye"></i>
                    </span>
                  )} */}
                </>
              );
            },
          },
        ]
      : [...columns];
  const blogColumns = [
    ...fColumns,
    {
      dataField: "",
      text: "Posts",

      headerStyle: { width: 100 },
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <span
              role="button"
              onClick={() =>
                navigate(`/post/${row.userId}`, { state: pathName })
              }
            >
              <i className="fa fa-eye"></i>
            </span>
          </>
        );
      },
    },
  ];
  const videoColumns = [
    ...blogColumns,

    {
      dataField: "",
      text: "Videos",
      headerStyle: { width: 100 },
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <span
              role="button"
              onClick={() =>
                navigate(`/video/${row.userId}`, { state: pathName })
              }
            >
              <i className="fa fa-video"></i>
            </span>
          </>
        );
      },
    },
  ];
  const hideShowColumns = [
    ...videoColumns,

    {
      dataField: "hide",
      text: "Hide/Show",
      headerStyle: { width: 100 },
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(cell);
        console.log(row);
        console.log(rowIndex);
        console.log(formatExtraData);
        return (
          <>
            <span
              role="button"
              className={`btn btn-sm ${
                row.hide ? "btn-success" : "btn-danger"
              }`}
              onClick={() => {
                setHideId(row.id);
                setHideMsg(row.hide);
                handleShowHide();
              }}
            >
              {row.hide ? "Show" : "Hide"}
            </span>
          </>
        );
      },
    },
  ];
  const pagination = paginationFactory({
    page: page,
    totalSize: totalSize,
    sizePerPage: limit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  const fetchContents = async () => {
    try {
      setContents([]);
      setLoading(true);

      const { data } =
        searchQuery && searchQuery.length
          ? fetchContentsUrl.includes("viewed-users")
            ? await axios.get(
                `${fetchContentsUrl}page=${page}&limit=${limit}&sortBy=${sort}&search=${searchQuery}`
              )
            : await axios.get(
                `${fetchContentsUrl}page=${page}&limit=${limit}&sortBy=${sort}&name=${searchQuery}`
              )
          : fetchContentsUrl.includes("press-release")
          ? await axios.get(`${fetchContentsUrl}&page=${page}&limit=${limit}`)
          : await axios.get(
              `${fetchContentsUrl}page=${page}&limit=${limit}&sortBy=${sort}`
            );

      setLoading(false);
      if (setTotalRecord) {
        setTotalRecord(data?.totalResults);
      }
      setContents(data?.results);
      setTotalSize(data?.totalResults);
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);
  const onTableChange = async (type, newState) => {
    if (type === "sort") {
      if (setexcelsort) {
      setexcelsort(`${newState.sortField}:${newState.sortOrder}`)
      } 
      setSort(`${newState.sortField}:${newState.sortOrder}`);
    }
    if (type === "pagination") {
      setPage(newState.page);
      setLimit(newState.sizePerPage);
    }
  };
  const handleDeleteContent = async () => {
    if (deleteId) {
      try {
        await axios.delete(`${deleteUrl}/${deleteId}`);
        toast.success(deleteSuccessMessage);
        fetchContents();
        handleClose();
      } catch (error) {
        fetchContents();
        handleClose();
      }
    }
  };
  const handleEditContent = async (id) => {
    navigate(`${editUrl}/${id}`, {
      state: { value: value, label: label, pathName: pathName },
    });
  };
  const handleHideContent = async () => {
    if (hideId) {
      try {
        const data = await axios.patch(`v1/provider/${hideId}/hide`);
        if (data) {
          toast.success(data.data.message);
        }
        fetchContents();
        handleCloseHide();
      } catch (error) {
        fetchContents();
        handleCloseHide();
      }
    }
  };
  useEffect(() => {
    fetchContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sort, fetchContentsUrl, searchQuery]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteContent}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showHide} onHide={handleCloseHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{hideMsg ? "Show" : "Hide"} Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {hideMsg ? "show" : "hide"} this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseHide}>
            Cancel
          </Button>
          <Button
            variant={hideMsg ? "success" : "danger"}
            onClick={handleHideContent}
          >
            {hideMsg ? "Show" : "Hide"}
          </Button>
        </Modal.Footer>
      </Modal>
      <BootstrapTable
        remote={{ pagination: true, filter: false, sort: false }}
        bootstrap4
        noDataIndication={loading ? <LoadingScreen /> : <h6>No Data</h6>}
        keyField="id"
        data={contents}
        bodyClasses="custom-table"
        columns={type === "provider" ? hideShowColumns : fColumns}
        onTableChange={onTableChange}
        pagination={pagination}
      />
    </>
  );
};

export default CustomDataTable;
