import React, { useRef, useState } from "react";
import axios from "../../util/axiosInst";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import UptodateArticleList from "./UptodateArticleList";

export default function UptodateSearch({ onSectionSelected }) {
  const ref = useRef();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const handleSearch = (query) => {
    setIsLoading(true);
    axios.get(`/v1/uptodate/autosuggest?q=${query}`).then(({ data }) => {
      const option = data.results.map((i) => ({
        value: i.searchTerm,
        id: i.searchTerm,
      }));
      setOptions(option);
      setIsLoading(false);
    });
  };
  return (
    <>
      <AsyncTypeahead
        filterBy={() => true}
        ref={ref}
        clearButton
        id={`search-keyword`}
        isLoading={isLoading}
        onChange={(value) => {
          ref.current.blur();
          setSelected(value);
        }}
        minLength={3}
        labelKey="value"
        onSearch={handleSearch}
        options={options}
        placeholder="Search articles"
        renderMenuItemChildren={(option) => (
          <div key={option.id}>
            <span>{option.value}</span>
          </div>
        )}
      />
      {selected && selected.length > 0 && (
        <div className="mt-5">
          <h5>Articles found</h5>
          <UptodateArticleList
            keyword={selected[0].value}
            onSectionSelected={onSectionSelected}
          />
        </div>
      )}
    </>
  );
}
