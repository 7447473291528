import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import TextInput from "../../common/TextInput";
import MediaPreview from "../../custom/MediaPreview";
import Time from "../Time";
import SideNavigation from "../../layout/SideNavigation";
import TopNavigation from "../../layout/TopNavigation";
import SocialMedia from "../../custom/SocialMedia";
import axios from "../../../util/axiosInst";
import WebLinksComponent from "../../custom/WebLinksComponent";
import { Days, timeArray, unknownErrorMessage } from "../../../util/constants";
import { GymSchema } from "../../../util/schema";
import Button from "../../common/Button";
import {
  affiliatedGymsInitValue,
  certificationInitValue,
  classesInitValue,
  hoursOfOperationInitValue,
  leadershipInitValue,
  modalitiesInitValue,
  programInitValue,
  trainersAvailableInitValue,
} from "./init";
import { SwalMessage } from "../../../util/utils";

const GymProfile = () => {
  const [videoUrl, setVideoUrl] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [classesRows, setClassesRows] = useState([classesInitValue]);
  const [hoursOfOperationRows, setHoursOfOperationRows] = useState([
    hoursOfOperationInitValue,
  ]);
  const [dataFromApi, setDataFromApi] = useState({});
  const [provider, setProvider] = useState(null);
  const [programRows, setProgramRows] = useState([programInitValue]);
  const [modalitiesRows, setModalitiesRows] = useState([modalitiesInitValue]);
  const [leadershipRows, setleadershipRows] = useState([leadershipInitValue]);
  const [affiliatedGymsRows, setAffiliatedGyms] = useState([
    affiliatedGymsInitValue,
  ]);
  const [webLinks, setWebLinks] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [error, setError] = useState({});
  const [trainersAvailableRows, setTrainersAvailableRows] = useState([
    trainersAvailableInitValue,
  ]);
  const [select, setSelect] = useState("No");
  const [certification, setCertification] = useState();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GymSchema),
  });
  const location = useLocation();
  const handleValidation = (ind, event) => {
    let errorArray = { index: null, error: "" };
    leadershipRows.forEach((item) => {
      if (item.teamEmail === event) {
        errorArray = { index: ind, error: "email is already taken" };
      }
    });
    setError({ ...errorArray });
  };
  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCertification = async () => {
    const { data } = await axios.get("v1/certification?limit=1000");
    setCertification(data?.results);
  };

  useEffect(() => {
    getCertification();
  }, []);
  useEffect(() => {
    if (params?.id) getProvider();
    setValue("userType", location?.state?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvider = async () => {
    const { data: providerData } = await axios.get(`/v1/provider/${params.id}`);
    setDataFromApi(providerData);
    setProvider(providerData);
    setValue("firstName", providerData?.firstName);
    setValue("lastName", providerData?.lastName);
    setValue("email", providerData?.email);
    setValue("address", providerData?.address);
    setValue("state", providerData?.state);
    setValue("city", providerData?.city);
    setValue("website", providerData.website);
    setValue("userType", providerData?.userType.replace("_", " "));
    setValue("zipcode", providerData?.zipcode);
    setValue("phoneNumber", providerData?.phoneNumber);
    setValue("googlePlaceId", providerData?.googlePlaceId || "");
    setValue("active", providerData?.active);
    setValue("mopRecommended", providerData?.mopRecommended);
    setValue("videoUrl", providerData?.videoUrl ? providerData.videoUrl : "");
    setValue("facebook", providerData.facebook);
    setValue("twitter", providerData?.twitter);
    setValue("instagram", providerData?.instagram);
    setValue("youtube", providerData?.youtube);
    setValue("linkedin", providerData?.linkedin);
    setValue("askAnExpert", providerData?.askAnExpert);
    setValue("philosophy", providerData?.philosophy);
    setValue("years_in_business", providerData?.years_in_business);
    setValue("about", providerData?.about);
    setValue("npiId", providerData?.npiId);

    if (providerData.askAnExpert === true) {
      setSelect("Yes");
    } else {
      setSelect("No");
    }
    if (providerData.classes_info?.length > 0) {
      setClassesRows(providerData.classes_info);
      setValue("classes_info", providerData.classes_info);
    }
    if (providerData?.webLinks && providerData?.webLinks?.length) {
      const temp = providerData?.webLinks.map((map) => {
        map.label = map?.name;
        map.value = map?.link;
        delete map.name;
        delete map.link;
        return map;
      });
      setValue("webLinks", temp);
      setWebLinks(temp);
    }

    if (providerData.photo) {
      setValue("photo", providerData.photo);
    }
    if (providerData?.banner) {
      setValue("banner", providerData?.banner);
    }
    if (providerData?.video) {
      setValue("video", providerData?.video);
    }
    if (providerData.payment_accepted_methods?.length > 0) {
      setValue(
        "payment_accepted_methods",
        providerData.payment_accepted_methods[0]
      );
    }
    if (providerData.hours_of_operation?.length > 0) {
      if (providerData.hours_of_operation[0] !== "") {
        setHoursOfOperationRows(providerData.hours_of_operation);
        setValue("hours_of_operation", providerData.hours_of_operation);
      } else {
        setHoursOfOperationRows([hoursOfOperationInitValue]);
        setValue("hours_of_operation", [hoursOfOperationInitValue]);
      }
    }
    if (providerData?.certifications && providerData?.certifications?.length) {
      setValue("certifications", providerData?.certifications);
      setCertificationRows(providerData?.certifications);
    }
    if (providerData.affiliations?.length > 0) {
      setAffiliatedGyms(providerData.affiliations);
      setValue("affiliations", providerData.affiliations);
    }
    if (providerData.modalities_available?.length > 0) {
      setModalitiesRows(providerData.modalities_available);
      setValue("modalities_available", providerData.modalities_available);
    }
    if (providerData.trainers_available?.length > 0) {
      if (
        providerData.trainers_available[0] !== "" &&
        providerData.trainers_available[0].trainers_Available
      ) {
        setTrainersAvailableRows(providerData.trainers_available);
        setValue("trainers_available", providerData.trainers_available);
      } else {
        setTrainersAvailableRows([trainersAvailableInitValue]);
        setValue("trainers_available", [trainersAvailableInitValue]);
      }
    }
    if (providerData.programs?.length > 0) {
      setProgramRows(providerData.programs);
      setValue("programs", providerData.programs);
    }

    if (providerData.leadership_team?.length > 0) {
      setleadershipRows(providerData.leadership_team);
      setValue("leadership_team", providerData.leadership_team);
    }
    if (providerData.videoUrl) {
      setVideoUrl(true);
    }
  };

  const getEndTime = (ind, index) => {
    let tempArray = [...timeArray];
    if (watchStartTime) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTime[ind]?.timings[index]?.start_time
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (
        itemToBeRemoved.includes(watchStartTime[ind]?.timings[index]?.end_time)
      ) {
        setValue(`classes_info[${ind}].timings[${index}].end_time`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  const watchStartTime = useWatch({
    control,
    name: "classes_info",
  });
  const getDayDetailsForHours = (id, name, index) => {
    if (!hoursOfOperationRows[index].days.includes(id)) {
      let row = hoursOfOperationRows;
      row[index].days = row[index]?.days ? [...row[index]?.days, id] : [id];
      setHoursOfOperationRows([...row]);
      setValue(name, row);
    } else {
      const filter = hoursOfOperationRows[index].days.filter(
        (data) => data !== id
      );
      let row = getValues("hours_of_operation");
      row[index].days = filter;

      setHoursOfOperationRows([...row]);
      setValue(name, row);
    }
  };
  const watchStartTimeForHours = useWatch({
    control,
    name: "hours_of_operation",
  });
  const getEndTimeForHours = (ind) => {
    let tempArray = [...timeArray];
    if (watchStartTimeForHours) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTimeForHours[ind]?.start_time
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (itemToBeRemoved.includes(watchStartTimeForHours[ind]?.end_time)) {
        setValue(`hours_of_operation[${ind}].end_time`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  const getDayDetails = (id, ind, name, index) => {
    if (!classesRows[index].timings[ind].days.includes(id)) {
      let row = getValues("classes_info");
      row[index].timings[ind].days = row[index]?.timings[ind]?.days
        ? [...row[index].timings[ind].days, id]
        : [id];
      setClassesRows([...row]);
      setValue(name, row[index].timings[ind].days);
    } else {
      const filter = classesRows[index].timings[ind].days.filter(
        (data) => data !== id
      );
      let row = classesRows;
      row[index].timings[ind].days = filter;
      setClassesRows([...row]);
      setValue(name, filter);
    }
  };

  const FileUploadComponent = ({ fileName, accept }) => {
    const [upload, setUpload] = useState(0);
    const file = useWatch({
      control,
      name: fileName,
    });
    return (
      <div className="image-wraper mb-3 h-100">
        <div className="media-container">
          <MediaPreview
            fileName={file || watch(fileName)}
            type={accept === "image/*" ? "image" : "video"}
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            className="d-none"
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            onProgress={(e) => {
              setUpload(e);
            }}
            uploadRequestHeaders={{}}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/contents/${file.name}`,
                },
              });

              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName}>
            {!watch(fileName)?.length ? (
              <span className="border btn btn-primary" role="button">
                {fileName === "photo" && "Upload Photo"}
                {fileName === "video" &&
                  `${upload > 0 ? `${upload}% Uploading` : "Upload Video"}`}
                {fileName === "banner" && "Upload banner"}
              </span>
            ) : (
              <></>
            )}
            {getValues(fileName) &&
              (fileName === "photo" ||
                fileName === "video" ||
                fileName === "banner") && (
                <span
                  className="border ml-3 btn btn-danger"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue(fileName, "");
                  }}
                >
                  Remove
                </span>
              )}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <form
                onSubmit={handleSubmit(async (formData) => {
                  if (select === "No") {
                    formData.askAnExpert = false;
                  }
                  if (!params.id) {
                    formData.isMOPAdminCreated = true;
                  }
                  if (select === "Yes") {
                    formData.askAnExpert = true;
                  }
                  formData.admin = true;
                  formData.lastName = "Gym";
                  formData.userType = location.state.value;
                  if (formData?.webLinks?.length) {
                    formData?.webLinks?.forEach((map) => {
                      map.link = map?.__isNew__ ? "" : map?.value;
                      map.name = map?.label;
                      delete map.value;
                      delete map.label;
                      delete map.__isNew__;
                    });
                  }

                  if (
                    typeof formData.phoneNumber === "undefined" ||
                    formData.phoneNumber === null
                  ) {
                    delete formData.phoneNumber;
                  } else {
                    formData.phoneNumber = parseFloat(formData.phoneNumber);
                  }
                  formData.certifications?.forEach((map, ind) => {
                    if (
                      JSON.stringify(certificationInitValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.certifications.splice(ind, 1);
                    }
                  });
                  formData.programs?.forEach((map, ind) => {
                    if (
                      JSON.stringify(programInitValue) === JSON.stringify(map)
                    ) {
                      formData.programs.splice(ind, 1);
                    }
                  });
                  formData.affiliations?.forEach((map, ind) => {
                    if (
                      JSON.stringify(affiliatedGymsInitValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.affiliations.splice(ind, 1);
                    }
                  });
                  formData.leadership_team?.forEach((map, ind) => {
                    if (
                      JSON.stringify(leadershipInitValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.leadership_team.splice(ind, 1);
                    }
                  });
                  formData.modalities_available?.forEach((map, ind) => {
                    if (
                      JSON.stringify(modalitiesInitValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.modalities_available.splice(ind, 1);
                    }
                  });
                  if (formData?.email?.length <= 0) {
                    delete formData?.email;
                  }
                  if (!params.id) {
                    try {
                      await axios.post("/v1/provider/create/admin", {
                        ...formData,
                        profile_claimed_status: "created",
                      });
                      navigate(location.state.pathName);
                      SwalMessage(
                        "success",
                        `${location?.state?.label} created successfully.`
                      );
                    } catch (error) {
                      toast.error(error.response.data.message);
                    }
                  } else {
                    if (
                      formData.address !== dataFromApi.address ||
                      formData.state !== dataFromApi.state ||
                      formData.city !== dataFromApi.city ||
                      formData.country !== dataFromApi.country ||
                      formData.zipcode !== dataFromApi.zipcode
                    ) {
                      delete formData.googlePlaceId;
                    }
                    try {
                      const { data } = await axios.patch(
                        `/v1/provider/${params.id}`,
                        formData
                      );
                      if (data && data.id) {
                        navigate(`${location.state.pathName}`, {
                          state: provider.planType,
                        });
                        SwalMessage(
                          "success",
                          `${location?.state?.label} updated successfully.`
                        );
                      }
                    } catch (e) {
                      if (e.response?.data?.message) {
                        toast.error(e.response?.data?.message);
                      } else {
                        toast.error(unknownErrorMessage);
                      }
                    }
                  }
                })}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Basic Information</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"photo"}
                                accept={"image/*"}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"banner"}
                                accept={"image/*"}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Gym Name"
                                mandatory={true}
                                name="firstName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Address"
                                name="address"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="State"
                                name="state"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="City"
                                name="city"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Zipcode"
                                name="zipcode"
                                maxlength={5}
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                maxlength={10}
                                errors={errors}
                                register={register}
                                label="Phone Number"
                                name="phoneNumber"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Website"
                                name="website"
                              />
                            </div>
                            {params.id ? (
                              <div className="col-md-4 mb-3">
                                <TextInput
                                  errors={errors}
                                  register={register}
                                  label="Email"
                                  name="email"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="User Type"
                                name="userType"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="NPI #"
                                name="npiId"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Programs</h5>
                        </div>
                        <div className="p-4">
                          <div className="row container-fluid">
                            {programRows.map((programRow, ind) => {
                              return (
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Program Name"
                                    name={`programs[${ind}].programName`}
                                  />

                                  <div className="row mt-4">
                                    {programRows.length - 1 === ind && (
                                      <div
                                        className="col-md-4 text-primary cursor-pointer"
                                        onClick={() => {
                                          const rows = [
                                            ...getValues("programs"),
                                            programInitValue,
                                          ];
                                          setProgramRows(rows);
                                        }}
                                      >
                                        Add new
                                      </div>
                                    )}
                                    <div
                                      className="col-md-1 text-danger cursor-pointer"
                                      onClick={() => {
                                        let rows = [...programRows];
                                        if (rows.length > 1) {
                                          rows.splice(ind, 1);
                                          setProgramRows(rows);
                                          setValue("programs", rows);
                                        } else {
                                          setProgramRows([programInitValue]);
                                          setValue("programs", [
                                            programInitValue,
                                          ]);
                                        }
                                      }}
                                    >
                                      Remove
                                    </div>
                                  </div>
                                </div>
                              );
                            })}{" "}
                          </div>
                        </div>
                      </div>
                      <WebLinksComponent
                        setWebLinks={setWebLinks}
                        setValue={setValue}
                        webLinks={webLinks}
                        getValues={getValues}
                        providers={provider}
                      />
                      <SocialMedia.Certifications
                        certificationIntitialValue={certificationInitValue}
                        setValue={setValue}
                        setCertificationRows={setCertificationRows}
                        getValues={getValues}
                        certificationRows={certificationRows}
                        register={register}
                        watch={watch}
                        certification={certification}
                        errors={errors}
                      />
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="">About</h5>
                        </div>
                        <div className="row container-fluid py-4 px-5">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label for="" className="form-label ">
                              About
                            </label>
                            <textarea
                              name=""
                              id=""
                              cols="3"
                              rows="1"
                              className="form-control flex-fill"
                              {...register("about")}
                            ></textarea>
                          </div>
                          {videoUrl === false &&
                            (getValues("videoUrl") === undefined ||
                              getValues("videoUrl")?.length === 0) && (
                              <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">
                                  Short video about me
                                </label>
                                <div className=" p-4 text-center text-light rounded">
                                  <FileUploadComponent
                                    fileName={"video"}
                                    accept={"video/*"}
                                  />
                                </div>
                              </div>
                            )}
                          <div className="col-md-6 mb-3">
                            <label for="" className="form-label ">
                              Gym Philosophy
                            </label>
                            <TextInput
                              mandatory={false}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              name="philosophy"
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label for="" className="form-label fw-bold">
                              VideoURL
                            </label>
                            <TextInput
                              errors={errors}
                              register={register}
                              mandatory={false}
                              name="videoUrl"
                              setVideoUrl={setVideoUrl}
                              setValue={setValue}
                              disabled={getValues("video")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Modalities Available</h5>
                        </div>
                        <div className="p-4">
                          <div className="row container-fluid">
                            {modalitiesRows.map((modalitiesRow, ind) => {
                              return (
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Modalities Available"
                                    name={`modalities_available[${ind}].modalities_Name`}
                                  />

                                  <div className="row mt-4">
                                    {modalitiesRows.length - 1 === ind && (
                                      <div
                                        className="col-md-4 text-primary cursor-pointer"
                                        onClick={() => {
                                          const rows = [
                                            ...getValues(
                                              "modalities_available"
                                            ),
                                            modalitiesInitValue,
                                          ];
                                          setModalitiesRows(rows);
                                        }}
                                      >
                                        Add new
                                      </div>
                                    )}
                                    <div
                                      className="col-md-1 text-danger cursor-pointer"
                                      onClick={() => {
                                        let rows = [...modalitiesRows];
                                        if (rows.length > 1) {
                                          rows.splice(ind, 1);
                                          setModalitiesRows(rows);
                                          setValue(
                                            "modalities_available",
                                            rows
                                          );
                                        } else {
                                          setModalitiesRows([
                                            modalitiesInitValue,
                                          ]);
                                          setValue("modalities_available", [
                                            modalitiesInitValue,
                                          ]);
                                        }
                                      }}
                                    >
                                      Remove
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Leadership team</h5>
                        </div>
                        <div className="p-4">
                          {leadershipRows.map((leadershipRow, ind) => {
                            return (
                              <div className="row container-fluid">
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Name"
                                    name={`leadership_team[${ind}].teamName`}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Title"
                                    name={`leadership_team[${ind}].teamTitle`}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={error}
                                    register={register}
                                    label="Email"
                                    className={
                                      error && error.index === ind
                                        ? "border-b border-danger"
                                        : ""
                                    }
                                    isEmail={true}
                                    setValue={setValue}
                                    dataChange={(name, event) =>
                                      handleValidation(ind, event)
                                    }
                                    name={`leadership_team[${ind}].teamEmail`}
                                  />

                                  {error && error.index === ind && (
                                    <span className="text-danger">
                                      {error.error}
                                    </span>
                                  )}

                                  <div className="row mt-4">
                                    {leadershipRows.length - 1 === ind && (
                                      <div
                                        className="col-md-4 text-primary cursor-pointer"
                                        onClick={() => {
                                          const rows = [
                                            ...getValues("leadership_team"),
                                            leadershipInitValue,
                                          ];
                                          setleadershipRows(rows);
                                        }}
                                      >
                                        Add new
                                      </div>
                                    )}
                                    <div
                                      className="col-md-1 text-danger cursor-pointer"
                                      onClick={() => {
                                        let rows = [...leadershipRows];
                                        if (rows.length > 1) {
                                          rows.splice(ind, 1);
                                          setleadershipRows(rows);
                                          setValue("leadership_team", rows);
                                        } else {
                                          setleadershipRows([
                                            leadershipInitValue,
                                          ]);
                                          setValue("leadership_team", [
                                            leadershipInitValue,
                                          ]);
                                        }
                                      }}
                                    >
                                      Remove
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="">Trainers Available</h5>
                        </div>
                        <div className="py-4">
                          <div className="row container-fluid">
                            {trainersAvailableRows.map(
                              (trainersAvailableRow, ind) => {
                                return (
                                  <div className="col-md-6 mb-3">
                                    <TextInput
                                      errors={errors}
                                      register={register}
                                      label="Trainers Available"
                                      name={`trainers_available[${ind}].trainers_Available`}
                                    />
                                    <div className="row mt-4">
                                      {trainersAvailableRows.length - 1 ===
                                        ind && (
                                        <div
                                          className="col-md-4 text-primary cursor-pointer"
                                          onClick={() => {
                                            const rows = [
                                              ...getValues(
                                                "trainers_available"
                                              ),
                                              trainersAvailableInitValue,
                                            ];
                                            setTrainersAvailableRows(rows);
                                          }}
                                        >
                                          Add new
                                        </div>
                                      )}

                                      <div
                                        className="col-md-1 text-danger cursor-pointer"
                                        onClick={() => {
                                          let rows = [
                                            ...getValues("trainers_available"),
                                          ];
                                          if (rows.length > 1) {
                                            rows.splice(ind, 1);
                                            setTrainersAvailableRows(rows);
                                            setValue(
                                              "trainers_available",
                                              rows
                                            );
                                          } else {
                                            setTrainersAvailableRows([
                                              trainersAvailableInitValue,
                                            ]);
                                            setValue("trainers_available", [
                                              trainersAvailableInitValue,
                                            ]);
                                          }
                                        }}
                                      >
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Classes Info</h5>
                        </div>
                        <div className="p-4">
                          {classesRows.map((classRow, ind) => {
                            return (
                              <>
                                <div className="row mb-3">
                                  <div className="col-md-6 mb-3">
                                    <TextInput
                                      errors={errors}
                                      register={register}
                                      label="Classes Taught"
                                      name={`classes_info[${ind}].taught`}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <TextInput
                                      errors={errors}
                                      register={register}
                                      label="Class Instructor"
                                      name={`classes_info[${ind}].instructor`}
                                    />
                                  </div>
                                  {/* <div className="col-md-6 mb-3">
                                    <TextInput
                                      errors={errors}
                                      register={register}
                                      label="Classes Location"
                                      name={`classes_info[${ind}].location`}
                                    />
                                  </div> */}
                                </div>
                                <div className="py-3">
                                  <h5 className="mb-0">Classes Timings</h5>
                                </div>
                                <div className="row">
                                  {classRow?.timings?.map((day, index) => {
                                    return (
                                      <>
                                        <div className="col-md-4 mb-3">
                                          <div className="row">
                                            {Days?.map((muscleGroup) => {
                                              return (
                                                <div
                                                  className="col-auto pe-0 mb-3"
                                                  onClick={() => {
                                                    getDayDetails(
                                                      muscleGroup.value,
                                                      index,
                                                      `classes_info[${ind}].timings[${index}].days`,
                                                      ind
                                                    );
                                                  }}
                                                  key={muscleGroup.id}
                                                >
                                                  <div
                                                    className={
                                                      day?.days?.includes(
                                                        muscleGroup.value
                                                      )
                                                        ? "education-active bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                                        : "bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                                    }
                                                    id={
                                                      muscleGroup.evaluationType
                                                    }
                                                  >
                                                    <p className="text-center small mb-0">
                                                      {muscleGroup.value}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>

                                        <div className="col-md-4 mb-1">
                                          <label>Start Time</label>
                                          <Time
                                            register={register}
                                            options={[...timeArray]}
                                            name={`classes_info[${ind}].timings[${index}].start_time`}
                                          />
                                        </div>
                                        <div className="col-md-4 mb-1">
                                          <label>End Time</label>
                                          <Time
                                            register={register}
                                            disabledEndTimes={getEndTime(
                                              ind,
                                              index
                                            )}
                                            options={[...timeArray]}
                                            name={`classes_info[${ind}].timings[${index}].end_time`}
                                          />
                                        </div>
                                        <div className="row mb-3">
                                          {classesRows[ind].timings.length -
                                            1 ===
                                            index && (
                                            <div
                                              className="col-md-3 text-primary cursor-pointer"
                                              onClick={() => {
                                                const rows = {
                                                  timings: [
                                                    ...getValues(
                                                      "classes_info"
                                                    )[ind].timings,
                                                    {
                                                      days: [],
                                                      start_time: "",
                                                      end_time: "",
                                                    },
                                                  ],
                                                };
                                                let businessHoursRow =
                                                  classesRows;
                                                businessHoursRow[ind] = rows;
                                                setClassesRows([
                                                  ...businessHoursRow,
                                                ]);
                                              }}
                                            >
                                              Add Class Timings
                                            </div>
                                          )}
                                          <div
                                            className="col-md-3 text-danger cursor-pointer"
                                            onClick={() => {
                                              let rows = [
                                                ...classesRows[ind].timings,
                                              ];
                                              if (rows.length > 1) {
                                                rows.splice(index, 1);
                                                let businessHoursRow =
                                                  classesRows;
                                                businessHoursRow[ind].timings =
                                                  rows;
                                                setClassesRows([
                                                  ...businessHoursRow,
                                                ]);
                                                setValue(
                                                  `classes_info[${ind}].timings`,
                                                  businessHoursRow[0].timings
                                                );
                                              } else {
                                                let businessHoursRow =
                                                  classesRows;

                                                businessHoursRow[ind].timings =
                                                  [
                                                    {
                                                      days: [],
                                                      start_time: "",
                                                      end_time: "",
                                                    },
                                                  ];
                                                setClassesRows([
                                                  ...businessHoursRow,
                                                ]);

                                                setValue(
                                                  `classes_info[${ind}].timings`,
                                                  [
                                                    {
                                                      days: [],
                                                      start_time: "",
                                                      end_time: "",
                                                    },
                                                  ]
                                                );
                                              }
                                            }}
                                          >
                                            Remove Class Timings
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div className="row mb-1">
                                    {classesRows.length - 1 === ind && (
                                      <div
                                        className="col-md-2 p-2 text-primary cursor-pointer"
                                        onClick={() => {
                                          const rows = [
                                            ...getValues("classes_info"),
                                            classesInitValue,
                                          ];
                                          setClassesRows(rows);
                                        }}
                                      >
                                        Add Classes Info
                                      </div>
                                    )}
                                    <div
                                      className="col-md-3 p-2 text-danger cursor-pointer"
                                      onClick={() => {
                                        let rows = [...classesRows];
                                        if (rows.length > 1) {
                                          rows.splice(ind, 1);
                                          setClassesRows(rows);
                                          setValue("classes_info", rows);
                                        } else {
                                          setClassesRows([classesInitValue]);
                                          setValue("classes_info", [
                                            classesInitValue,
                                          ]);
                                        }
                                      }}
                                    >
                                      Remove Classes Info
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Hours of operation</h5>
                        </div>
                        <div className="p-4">
                          <>
                            <div className="row">
                              {hoursOfOperationRows?.map((day, index) => (
                                <>
                                  <div className="col-md-4 mb-3" key={index}>
                                    <div className="row">
                                      {Days.map((muscleGroup) => {
                                        return (
                                          <div
                                            className="col-auto pe-0 mb-3"
                                            onClick={() => {
                                              getDayDetailsForHours(
                                                muscleGroup.value,
                                                `hours_of_operation`,
                                                index
                                              );
                                            }}
                                            key={muscleGroup.id}
                                          >
                                            <div
                                              className={
                                                day?.days?.includes(
                                                  muscleGroup.value
                                                )
                                                  ? "education-active bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                                  : "bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                              }
                                              id={muscleGroup.evaluationType}
                                            >
                                              <p className="text-center small mb-0">
                                                {muscleGroup.value}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>

                                  <div className="col-md-4 mb-1">
                                    <label>Start Time</label>
                                    <Time
                                      register={register}
                                      name={`hours_of_operation[${index}].start_time`}
                                    />
                                  </div>
                                  <div className="col-md-4 mb-1">
                                    <label>End Time</label>
                                    <Time
                                      register={register}
                                      disabledEndTimes={getEndTimeForHours(
                                        index
                                      )}
                                      name={`hours_of_operation[${index}].end_time`}
                                    />
                                  </div>
                                  <div className="row mb-3">
                                    {hoursOfOperationRows.length - 1 ===
                                      index && (
                                      <div
                                        className="col-md-3 text-primary cursor-pointer"
                                        onClick={() => {
                                          setHoursOfOperationRows([
                                            ...getValues("hours_of_operation"),
                                            {
                                              days: [],
                                              start_time: "",
                                              end_time: "",
                                            },
                                          ]);
                                          setValue(
                                            `hours_of_operation[${index + 1}]`,
                                            {
                                              days: [],
                                              start_time: "",
                                              end_time: "",
                                            }
                                          );
                                        }}
                                      >
                                        Add New Hours Of Operation
                                      </div>
                                    )}
                                    <div
                                      className="col-md-3 text-danger cursor-pointer"
                                      onClick={() => {
                                        let rows = [
                                          ...getValues("hours_of_operation"),
                                        ];
                                        if (rows.length > 1) {
                                          rows.splice(index, 1);
                                          setHoursOfOperationRows(rows);
                                          setValue(`hours_of_operation`, rows);
                                        } else {
                                          setHoursOfOperationRows([
                                            {
                                              days: [],
                                              start_time: "",
                                              end_time: "",
                                            },
                                          ]);

                                          setValue(`hours_of_operation`, [
                                            {
                                              days: [],
                                              start_time: "",
                                              end_time: "",
                                            },
                                          ]);
                                        }
                                      }}
                                    >
                                      Remove Hours Of Operation
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="row container-fluid py-4 px-4">
                          {/* <div className="col-md-6 mb-3 d-flex flex-column">
                            <TextInput
                              errors={errors}
                              register={register}
                              label="Hours of operation"
                              name="hours_of_operation"
                            />
                          </div> */}
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <TextInput
                              errors={errors}
                              register={register}
                              label="Years in Business"
                              name="years_in_business"
                            />
                          </div>
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <TextInput
                              errors={errors}
                              register={register}
                              label="Forms of Payment accepted"
                              name="payment_accepted_methods"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Affiliations</h5>
                        </div>

                        <div className="p-4">
                          <div className="row container-fluid">
                            {affiliatedGymsRows.map(
                              (affiliatedGymsRow, ind) => {
                                return (
                                  <>
                                    <div className="col-md-6">
                                      <TextInput
                                        errors={errors}
                                        register={register}
                                        label="Affiliated Gym Name"
                                        name={`affiliations[${ind}.affiliatedGymsName]`}
                                      />
                                      <div className="row my-3 mb-4">
                                        {affiliatedGymsRows.length - 1 ===
                                          ind && (
                                          <div
                                            className="col-md-4 text-primary cursor-pointer"
                                            onClick={() => {
                                              const rows = [
                                                ...getValues("affiliations"),
                                                affiliatedGymsInitValue,
                                              ];
                                              setAffiliatedGyms(rows);
                                            }}
                                          >
                                            Add new
                                          </div>
                                        )}
                                        <div
                                          className="col-md-1 text-danger cursor-pointer"
                                          onClick={() => {
                                            let rows = [...affiliatedGymsRows];
                                            if (rows.length > 1) {
                                              rows.splice(ind, 1);
                                              setAffiliatedGyms(rows);
                                              setValue("affiliations", rows);
                                            } else {
                                              setAffiliatedGyms([
                                                affiliatedGymsInitValue,
                                              ]);
                                              setValue("affiliations", [
                                                affiliatedGymsInitValue,
                                              ]);
                                            }
                                          }}
                                        >
                                          Remove
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Social Media</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Facebook
                                <span data-tip data-for="facebook">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="facebook"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Facebook URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="facebook"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Twitter
                                <span data-tip data-for="twitter">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="twitter"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Twitter URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="twitter"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Instagram
                                <span data-tip data-for="instagram">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="instagram"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Instagram URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="instagram"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Youtube
                                <span data-tip data-for="youtube">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="youtube"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Youtube URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="youtube"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                LinkedIn
                                <span data-tip data-for="linkedin">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="linkedin"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Linkedin URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="linkedin"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="row container-fluid py-4 px-4">
                          <div className="col-md-4">
                            <div className="check-box">
                              <label
                                className="form-radio-label"
                                for="inlineRadio1"
                              >
                                Ask a Question
                              </label>
                              <div className="radio-buttons">
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    name="askAnExpert"
                                    className="align-middle me-1"
                                    value="Yes"
                                    checked={select === "Yes"}
                                    onChange={(e) => setSelect("Yes")}
                                  />
                                  <label
                                    className="form-check-label me-4"
                                    for="inlineRadio1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    name="askAnExpert"
                                    className="align-middle me-1"
                                    value="No"
                                    checked={select === "No"}
                                    onChange={(e) => setSelect("No")}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineRadio2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      {params.id && (
                        <div className="row">
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("active")}
                                type="checkbox"
                                id="active"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("mopRecommended")}
                                type="checkbox"
                                id="mopRecommended"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="mopRecommended"
                              >
                                MOP Recommended
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <Button
                        className="btn btn-primary mt-4"
                        value={!params.id ? "Submit" : "Update"}
                        type="submit"
                      />
                      <Button
                        className="btn btn-secondary mt-4 ms-2"
                        onClick={() => {
                          navigate(`${location.state.pathName}`, {
                            state: provider?.planType,
                          });
                        }}
                        value="Cancel"
                        type="button"
                      />
                      <div className="row mt-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GymProfile;
