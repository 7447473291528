import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextInput from "../Components/common/TextInput";
import ClaimReasonModal from "../Components/modal/ClaimReasonModal";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../util/axiosInst";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { unknownErrorMessage } from "../util/constants";
import { formatPhoneNumber } from "../util/utils";
import clsx from "clsx";
import _ from "lodash";
import { TABLEURL } from "../util/router";

const { useEffect, useState } = React;
export default function ClaimRequestProfile() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const schema = yup.object({
    firstName: yup
      .string()
      .required("Firstname is a required field")
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, "Firstname must be alphabetic"),
    lastName: yup
      .string()
      .required("Lastname is a required field")
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, "lastname must be alphabetic"),
    businessName: yup
      .string()
      .required("Business name is a required field")
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, "businessname must be alphabetic"),
    businessAddress: yup
      .string()
      .required("Business address is a required field"),

    city: yup
      .string()
      .required("City is a required field")
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, "City must be alphabetic"),
    state: yup
      .string()
      .required("State is a required field")
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, "state must be alphabetic"),
    businessPhone: yup.string().required("Business phone is a required field"),
    personalPhone: yup.string(),
    email: yup.string().email().required("Email is a required field"),
  });
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line
  }, []);

  const [provider, setProvider] = useState(null);
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState(null);
  const [personalphone, setpersonalphone] = useState(null);
  const [pathName, setPathName] = useState("");
  const getProvider = async () => {
    const { data: claimRequestData } = await axios.get(
      `/v1/claimProfile/${params.id}`
    );
    setProvider(claimRequestData);
    setValue("firstName", claimRequestData.first_name);
    setValue("lastName", claimRequestData.last_name);
    setValue("businessName", claimRequestData.business_info.name);
    setValue("businessAddress", claimRequestData.business_info.address);
    setValue("businessPhone", claimRequestData.business_info.phone_number);
    setValue("city", claimRequestData.city);
    setValue("state", claimRequestData.state);
    setValue("personalPhone", claimRequestData.phone_number);
    setValue("email", claimRequestData.email);
    setValue("userId", claimRequestData.userId);
    setValue("additional_info", claimRequestData?.additional_info);
    setPhone(claimRequestData?.business_info.phone_number);
    setValue("userType", claimRequestData?.userType.replace("_", " "));
    setPathName(TABLEURL[claimRequestData?.userType]?.PATHNAME);
    setpersonalphone(claimRequestData?.phone_number);
  };
  const onSubmit = async (approved, reason) => {
    try {
      const { status } = await axios.patch(`/v1/claimProfile/${params.id}`, {
        userId: getValues("userId"),
        approved: approved,
        reason,
      });
      if (status === 200 || status === 201) {
        navigate(pathName, { state: "claimed_profile" });
        Swal.fire({
          icon: "success",
          title: `${location?.state?.label} status updated Successfully`,
          text: "Your changes have been saved",
        });
      }
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };

  return (
    <div>
      <SideNavigation />
      <ClaimReasonModal
        setShow={setShow}
        show={show}
        errors={errors}
        register={register}
        onSubmit={onSubmit}
      />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <form>
                <div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Information</h5>
                        </div>
                        <div className="p-4">
                          <div className="row mt-3">
                            <div className="col-md-12 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="BUSINESS NAME"
                                mandatory={true}
                                name="businessName"
                              />
                            </div>

                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="FIRST NAME"
                                mandatory={true}
                                name="firstName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="LAST NAME"
                                mandatory={true}
                                name="lastName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="User Type"
                                name="userType"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="BUSINESS ADDRESS"
                                mandatory={true}
                                name="businessAddress"
                              />
                            </div>

                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="CITY"
                                mandatory={true}
                                name="city"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="STATE/PROVINCE"
                                mandatory={true}
                                name="state"
                              />
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                BUSINESS PHONE{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                maxlength={10}
                                type="text"
                                className={clsx(
                                  `form-control`,
                                  _.get(errors, "businessPhone") && "is-invalid"
                                )}
                                onChange={(e) => {
                                  setPhone(formatPhoneNumber(e.target.value));
                                  setValue(
                                    "businessPhone",
                                    formatPhoneNumber(e.target.value)
                                  );
                                }}
                                value={formatPhoneNumber(phone)}
                              />
                              <div className="invalid-feedback">
                                {_.get(errors, "businessPhone")?.message}
                              </div>
                            </div>

                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                CELL PHONE{" "}
                              </label>
                              <input
                                maxlength={10}
                                type="text"
                                className={clsx(
                                  `form-control`,
                                  _.get(errors, "personalPhone") && "is-invalid"
                                )}
                                onChange={(e) => {
                                  setpersonalphone(
                                    formatPhoneNumber(e.target.value)
                                  );
                                  setValue(
                                    "personalPhone",
                                    formatPhoneNumber(e.target.value)
                                  );
                                }}
                                value={formatPhoneNumber(personalphone)}
                                // {...register("businessPhone")}
                              />

                              <div className="invalid-feedback">
                                {_.get(errors, "businessPhone")?.message}
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="EMAIL ADDRESS"
                                mandatory={true}
                                name="email"
                              />
                            </div>
                            <div className="col-md-12 col-sm-12 mb-3 fw-semibold col-12 align-self-center">
                              <label for="" className="form-label fw-semibold">
                                ADDITIONAL INFORMATION (OPTIONAL)
                              </label>
                              <textarea
                                name=""
                                id=""
                                cols="4"
                                rows="5"
                                className="form-control flex-fill"
                                {...register("additional_info")}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      {!provider?.approved && (
                        <button
                          type="button"
                          className="btn btn-primary mt-4 mr-4"
                          onClick={() => onSubmit(true)}
                        >
                          Approve
                        </button>
                      )}
                      <button
                        className={`btn ${
                          provider?.approved ? "btn-secondary" : "btn-danger"
                        } mt-4`}
                        onClick={() => {
                          if (provider?.approved)
                            navigate(pathName, { state: "claimed_profile" });
                          else {
                            setShow(true);
                          }
                        }}
                        type={"button"}
                      >
                        {provider?.approved ? "cancel" : "Deny"}
                      </button>
                      <div className="row mt-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
