import React, { useEffect, useState, useRef } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../util/axiosInst";
import MediaPreview from "../custom/MediaPreview";
import SideNavigation from "../layout/SideNavigation";
import TopNavigation from "../layout/TopNavigation";
import UptodateSearch from "../custom/UptodateSearch";
import { toast } from "react-toastify";
import {
  createSuccessMessage,
  unknownErrorMessage,
  updateSuccessMessage,
} from "../../util/constants";
import Select from "react-select";
import Creatable from "react-select/creatable";
export default function ContentForm() {
  const navigate = useNavigate();
  const params = useParams();
  const schema = yup
    .object({
      coverImage: yup.string(),
      title: yup.string().required("Title is a required field").min(3),
      keywords: yup.string().required("Keyword is a required field").min(3),
      titleTag: yup.string(),
      metaDescription: yup.string(),
      structuredData: yup.string(),
      active: yup.boolean().default(true),
      sections: yup.array().of(
        yup.object().shape({
          title: yup.string(),
          articleId: yup.string(),
          sectionId: yup.string(),
          uptoDateContent: yup.string(),
          active: yup.boolean().default(true),
          content: yup.array().of(
            yup.object().shape({
              type: yup.string(),
              data: yup.string(),
            })
          ),
        })
      ),
    })
    .required();
  const [template, setTemplate] = useState(null);
  const [editId, setEditId] = useState(null);
  const [muscleTypes, setMuscleTypes] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [selectedMuscleType, setSelectedMuscleType] = useState("");
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [coverImages, setCoverImages] = useState("");
  const fetchMuscleTypes = async () => {
    const { data } = await axios.get("/v1/public/evaluation/type/admin");
    const mts = data.map((muscleType) => ({
      label: muscleType.name,
      value: muscleType.id,
    }));
    setMuscleTypes(mts);
    return mts;
  };

  const fetchConditions = async (muscleType) => {
    const {
      data: { conditions },
    } = await axios.get(`/v1/public/evaluation/type/${muscleType}/conditions`);
    const conditionOptions = conditions.map((condition) => ({
      label: condition.conditionName,
      value: condition.id,
    }));
    setConditions(conditionOptions);
    return conditionOptions;
  };

  useEffect(() => {
    fetchMuscleTypes();
  }, []);

  useEffect(() => {
    if (selectedMuscleType?.value) {
      fetchConditions(selectedMuscleType.value);
    }
  }, [selectedMuscleType]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { fields, append } = useFieldArray({
    control,
    name: "sections",
  });

  const onUploadFinish = (response, file, fieldName) => {
    const uploadElement = document.getElementById(fieldName);
    uploadElement.labels[0].innerHTML = `
      <span class="border btn btn-primary" role="button">
        Choose file
      </span>`;

    setValue(fieldName, `/contents/${file.name}`);
    if (fieldName === "coverImage") {
      setCoverImages(fieldName, `/contents/${file.name}`);
    }
  };
  const onProgress = (percent, status, file, id) => {
    const uploadElement = document.getElementById(id);
    uploadElement.labels[0].textContent = `${status} - ${percent}`;
  };
  const onSignedUrl = (response) => {
    console.log("onSignedUrl ", response);
  };

  const onUploadError = (message) => {
    console.log("onUploadError ", message);
  };
  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getDefaultTemplate = async () => {
    const { data } = await axios.get("/v1/content/template");
    setTemplate(data.results[0]);
    if (params && params.id) {
      getContentDetails(params.id);
    } else {
      data.results[0].sections.forEach((section) => {
        append(
          [
            {
              title: section.title,
              active: true,
              content: [
                {
                  type: "text",
                  data: "",
                },
              ],
            },
          ],
          { shouldFocus: false }
        );
      });
    }
  };
  const getContentDetails = async (contentId) => {
    setEditId(contentId);
    const { data } = await axios.get(`/v1/content/admin/${contentId}`);
    delete data.id;
    data.sections.forEach((section) => delete section.id);
    data.keywords = data.keywords.join(",");
    const mts = await fetchMuscleTypes();
    const conditionOptions = await fetchConditions(data.evaluationTypeId);

    setSelectedMuscleType(mts.find((mt) => mt.value === data.evaluationTypeId));
    setSelectedConditions(
      data.evaluationTypeConditions?.map((et) =>
        conditionOptions.find((x) => x.value === et)
      )
    );
    reset(data);
  };

  const onSubmit = async (formData) => {
    if (coverImages.length > 0) {
      formData.sections.forEach((section) => {
        if (section.type === "uptodate") {
          section.data = "";
        }
      });
      const dataToSend = {
        ...formData,
        userId: JSON.parse(localStorage.getItem("mop-admin-user"))?.id,
        template_id: template.id,
        keywords: formData.keywords.split(","),
      };
      delete dataToSend.createdAt;
      delete dataToSend.updatedAt;

      if (selectedMuscleType?.value && selectedConditions?.length > 0) {
        dataToSend.evaluationTypeId = selectedMuscleType.value;
        const evaluationTypeConditions = selectedConditions.map((x) => x.value);
        const {
          data: { conditions },
        } = await axios.get(
          `/v1/public/evaluation/type/${selectedMuscleType?.value}/conditions`
        );
        const condition_ids = conditions.map((n) => n.id);
        dataToSend.evaluationTypeConditionsNew =
          evaluationTypeConditions.filter(
            (condition) => !condition_ids.includes(condition)
          );
        dataToSend.evaluationTypeConditions = evaluationTypeConditions.filter(
          (condition) =>
            !dataToSend.evaluationTypeConditionsNew.includes(condition)
        );
      } else {
        toast.error("Please specify map content to condition");
        return;
      }

      try {
        if (editId) {
          const { data } = await axios.patch(
            `/v1/content/admin/${editId}`,
            dataToSend
          );
          if (data && data.id) {
            toast.success(updateSuccessMessage);
            navigate("/contents");
          }
        } else {
          const { data } = await axios.post("/v1/content", dataToSend);
          if (data && data.id) {
            toast.success(createSuccessMessage);
            navigate("/contents");
          }
        }
      } catch (e) {
        if (e.response?.data?.message) {
          toast.error(e.response?.data?.message);
        } else {
          toast.error(unknownErrorMessage);
        }
      }
    }
  };
  useEffect(() => {
    getDefaultTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: ["sans-serif"] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  const Profile = ({ control }) => {
    const coverImage = useWatch({
      control,
      name: "coverImage",
    });
    useEffect(() => {
      if (window.location.pathname.includes("edit")) setCoverImages(coverImage);
    }, [coverImage]);
    return (
      <div className="image-wraper mb-3">
        <div className="media-container">
          <MediaPreview fileName={coverImage} type="image" />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept="image/*"
            id="coverImage"
            className="d-none"
            getSignedUrl={getSignedUrl}
            onSignedUrl={onSignedUrl}
            signingUrlWithCredentials={true}
            onProgress={(percent, status, file) =>
              onProgress(percent, status, file, "coverImage")
            }
            uploadRequestHeaders={{}}
            onError={onUploadError}
            onFinish={(result, file) =>
              onUploadFinish(result, file, "coverImage")
            }
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor="coverImage">
            <span className="border btn btn-primary" role="button">
              Choose file
            </span>
            {coverImage && (
              <span
                className="border ml-3 btn btn-danger"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  setValue("coverImage", "");
                  setCoverImages("");
                }}
              >
                Remove
              </span>
            )}
          </label>
        </div>
      </div>
    );
  };

  const TitleSection = ({ index }) => {
    const [isEditing, setEditing] = useState(false);

    return (
      <>
        {isEditing && (
          <input
            autoFocus
            {...register(`sections.${index}.title`)}
            type="text"
            id={`sections${index}title`}
            className="form-control mb-2"
          />
        )}
        {!isEditing && (
          <h5 className="mb-2">
            {getValues(`sections.${index}.title`)}{" "}
            <i
              style={{ cursor: "pointer" }}
              className="fa fa-edit pl-2"
              onClick={() => {
                setEditing(true);
              }}
            ></i>{" "}
          </h5>
        )}
      </>
    );
  };

  const onChangeType = (nestIndex, k) => {
    setValue(`sections[${nestIndex}].content[${k}].articleId`, "");
    setValue(`sections[${nestIndex}].content[${k}].sectionId`, "");
    setValue(`sections[${nestIndex}].content[${k}].data`, "");
  };
  const setUptodateContent = (details, sectionAt) => {
    if (details) {
      setValue(`${sectionAt}.articleId`, details.articleId);
      setValue(`${sectionAt}.sectionId`, details.id);
      setValue(`${sectionAt}.data`, details.html);
    } else {
      setValue(`${sectionAt}.articleId`, "");
      setValue(`${sectionAt}.sectionId`, "");
      setValue(`${sectionAt}.data`, "");
    }
  };
  const ConditionalInput = ({ control, nestIndex, k }) => {
    const value = useWatch({
      name: `sections[${nestIndex}].content`,
      control,
    });
    return (
      <Controller
        control={control}
        name={`sections[${nestIndex}].content[${k}].data`}
        defaultValue=""
        render={({ field }) => {
          switch (value[k]?.type) {
            case "text":
              return (
                <ReactQuill
                  {...field}
                  modules={editorModules}
                  formats={editorFormats}
                />
              );
            case "image":
              return (
                <div className="col-lg-3">
                  <div className="image-wraper mb-3">
                    <div className="media-container">
                      <MediaPreview
                        fileName={getValues(
                          `sections[${nestIndex}].content[${k}].data`
                        )}
                        type={getValues(
                          `sections[${nestIndex}].content[${k}].type`
                        )}
                      />
                    </div>
                    <div className="upload-control">
                      <ReactS3Uploader
                        accept="image/*"
                        id={`sections[${nestIndex}].content[${k}].data`}
                        className="d-none"
                        getSignedUrl={getSignedUrl}
                        onSignedUrl={onSignedUrl}
                        signingUrlWithCredentials={true}
                        uploadRequestHeaders={{}}
                        onError={onUploadError}
                        onFinish={(result, file) =>
                          onUploadFinish(
                            result,
                            file,
                            `sections[${nestIndex}].content[${k}].data`
                          )
                        }
                        onProgress={(percent, status, file) =>
                          onProgress(
                            percent,
                            status,
                            file,
                            `sections[${nestIndex}].content[${k}].data`
                          )
                        }
                        contentDisposition="auto"
                        scrubFilename={(filename) =>
                          filename.replace(/[^\w\d_\-.]+/gi, "")
                        }
                        autoUpload={true}
                      />
                      <label
                        htmlFor={`sections[${nestIndex}].content[${k}].data`}
                      >
                        <span className="border btn btn-primary" role="button">
                          Choose file
                        </span>
                        {getValues(
                          `sections[${nestIndex}].content[${k}].data`
                        ) && (
                          <span
                            className="border ml-3 btn btn-danger"
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue(
                                `sections[${nestIndex}].content[${k}].data`,
                                ""
                              );
                            }}
                          >
                            Remove
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              );
            case "video":
              return (
                <div className="col-lg-3">
                  <div className="image-wraper mb-3">
                    <div className="media-container">
                      <MediaPreview
                        fileName={getValues(
                          `sections[${nestIndex}].content[${k}].data`
                        )}
                        type={getValues(
                          `sections[${nestIndex}].content[${k}].type`
                        )}
                      />
                    </div>
                    <div className="upload-control">
                      <ReactS3Uploader
                        accept="video/*"
                        id={`sections[${nestIndex}].content[${k}].data`}
                        className="d-none"
                        getSignedUrl={getSignedUrl}
                        onSignedUrl={onSignedUrl}
                        signingUrlWithCredentials={true}
                        uploadRequestHeaders={{}}
                        onError={onUploadError}
                        onProgress={(percent, status, file) =>
                          onProgress(
                            percent,
                            status,
                            file,
                            `sections[${nestIndex}].content[${k}].data`
                          )
                        }
                        onFinish={(result, file) =>
                          onUploadFinish(
                            result,
                            file,
                            `sections[${nestIndex}].content[${k}].data`
                          )
                        }
                        contentDisposition="auto"
                        scrubFilename={(filename) =>
                          filename.replace(/[^\w\d_\-.]+/gi, "")
                        }
                        autoUpload={true}
                      />
                      <label
                        htmlFor={`sections[${nestIndex}].content[${k}].data`}
                      >
                        <span className="border btn btn-primary" role="button">
                          Choose file
                        </span>
                        {getValues(
                          `sections[${nestIndex}].content[${k}].data`
                        ) && (
                          <span
                            className="border ml-3 btn btn-danger"
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue(
                                `sections[${nestIndex}].content[${k}].data`,
                                ""
                              );
                            }}
                          >
                            Remove
                          </span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              );
            case "ypo":
              return (
                <div className="input-group-swing">
                  <textarea
                    type="text"
                    className="custom"
                    {...field}
                    placeholder="Enter Video code"
                    rows="2"
                    cols="50"
                  ></textarea>
                </div>
              );
            case "viewmedica":
              return (
                <div className="input-group-swing">
                  <textarea
                    type="text"
                    className="custom"
                    {...field}
                    placeholder="Enter Video code"
                    rows="2"
                    cols="50"
                  ></textarea>
                </div>
              );
            case "uptodate":
              return (
                <>
                  {getValues(
                    `sections[${nestIndex}].content[${k}].articleId`
                  ) ? (
                    <>
                      <span
                        role="button"
                        onClick={() =>
                          setUptodateContent(
                            null,
                            `sections[${nestIndex}].content[${k}]`
                          )
                        }
                      >
                        <h6>
                          Change <i className="fa fa-edit"></i>
                        </h6>
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getValues(
                            `sections[${nestIndex}].content[${k}].data`
                          ),
                        }}
                      ></div>
                    </>
                  ) : (
                    <UptodateSearch
                      onSectionSelected={(details) =>
                        setUptodateContent(
                          details,
                          `sections[${nestIndex}].content[${k}]`
                        )
                      }
                    />
                  )}
                </>
              );
            default:
              return null;
          }
        }}
      />
    );
  };

  const Section = ({ nestIndex }) => {
    const reference = useRef(null);
    const scrollToElement = () => reference.current.scrollIntoView();
    const { fields, remove, append } = useFieldArray({
      control,
      name: `sections[${nestIndex}].content`,
    });

    return (
      <>
        {fields.map((field, k) => {
          return (
            <>
              <div
                className="form-group"
                ref={reference}
                onChange={(e) => onChangeType(nestIndex, k)}
              >
                <label htmlFor="text-area">Select content type</label>
                <br />
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    value="text"
                    id={`sections${k}type-radio-text${nestIndex}`}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-text${nestIndex}`}
                  >
                    Rich-Text
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    id={`sections${k}type-radio-image${nestIndex}`}
                    value="image"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-image${nestIndex}`}
                  >
                    Image
                  </label>
                </div>

                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    id={`sections${k}type-radio-video${nestIndex}`}
                    value="video"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-video${nestIndex}`}
                  >
                    Video
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    id={`sections${k}type-radio-ypo${nestIndex}`}
                    value="ypo"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-ypo${nestIndex}`}
                  >
                    Embed YPO video
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    id={`sections${k}type-radio-uptodate${nestIndex}`}
                    value="uptodate"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-uptodate${nestIndex}`}
                  >
                    Uptodate
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    {...register(`sections[${nestIndex}].content[${k}].type`)}
                    type="radio"
                    id={`sections${k}type-radio-viewmedica${nestIndex}`}
                    value="viewmedica"
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`sections${k}type-radio-viewmedica${nestIndex}`}
                  >
                    Embed Viewmedica video
                  </label>
                </div>
              </div>
              <div>
                <ConditionalInput
                  key={field.id}
                  {...{ control, nestIndex, k, field }}
                />
              </div>
              <button
                className="btn btn-primary my-3"
                onClick={() => {
                  scrollToElement();
                  append(
                    {
                      type: "text",
                      data: "",
                    },
                    { shouldFocus: true }
                  );
                }}
              >
                Add section
              </button>

              {k !== 0 && (
                <button
                  className="btn btn-secondary my-3 ml-2"
                  onClick={() => {
                    remove(k);
                  }}
                >
                  Remove section
                </button>
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={"Content"} />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Profile control={control} />
                    <div>
                      <div className="input-group-swing">
                        <input
                          type="text"
                          placeholder="Enter Title (eg: knee dislocate)"
                          {...register("title")}
                        />
                        <label>Title:</label>
                        <p className="error">{errors.title?.message}</p>
                      </div>
                      <div className="input-group-swing">
                        <input
                          type="text"
                          placeholder="Enter Keywords (eg: knee,dislocate)"
                          {...register("keywords")}
                        />
                        <label>Search keywords:</label>
                        <p className="error">{errors.keywords?.message}</p>
                      </div>
                      <div className="">
                        <h5>Map content to condition</h5>
                        <div className="d-flex mb-4">
                          <div
                            className="mr-4"
                            style={{
                              width: "50%",
                            }}
                          >
                            <Select
                              options={muscleTypes}
                              value={selectedMuscleType}
                              onChange={(newValue) => {
                                setSelectedMuscleType(newValue);
                              }}
                            />
                          </div>

                          <div
                            className=""
                            style={{
                              width: "50%",
                            }}
                          >
                            <Creatable
                              isClearable
                              isMulti
                              options={conditions}
                              value={selectedConditions}
                              onChange={(newValue) => {
                                setSelectedConditions(newValue);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 mb-3">
                          <div className="input-group-swing">
                            <input
                              type="text"
                              placeholder=""
                              {...register("titleTag")}
                            />
                            <label>Title Tag:</label>
                            <p className="error">{errors.keywords?.message}</p>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="input-group-swing">
                            <input
                              type="text"
                              placeholder=""
                              {...register("metaDescription")}
                            />
                            <label>Meta Description:</label>
                            <p className="error">{errors.keywords?.message}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 mb-3">
                          <div className="input-group-swing">
                            <textarea
                              type="text"
                              className="custom"
                              {...register("structuredData")}
                              placeholder="Enter Video code"
                              rows="2"
                              cols="50"
                            ></textarea>
                            <label>Schema stuctured data</label>
                            <p className="error">
                              {errors.structuredData?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox custom-control-inline mb-5">
                        <input
                          {...register(`active`)}
                          type="checkbox"
                          id={`content-active`}
                          className="custom-control-input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`content-active`}
                        >
                          Display Content
                        </label>
                      </div>
                    </div>

                    {fields.map((field, index) => {
                      return (
                        <div key={field.id} className="mb-5">
                          <TitleSection index={index} />
                          <div className="custom-control custom-checkbox custom-control-inline mb-3">
                            <input
                              {...register(`sections.${index}.active`)}
                              type="checkbox"
                              id={`sections${index}active`}
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`sections${index}active`}
                            >
                              Display Section
                            </label>
                          </div>
                          <Section nestIndex={index} />
                        </div>
                      );
                    })}
                    <div className="mt-3">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => {
                          if (coverImages.length <= 0) {
                            toast.error("Cover Image is required field");
                          }
                        }}
                      >
                        {editId ? "Update" : "Create"}
                      </button>

                      <button
                        type="button"
                        className="btn ml-1 btn-secondary"
                        onClick={() => {
                          navigate("/contents");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
