import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";

export default function Patient() {
    const [key, setKey] = useState("patients");
    const [contents, setContents] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const schema = yup
        .object({
            searchQuery: yup.string(),
        })
        .required();

    const downloadExcel = async () => {
        let response;
        response = await axios.get(
            `/v1/user?role=patient&&limit=100000`
        );
        const worksheet = XLSX.utils.json_to_sheet(
            map(
                response?.data?.results,
                ({ firstName, lastName, email, active, createdAt }) => ({
                    Name: firstName + " " + lastName,
                    Email: email,
                    "Created Date": moment(createdAt).format("ll"),
                    "Active": active === true ? "YES" : "NO"
                })
            )
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD")}.xlsx`);
    };
    const columns = [
        { dataField: "id", text: "Id", sort: false, hidden: true },
        { dataField: "name", text: "Name", sort: true },
        { dataField: "email", text: "Email", sort: true },
        {
            dataField: "createdAt",
            text: "Created Date",
            formatter: (date) => {
                return <>{moment (date).format("lll")}</>;
            },
            sort: true,
        },
        {
            dataField: "active",
            text: "Active",
            headerStyle: { width: 100 },
            sort: true,
            formatter: (cell) => {
                return <>{cell ? <span className="badge badge-success">YES</span> : <span className="badge badge-danger">NO</span>}</>;
            },
        },
    ];

    const { register, getValues } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <div>
            <SideNavigation />
            <div className="main-content">
                <div className="container-fluid">
                    <TopNavigation title="Manage Patients" />
                    <div className="row mt-4">
                        <div className="col-sm-12">
                        <div className="d-flex justify-content-end">
                            <div className="input-group rounded me-2 ms-2 mb-3" style={{ width: "50%" }}>
                                <input
                                    type="text"
                                    className={"form-control bg-transparent p-2"}
                                    placeholder="Search Patient... "
                                    style={{
                                        maxWidth: "100%",
                                        borderTop: "1px solid #b5b5b5",
                                        borderLeft: "1px solid #b5b5b5",
                                        borderRadius: "5px 0 0 5px "
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            setContents(getValues("searchQuery"));
                                        }
                                    }}
                                    {...register("searchQuery")}
                                />
                                <button
                                    className="input-group-text text-white btn btn-primary"
                                    onClick={() => {
                                        setContents(getValues("searchQuery"));
                                        }}
                                        style={{
                                            borderRadius: "0 5px 5px 0"
                                          }}
                                >
                                    <i className="fa fa-solid fa-search"></i>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary ms-3 rounded"
                                        onClick={() => downloadExcel()}
                                        style={{borderRadius: 0}}
                                >
                                    Download as Excel
                                </button>
                                </div>
                                </div>
                            <div className="box">
                                <div className="box-content">
                                    <CustomDataTable columns={columns} searchQuery={contents} editUrl="/editpatient" deleteUrl={null} setTotalRecord={setTotalRecord} fetchContentsUrl={`/v1/user?role=patient&&`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
