import React from "react";

const CheckBox = ({ register, value, id, label }) => {
  return (
    <div className="custom-control custom-checkbox custom-control-inline">
      <input
        {...register(value)}
        type="checkbox"
        id={id}
        className="custom-control-input"
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
