import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextInput from "../Components/common/TextInput";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../util/axiosInst";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { unknownErrorMessage } from "../util/constants";
import ImageCropModal from "../Components/image/ImageCropModal";
import Image from "../util/Image";

const { useEffect, useState } = React;
const TestimoniesForm = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState("");
  const [fileSrc, setFileSrc] = useState("");
  const schema = yup.object({
    feedback: yup.string().nullable(),
  });
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line
  }, []);

  const getProvider = async () => {
    const { data: claimRequestData } = await axios.get(
      `v1/testimonial/${params.id}`
    );
    const name = claimRequestData.userType
      ? claimRequestData?.userType === "hospital" ||
        claimRequestData?.userType === "clinic" ||
        claimRequestData?.userType === "rehabilitation_center" ||
        claimRequestData?.userType === "gym"
        ? claimRequestData.name
          ? claimRequestData.name
          : claimRequestData.firstName
        : claimRequestData.firstName + " " + claimRequestData.lastName
      : claimRequestData.name
      ? claimRequestData.name
      : claimRequestData.firstName + " " + claimRequestData.lastName;
    setValue("name", name);
    setValue("feedback", claimRequestData?.feedback);
    setValue("banner", claimRequestData?.image);
    setValue("approved", claimRequestData.approved);
  };

  const onSubmit = async () => {
    try {
      const { status } = await axios.patch(`v1/testimonial/${params.id}`, {
        approved: getValues("approved"),
        feedback: getValues("feedback"),
        image: getValues("banner"),
      });
      if (status === 200 || status === 201) {
        navigate(`/testimonies`);
        Swal.fire({
          icon: "success",
          title: `${location?.state?.label} updated Successfully.`,
          // text: "Your changes have been saved",
        });
      }
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };
  const BannerImage = ({ control }) => {
    const banner = useWatch({
      control,
      name: "banner",
    });
    return (
      <div>
        <div>
          {banner && (
            <span role="button">
              <Image
                src={banner}
                alt={banner}
                width={"100%"}
                height={"300px"}
              />
            </span>
          )}
          <div>
            {!banner && (
              <label
                htmlFor="banner"
                className={`w-100 ${banner ? "" : "min-h-75"}`}
              >
                <span
                  role="button"
                  type="button"
                  className={`w-100 ${banner ? "" : "min-h-75"}`}
                >
                  <>
                    <label className="mb-1 w-100" style={{ cursor: "pointer" }}>
                      Set Thumnail
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept={"image/*"}
                        onChange={(e) => onSelectFile(e)}
                      />
                      <div className="border-dotted min-h-75  border rounded p-3 d-flex align-items-center justify-content-center flex-fill">
                        <div className="text-center text-light">
                          <h1>
                            <i className="fa fa-image" />
                          </h1>
                          <p className="mb-0">Upload an image</p>
                        </div>
                      </div>
                    </label>
                  </>
                </span>
              </label>
            )}
            {banner && (
              <button
                className={`btn
                  btn-danger mt-3
               `}
                onClick={() => {
                  setValue("banner", "");
                }}
                type={"button"}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setFileSrc(e.target.files[0]);
      document.querySelector("#imgCrop").classList.add("show");
      document.querySelector("#imgCrop").style.display = "block";
    }
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <ImageCropModal
                setValue={setValue}
                imgSrc={imgSrc}
                fileSrc={fileSrc}
              />
              <form>
                <div className="">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Testimonies</h5>
                        </div>
                        <div className="p-4">
                          <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="NAME"
                                mandatory={true}
                                disabled={true}
                                name="name"
                              />
                            </div>
                            <div className="col-md-12 col-sm-12 mb-3 fw-semibold col-12 align-self-center">
                              <label for="" className="form-label fw-semibold">
                                Feedback Message
                              </label>
                              <textarea
                                name=""
                                id=""
                                cols="4"
                                rows="5"
                                className="form-control flex-fill"
                                {...register("feedback")}
                              ></textarea>
                            </div>
                            <div className="col-3">
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  {...register("approved")}
                                  type="checkbox"
                                  id="approved"
                                  className="custom-control-input"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="approved"
                                >
                                  Approved
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5"></div>
                    </div>
                    <div className="col-md-3">
                      <div className="border rounded bg-white mb-3 p-3">
                        <div className="d-flex flex-column">
                          <BannerImage control={control} />
                          <button
                            className={`btn
                            btn-outline-danger
                         mt-4`}
                            onClick={() => {
                              navigate("/testimonies");
                            }}
                            type={"button"}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary mt-4 "
                            onClick={() => onSubmit()}
                          >
                            Publish
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimoniesForm;
