export const TABLEURL = {
  nurse_practitioner: {
    PATHNAME: "/nursePractitioner",
  },
  orthopedic_surgeon: {
    PATHNAME: "/orthosurgion",
  },
  neuro_surgeon: {
    PATHNAME: "/neurosurgeon",
  },
  primary_care: {
    PATHNAME: "/physician",
  },
  personal_trainer: {
    PATHNAME: "/personalTrainer",
  },
  physical_therapist: {
    PATHNAME: "/Physical-Therapists",
  },
  wellness_provider: {
    PATHNAME: "/wellnessProviders",
  },
  gym: {
    PATHNAME: "/gym",
  },
  athletic_trainers: {
    PATHNAME: "/athleticTrainers",
  },
  rehabilitation_center: {
    PATHNAME: "/rehabilitation-center",
  },
  hospital: {
    PATHNAME: "/hospital",
  },
  clinic: {
    PATHNAME: "/clinic",
  },
  chiropractor: {
    PATHNAME: "/chiropractor",
  },
  pain_management_specialist: {
    PATHNAME: "/pain-management-specialist",
  },
  massage_therapist: {
    PATHNAME: "massage-therapist",
  },
};
