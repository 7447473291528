import React from "react";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";

const { useState } = React;
const LatestBlogsList = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("latest_news");
  const [contents, setContents] = useState([]);

  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const downloadExcel = async () => {
    let response;
    response = await axios.get(
      `v1/blog-news/admin?deleted=false&&limit=100000`
    );
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ title, categoryId, active, createdAt }) => ({
          Title: title,
          Category: categoryId.name,
          "Created Date": moment(createdAt).format("ll"),
          "Active": active === true ? "YES" : "NO"
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD")}.xlsx`);
  };

  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },

    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "categoryId.name",
      text: "Category",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (date) => {
        return <>{moment(date).format("ll")}</>;
      },
      sort: true,
    },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];

  setInterval(() => {
    if (document.querySelectorAll("#content")) {
      if (document.querySelectorAll("#content").length > 2) {
        var slice = function (elements, start, end) {
          var sliced = Array.prototype.slice.call(elements, start, end);

          return sliced;
        };

        var elems = document.querySelectorAll("#content");
        elems.forEach((element) => {
          var html = "";
          if (element.childNodes.length > 2) {
            var slices = slice(element.childNodes, 0, 2),
              len = slices.length,
              i = 0;

            for (i; i < len; i++) {
              if (slices[i].firstChild.localName === "img") {
                html += "<p>" + slices[i].firstChild.outerHTML + "</p>";
                element.innerHTML = html;
              } else if (slices[i].firstChild.nodeName === "#text") {
                html += "<p>" + slices[i].firstChild.nodeValue + "</p>";
                element.innerHTML = html;
              }
            }
            element.querySelectorAll("img").forEach((element) => {
              element.classList.add("w-100");
            });
          }
        });
      }
    }
  }, 2000);

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Newsroom" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="d-flex justify-content-end mb-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      navigate("/add-latest-blogs", {
                        state: {
                          value: "add-latest-blogs",
                          label: "add-latest-blogs",
                          pathName: window.location.pathname,
                        },
                      })
                    }
                  >
                    Add Newsroom
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-3 rounded"
                    onClick={() => downloadExcel()}
                    style={{ borderRadius: 0 }}
                  >
                    Download as Excel
                  </button>
                </div>
                <div className="box-content">
                  <CustomDataTable
                    columns={columns}
                    editUrl="/edit-latest-blogs"
                    deleteUrl={"v1/blog-news"}
                    value="personal_trainer"
                    label="Claim Request"
                    searchQuery={contents}
                    role="admin"
                    pathName={window.location.pathname}
                    fetchContentsUrl={`v1/blog-news/admin?deleted=false&&`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestBlogsList;
