import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const { useState } = React;
const TestimoniesList = () => {
  const [key, setKey] = useState("request");
  const [contents] = useState([]);

  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {row.userType
                ? row?.userType === "hospital" ||
                  row?.userType === "clinic" ||
                  row?.userType === "rehabilitation_center" ||
                  row?.userType === "gym"
                  ? row.name
                    ? row.name
                    : row.firstName
                  : row.firstName + " " + row.lastName
                : row.name
                ? row.name
                : row.firstName + " " + row.lastName}
            </span>
          </>
        );
      },
    },
    { dataField: "feedback", text: "Feedback", sort: true },
    {
      dataField: "approved",
      text: "Approved",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Testimonies" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="request" title="New Requests">
                      {key === "request" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl="/edit-testimonies"
                          deleteUrl={"v1/testimonial"}
                          value="personal_trainer"
                          label="Testimonies"
                          role="admin"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/testimonial/admin?approved=false&`}
                        />
                      )}
                    </Tab>
                    <Tab eventKey="approved" title="Approved">
                      {key === "approved" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl="/edit-testimonies"
                          deleteUrl={"v1/testimonial"}
                          searchQuery={contents}
                          value="personal_trainer"
                          role="admin"
                          label="Testimonies"
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/testimonial/admin?approved=true&`}
                        />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimoniesList;
