import React from "react";
import { timeArray } from "../../util/constants";


const Time = ({ register, name, options, disabledEndTimes }) => {
  return (
    <select className="form-select" {...register(name)}>
      <option value="">Select Time</option>
      {
        timeArray.map((time) => {
          return <option disabled={disabledEndTimes? disabledEndTimes.includes(time)?true:false : false} value={time}>{time}</option>
        })
      }
    </select>
  );
};

export default Time;
