import React, { useEffect, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { MdUnsubscribe } from "react-icons/md";
import axios from "../../util/axiosInst";
import { unknownErrorMessage } from "../../util/constants";
import { MdPerson, MdOutlineLogout, MdInsertDriveFile } from "react-icons/md";
import { BsClipboardData } from "react-icons/bs";
import { TbCertificate } from "react-icons/tb";
const SideNavigation = () => {
  const [show, setShow] = useState(true);
  const location = useLocation();
  const handleLogout = async () => {
    try {
      await axios.post("/v1/auth/logout", {
        refreshToken: JSON.parse(localStorage.getItem("mop-admin-token"))
          .refresh.token,
      });
      localStorage.removeItem("mop-admin-token");
      localStorage.removeItem("mop-admin-user");
      window.location.href = "/login";
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };
  window.addEventListener("click", function (event) {
    getPosition();
    if (document.getElementById("nav-dropdown")) {
      const event = document.getElementById("nav-dropdown");
      event.addEventListener("click", function () {
        if (show === true) {
          setShow(false);
        } else {
          setShow(true);
        }
      });
    }
  });

  useEffect(() => {
    getPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  const getPosition = () => {
    if (document.getElementsByClassName("dropdown-menu").length > 0) {
      const data = document.getElementsByClassName("dropdown-menu");
      var nodes = Array.prototype.slice.call(
        document.getElementsByClassName("dropdown-menu")[0].children[0]
          .childNodes
      );
      data[0].childNodes[0].childNodes.forEach((element, index) => {
        if (element.className.includes("active")) {
          if (nodes.indexOf(element) > 5) {
            document
              .getElementsByClassName("dropdown-menu")[0]
              .scrollTo(0, 500);
            document
              .getElementsByClassName("side-navigation")[0]
              .scrollTo(0, 10000);
          }
        }
      });
    }
  };

  return (
    <div className="side-navigation">
      <div className="logo">
        <h6 className="mt-3">My Orthopedic Problem</h6>
        <ul className="panel-icons"></ul>
      </div>
      <ul className="nav flex-column mt-3">
        <Nav activeKey={window.location.pathname}>
          <Nav.Item className="w-100">
            <Nav.Link
              to="/adminstaff"
              eventKey="/adminstaff"
              as={Link}
              className={`${
                location.state?.value === "createadmin" ||
                window.location.pathname.includes("editadmin")
                  ? "active"
                  : ""
              }`}
            >
              <MdPerson className="mr-3 icon" />
              Admin Staff
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link
              to="/contents"
              eventKey="/contents"
              as={Link}
              className={`${
                location.state?.value === "createcontent" ||
                window.location.pathname.includes("editcontent")
                  ? "active"
                  : ""
              }`}
            >
              <MdInsertDriveFile className="mr-3 icon" />
              Educational Contents
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link to="/providers" eventKey="/providers" as={Link}>
              <MdPerson className="mr-3 icon" /> Providers
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link
              to="/latest-blogs"
              eventKey="/latest-blogs"
              as={Link}
              className={`${
                location.state?.value === "add-latest-blogs" ||
                window.location.pathname.includes("edit-latest-blogs")
                  ? "active"
                  : ""
              }`}
            >
              <MdPerson className="mr-3 icon" /> Newsroom
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link
              to="/users-information"
              eventKey="/users-information"
              as={Link}
              className={`${
                location.state?.value === "users-information" ? "active" : ""
              }`}
            >
              <MdPerson className="mr-3 icon" /> Users
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link to="/newsletter" eventKey="/newsletter" as={Link}>
              <MdUnsubscribe className="mr-3 icon" /> Newsletter
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link to="/testimonies" eventKey="/testimonies" as={Link}>
              <MdPerson className="mr-3 icon" /> Testimonies
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="w-100">
            <Nav.Link
              to="/certifications"
              eventKey="/certifications"
              as={Link}
              className={`${
                location.state?.value === "add-news" ||
                window.location.pathname.includes("certifications") ||
                window.location.pathname.includes("certifications")
                  ? "active"
                  : ""
              }`}
            >
              <TbCertificate className="mr-3 icon" /> Certifications
            </Nav.Link>
          </Nav.Item>
          <NavDropdown
            title="Manage Providers"
            id={`nav-dropdown`}
            className="w-100"
            show={show}
          >
            <div id={"scrollBar"}>
              <NavDropdown.Item
                to="/nursePractitioner"
                eventKey="/nursePractitioner"
                as={Link}
                className={`${
                  location.state?.value === "nurse_practitioner" ||
                  window.location.pathname.includes("nursePractitioner")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Nurse Practitioner
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/orthosurgion"
                eventKey="/orthosurgion"
                as={Link}
                className={`${
                  location.state?.value === "orthopedic_surgeon" ||
                  window.location.pathname.includes("orthosurgionDetails")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Orthopedic Surgeon
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/neurosurgeon"
                eventKey="/neurosurgeon"
                as={Link}
                className={`${
                  location.state?.value === "neuro_surgeon" ||
                  window.location.pathname.includes("neurosurgeonDetails")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Neurosurgeon
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/physician"
                eventKey="/physician"
                as={Link}
                className={`${
                  location.state?.value === "primary_care" ||
                  window.location.pathname.includes("physicianDetails")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Primary Care
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/personalTrainer"
                eventKey="/personalTrainer"
                as={Link}
                className={`${
                  window.location.pathname.includes("personalTrainer") ||
                  window.location.pathname.includes("addPersonalTrainer")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Personal Trainer
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/Physical-Therapists"
                eventKey="/Physical-Therapists"
                as={Link}
                className={`${
                  window.location.pathname.includes("addPhysicalTherapist") ||
                  window.location.pathname.includes("physicalTherapist")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Physical Therapist
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/wellnessProviders"
                eventKey="/wellnessProviders"
                as={Link}
                className={`${
                  window.location.pathname.includes("addWellnessProvider") ||
                  window.location.pathname.includes("wellnessProvider")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Wellness Provider
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/athleticTrainers"
                eventKey="/athleticTrainers"
                as={Link}
                className={`${
                  window.location.pathname.includes("add-athletic-trainer")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" /> Athletic Trainer
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/gym"
                eventKey="/gym"
                as={Link}
                className={`${
                  window.location.pathname.includes("gym") ? "active" : ""
                }`}
              >
                <MdPerson className="mr-3 icon" />
                GYM
              </NavDropdown.Item>
              <NavDropdown.Item
                className={`${
                  window.location.pathname.includes("rehabilitation")
                    ? "active"
                    : ""
                }`}
                to="/rehabilitation-center"
                eventKey="/rehabilitation-center"
                as={Link}
              >
                <MdPerson className="mr-3 icon" />
                Rehabilitation Center
              </NavDropdown.Item>

              <NavDropdown.Item
                to="/hospital"
                eventKey="/hospital"
                as={Link}
                className={`${
                  window.location.pathname.includes("hospital") ? "active" : ""
                }`}
              >
                <MdPerson className="mr-3 icon" />
                Hospital
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/clinic"
                eventKey="/clinic"
                as={Link}
                className={`${
                  window.location.pathname.includes("clinic") ? "active" : ""
                }`}
              >
                <MdPerson className="mr-3 icon" id="/clinic" />
                Clinic
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/chiropractor"
                eventKey="/chiropractor"
                as={Link}
                className={`${
                  window.location.pathname.includes("chiropractor")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" id="/chiropractor" />
                Chiropractor
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/pain-management-specialist"
                eventKey="/pain-management-specialist"
                as={Link}
                className={`${
                  window.location.pathname.includes(
                    "pain-management-specialist"
                  )
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson
                  className="mr-3 icon"
                  id="/pain-management-specialist"
                />
                Pain Management Specialist
              </NavDropdown.Item>
              <NavDropdown.Item
                to="/massage-therapist"
                eventKey="/massage-therapist"
                as={Link}
                className={`${
                  window.location.pathname.includes("massage-therapist")
                    ? "active"
                    : ""
                }`}
              >
                <MdPerson className="mr-3 icon" id="/massage-therapist" />
                Massage Therapist
              </NavDropdown.Item>
            </div>
          </NavDropdown>
          <Nav.Item className="w-100">
            <Nav.Link
              href="#"
              onClick={(e) => {
                handleLogout();
              }}
            >
              <MdOutlineLogout className="mr-3 icon" />
              Logout
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </ul>
    </div>
  );
};
export default SideNavigation;
