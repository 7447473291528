import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import { toast } from "react-toastify";
import axios from "../../../util/axiosInst";
import { unknownErrorMessage } from "../../../util/constants";
import { AtheleticTrainerSchema } from "../../../util/schema";
import { SwalMessage } from "../../../util/utils";
import Button from "../../common/Button";
import LabelWithTooltip from "../../common/LabelWithTooltip";
import TextInput from "../../common/TextInput";
import MediaPreview from "../../custom/MediaPreview";
import SocialMedia from "../../custom/SocialMedia";
import WebLinksComponent from "../../custom/WebLinksComponent";
import SideNavigation from "../../layout/SideNavigation";
import TopNavigation from "../../layout/TopNavigation";
import {
  certificationIntitialValue,
  educationInitValue,
  schoolAffiliatedInitialValue,
  teamAffiliatedInitialValue,
} from "./init";
import _ from "lodash";
const AthleticTrainerForm = () => {
  const [videoUrl, setVideoUrl] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [provider, setProvider] = useState(null);
  const [select, setSelect] = useState("No");
  const [webLinks, setWebLinks] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [dataFromApi, setDataFromApi] = useState({});
  const [educationRows, setEducationRows] = useState([educationInitValue]);
  const [certificationRows, setCertificationRows] = useState([
    certificationIntitialValue,
  ]);
  const [teamAffiliationsRows, setTeamAffiliationsRows] = useState([
    teamAffiliatedInitialValue,
  ]);
  const [schoolAffiliationsRows, setSchoolAffiliationsRows] = useState([
    schoolAffiliatedInitialValue,
  ]);
  const [certification, setCertification] = useState();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AtheleticTrainerSchema),
  });
  const location = useLocation();

  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (params?.id) getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvider = async () => {
    const { data: providerData } = await axios.get(`/v1/provider/${params.id}`);
    setDataFromApi(providerData);
    setProvider(providerData);
    setValue("firstName", providerData.firstName);
    setValue("lastName", providerData.lastName);
    setValue("email", providerData.email);
    setValue("biography", providerData.biography);
    setValue("userType", providerData.userType.replace("_", " "));
    setValue("suffix", providerData.suffix);
    setValue("askAnExpert", providerData.askAnExpert);
    setValue("training_philosophy", providerData.training_philosophy);
    setValue("address", providerData?.address);
    setValue("state", providerData?.state);
    setValue("city", providerData?.city);
    setValue("country", providerData?.country);
    setValue("zipcode", providerData?.zipcode);
    setValue("phoneNumber", providerData?.phoneNumber);
    setValue("website", providerData?.website);
    setValue("active", providerData?.active);
    setValue("googlePlaceId", providerData?.googlePlaceId || "");
    setValue("mopRecommended", providerData?.mopRecommended);
    setValue("videoUrl", providerData?.videoUrl ? providerData.videoUrl : "");
    setValue("facebook", providerData.facebook);
    setValue("years_of_experience", providerData.years_of_experience);
    setValue("twitter", providerData?.twitter);
    setValue("instagram", providerData?.instagram);
    setValue("youtube", providerData?.youtube);
    setValue("linkedin", providerData?.linkedin);
    setValue("npiId", providerData?.npiId);
    setValue(
      "payment_accepted_methods",
      providerData?.payment_accepted_methods
    );

    if (providerData.askAnExpert === true) {
      setSelect("Yes");
    } else {
      setSelect("No");
    }

    if (providerData?.webLinks && providerData?.webLinks?.length) {
      const temp = providerData?.webLinks.map((map) => {
        map.label = map?.name;
        map.value = map?.link;
        delete map.name;
        delete map.link;
        return map;
      });
      setValue("webLinks", temp);
      setWebLinks(temp);
    }

    if (providerData.video) {
      setValue("video", providerData.video);
    }
    if (providerData.photo) {
      setValue("photo", providerData.photo);
    }
    if (providerData?.banner) {
      setValue("banner", providerData?.banner);
    }
    if (providerData.specilaizations.length > 0) {
      setValue("specilaizations", providerData.specilaizations.join(", "));
    }
    if (providerData?.certifications && providerData?.certifications?.length) {
      setValue("certifications", providerData?.certifications);
      setCertificationRows(providerData?.certifications);
    }
    if (providerData.education.length > 0) {
      setEducationRows(providerData.education);
      setValue("education", providerData.education);
    }
    if (providerData.teams_affiliated.length > 0) {
      setTeamAffiliationsRows(providerData.teams_affiliated);
      setValue("teams_affiliated", providerData.teams_affiliated);
    }
    if (providerData.schools_affiliated.length > 0) {
      setSchoolAffiliationsRows(providerData.schools_affiliated);
      setValue("schools_affiliated", providerData.schools_affiliated);
    }
    if (providerData.videoUrl) {
      setVideoUrl(true);
    }
  };

  useEffect(() => {
    setValue("userType", location?.state?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertification = async () => {
    const { data } = await axios.get("v1/certification?limit=1000");
    setCertification(data?.results);
  };

  useEffect(() => {
    getCertification();
  }, []);

  const FileUploadComponent = ({ fileName, accept }) => {
    const [upload, setUpload] = useState(0);
    const file = useWatch({
      control,
      name: fileName,
    });
    return (
      <div className="image-wraper mb-3 h-100">
        <div className="media-container">
          <MediaPreview
            fileName={file || watch(fileName)}
            type={accept === "image/*" ? "image" : "video"}
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            className="d-none"
            getSignedUrl={getSignedUrl}
            onProgress={(e) => {
              setUpload(e);
            }}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/contents/${file.name}`,
                },
              });

              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName}>
            {!watch(fileName)?.length ? (
              <span className="border btn btn-primary" role="button">
                {fileName === "photo" && "Upload Photo"}
                {fileName === "video" &&
                  `${upload > 0 ? `${upload}% Uploading` : "Upload Video"}`}
                {fileName === "banner" && "Upload banner"}
              </span>
            ) : (
              <></>
            )}
            {getValues(fileName) &&
              (fileName === "photo" ||
                fileName === "video" ||
                fileName === "banner") && (
                <span
                  className="border ml-3 btn btn-danger"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue(fileName, "");
                  }}
                >
                  Remove
                </span>
              )}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <form
                onSubmit={handleSubmit(async (formData) => {
                  if (
                    formData.address !== dataFromApi.address ||
                    formData.state !== dataFromApi.state ||
                    formData.city !== dataFromApi.city ||
                    formData.country !== dataFromApi.country ||
                    formData.zipcode !== dataFromApi.zipcode
                  ) {
                    delete formData.googlePlaceId;
                  }
                  if (select === "No") {
                    formData.askAnExpert = false;
                  }
                  formData.admin = true;

                  if (select === "Yes") {
                    formData.askAnExpert = true;
                  }

                  if (formData?.webLinks?.length) {
                    formData?.webLinks?.forEach((map) => {
                      map.link = map?.__isNew__ ? "" : map?.value;
                      map.name = map?.label;
                      delete map.value;
                      delete map.label;
                      delete map.__isNew__;
                    });
                  }

                  formData.specilaizations = formData.specilaizations
                    .split(",")
                    .map((item) => item.trim());
                  formData.specilaizations.forEach((map, ind) => {
                    if (map === "") {
                      formData.specilaizations.splice(ind, 1);
                    }
                  });
                  formData.education.forEach((map, ind) => {
                    if (
                      JSON.stringify(educationInitValue) === JSON.stringify(map)
                    ) {
                      formData.education.splice(ind, 1);
                    }
                  });
                  formData.certifications.forEach((map, ind) => {
                    if (
                      JSON.stringify(certificationIntitialValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.certifications.splice(ind, 1);
                    }
                  });
                  formData.teams_affiliated.forEach((map, ind) => {
                    if (
                      JSON.stringify(teamAffiliatedInitialValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.teams_affiliated.splice(ind, 1);
                    }
                  });
                  formData.schools_affiliated.forEach((map, ind) => {
                    if (
                      JSON.stringify(schoolAffiliatedInitialValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.schools_affiliated.splice(ind, 1);
                    }
                  });
                  formData.userType = location.state.value;
                  if (formData.phoneNumber === null) {
                    delete formData.phoneNumber;
                  } else {
                    formData.phoneNumber = parseFloat(formData.phoneNumber);
                  }
                  if (formData?.email?.length <= 0) {
                    delete formData?.email;
                  }
                  if (!params.id) {
                    formData.isMOPAdminCreated = true;
                  }
                  if (!params.id) {
                    try {
                      await axios.post("/v1/provider/create/admin", {
                        ...formData,
                        profile_claimed_status: "created",
                      });
                      navigate(location.state.pathName);
                      SwalMessage(
                        "success",
                        `${location?.state?.label} created Successfully.`
                      );
                    } catch (error) {
                      toast.error(error.response.data.message);
                    }
                  } else {
                    if (formData.clinic) {
                      let clinicResult = _.isEqual(
                        formData.clinic,
                        dataFromApi.clinic
                      );
                      if (clinicResult) {
                        delete formData.clinic;
                      } else {
                        delete formData.googlePlaceId;
                      }
                    }
                    try {
                      const { data } = await axios.patch(
                        `/v1/provider/${params.id}`,
                        formData
                      );
                      if (data && data.id) {
                        navigate(`${location.state.pathName}`, {
                          state: provider.planType,
                        });
                        SwalMessage(
                          "success",
                          `${location?.state?.label} updated Successfully.`
                        );
                      }
                    } catch (e) {
                      if (e.response?.data?.message) {
                        toast.error(e.response?.data?.message);
                      } else {
                        toast.error(unknownErrorMessage);
                      }
                    }
                  }
                })}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Basic Information</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"photo"}
                                accept={"image/*"}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"banner"}
                                accept={"image/*"}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="First Name"
                                mandatory={true}
                                name="firstName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Last Name"
                                mandatory={true}
                                name="lastName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Suffix"
                                name="suffix"
                              />
                            </div>
                            {params.id ? (
                              <div className="col-md-4 mb-3">
                                <TextInput
                                  errors={errors}
                                  register={register}
                                  label="Email"
                                  name="email"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Address"
                                name="address"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="State"
                                name="state"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="City"
                                name="city"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Country"
                                name="country"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Zipcode"
                                maxlength={5}
                                name="zipcode"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                maxlength={10}
                                errors={errors}
                                register={register}
                                label="Phone Number"
                                name="phoneNumber"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Website"
                                name="website"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="User Type"
                                name="userType"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="NPI #"
                                name="npiId"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border mt-3 rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">About</h5>
                        </div>
                        <div className="row container-fluid p-4">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label for="" className="form-label fw-bold">
                              About
                            </label>
                            <textarea
                              name=""
                              id=""
                              cols="3"
                              rows="1"
                              className="form-control flex-fill"
                              {...register("biography")}
                            ></textarea>
                          </div>
                          {videoUrl === false &&
                            (getValues("videoUrl") === undefined ||
                              getValues("videoUrl")?.length === 0) && (
                              <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">
                                  Short video about me
                                </label>
                                <div className=" p-4 text-center text-light rounded">
                                  <FileUploadComponent
                                    fileName={"video"}
                                    accept={"video/*"}
                                  />
                                </div>
                              </div>
                            )}
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label for="" className="form-label fw-bold">
                              Training Philosophy
                            </label>
                            <TextInput
                              errors={errors}
                              register={register}
                              mandatory={false}
                              name="training_philosophy"
                            />
                          </div>
                          <div className="col-sm-6 mb-3">
                            <label for="" className="form-label fw-bold">
                              VideoURL
                            </label>
                            <TextInput
                              errors={errors}
                              register={register}
                              mandatory={false}
                              name="videoUrl"
                              setValue={setValue}
                              setVideoUrl={setVideoUrl}
                              disabled={getValues("video")}
                            />
                          </div>
                        </div>
                      </div>
                      <WebLinksComponent
                        setWebLinks={setWebLinks}
                        setValue={setValue}
                        webLinks={webLinks}
                        getValues={getValues}
                        providers={provider}
                      />
                      <div className="bg-white border rounded mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Specializations</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <LabelWithTooltip
                                labelClassName="form-label"
                                id="service"
                                label="Specializations"
                                TooltipText="To add mulitple Specializations, add each
                                Specializations seperated by Comma."
                              />
                              <input
                                type="text"
                                className="form-control mt-2"
                                {...register("specilaizations")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <SocialMedia.Certifications
                        certificationIntitialValue={certificationIntitialValue}
                        setValue={setValue}
                        setCertificationRows={setCertificationRows}
                        getValues={getValues}
                        certificationRows={certificationRows}
                        register={register}
                        watch={watch}
                        certification={certification}
                        errors={errors}
                      />
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <div className="row">
                            <div className="col-md-6">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Years of Experience"
                                name={`years_of_experience`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Teams Affiliated With</h5>
                          {teamAffiliationsRows.map((trainingRow, ind) => {
                            return (
                              <div className="row container-fluid">
                                <div className="col-md-6">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Team Name"
                                    name={`teams_affiliated[${ind}].team_name`}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Team City"
                                    name={`teams_affiliated[${ind}].team_city`}
                                  />
                                </div>
                                <div className="row">
                                  {teamAffiliationsRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("teams_affiliated"),
                                          teamAffiliatedInitialValue,
                                        ];
                                        setTeamAffiliationsRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...teamAffiliationsRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setTeamAffiliationsRows(rows);
                                        setValue("teams_affiliated", rows);
                                      } else {
                                        setTeamAffiliationsRows([
                                          teamAffiliatedInitialValue,
                                        ]);
                                        setValue("teams_affiliated", [
                                          teamAffiliatedInitialValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Schools Affiliated with</h5>
                          {schoolAffiliationsRows.map((trainingRow, ind) => {
                            return (
                              <div className="row container-fluid">
                                <div className="col-md-6">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="School Name"
                                    name={`schools_affiliated[${ind}].school_name`}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="School City"
                                    name={`schools_affiliated[${ind}].school_city`}
                                  />
                                </div>
                                <div className="row">
                                  {schoolAffiliationsRows.length - 1 ===
                                    ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("schools_affiliated"),
                                          schoolAffiliatedInitialValue,
                                        ];
                                        setSchoolAffiliationsRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...schoolAffiliationsRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setSchoolAffiliationsRows(rows);
                                        setValue("schools_affiliated", rows);
                                      } else {
                                        setSchoolAffiliationsRows([
                                          schoolAffiliatedInitialValue,
                                        ]);
                                        setValue("schools_affiliated", [
                                          schoolAffiliatedInitialValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Education</h5>
                          {educationRows.map((educationRow, ind) => {
                            return (
                              <div className="row container-fluid">
                                <div className="col-md-4">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Degree"
                                    name={`education[${ind}].degree`}
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Degree Institute"
                                    name={`education[${ind}].college`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Year of Completion"
                                    name={`education[${ind}].completionyear`}
                                  />
                                </div>
                                <div className="row">
                                  {educationRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("education"),
                                          educationInitValue,
                                        ];
                                        setEducationRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...educationRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setEducationRows(rows);
                                        setValue("education", rows);
                                      } else {
                                        setEducationRows([educationInitValue]);
                                        setValue("education", [
                                          educationInitValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Social Media</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <SocialMedia.SocialMedia
                              errors={errors}
                              register={register}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      {params.id && (
                        <div className="row">
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("active")}
                                type="checkbox"
                                id="active"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("mopRecommended")}
                                type="checkbox"
                                id="mopRecommended"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="mopRecommended"
                              >
                                MOP Recommended
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <Button
                        className="btn btn-primary mt-4"
                        value={!params.id ? "Submit" : "Update"}
                        type="submit"
                      />
                      <Button
                        className="btn btn-secondary mt-4 ms-2"
                        onClick={() => {
                          navigate(`${location.state.pathName}`, {
                            state: provider?.planType,
                          });
                        }}
                        value="Cancel"
                        type="button"
                      />
                      <div className="row mt-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AthleticTrainerForm;
