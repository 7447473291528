import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import { toast } from "react-toastify";
import TextInput from "../../common/TextInput";
import MediaPreview from "../../custom/MediaPreview";
import SideNavigation from "../../layout/SideNavigation";
import TopNavigation from "../../layout/TopNavigation";
import axios from "../../../util/axiosInst";
import WebLinksComponent from "../../custom/WebLinksComponent";
import { unknownErrorMessage } from "../../../util/constants";
import { ClinicSchema } from "../../../util/schema";
import {
  certificationIntitialValue,
  leadershipInitValue,
  serviceInitValue,
} from "./init";
import CheckBox from "../../common/CheckBox";
import SocialMedia from "../../custom/SocialMedia";
import { SwalMessage } from "../../../util/utils";
import Button from "../../common/Button";
import _ from "lodash";
const ClinicForm = () => {
  const [videoUrl, setVideoUrl] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [dataFromApi, setDataFromApi] = useState({});
  const [provider, setProvider] = useState(null);
  const [serviceRows, setServiceRows] = useState([serviceInitValue]);
  const [certificationRows, setCertificationRows] = useState([
    certificationIntitialValue,
  ]);
  const [certification, setCertification] = useState();

  const [webLinks, setWebLinks] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [leadershipRows, setleadershipRows] = useState([leadershipInitValue]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ClinicSchema),
  });
  const location = useLocation();

  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProvider = async () => {
    const { data: providerData } = await axios.get(`/v1/provider/${params.id}`);
    setDataFromApi(providerData);
    setProvider(providerData);
    setValue("firstName", providerData?.firstName);
    setValue("email", providerData?.email);
    setValue("website", providerData.website);
    setValue("address", providerData?.address);
    setValue("state", providerData?.state);
    setValue("biography", providerData.biography);
    setValue("city", providerData?.city);
    setValue("userType", providerData?.userType.replace("_", " "));
    setValue("zipcode", providerData?.zipcode);
    setValue("phoneNumber", providerData?.phoneNumber);
    setValue("videoUrl", providerData?.videoUrl ? providerData.videoUrl : "");
    setValue("active", providerData?.active);
    setValue("googlePlaceId", providerData?.googlePlaceId || "");
    setValue("mopRecommended", providerData?.mopRecommended);
    setValue("facebook", providerData.facebook);
    setValue("twitter", providerData?.twitter);
    setValue("instagram", providerData?.instagram);
    setValue("youtube", providerData?.youtube);
    setValue("linkedin", providerData?.linkedin);
    setValue("npiId", providerData?.npiId);
    if (providerData.photo) {
      setValue("photo", providerData.photo);
    }
    if (providerData?.banner) {
      setValue("banner", providerData?.banner);
    }

    if (providerData?.webLinks && providerData?.webLinks?.length) {
      const temp = providerData?.webLinks.map((map) => {
        map.label = map?.name;
        map.value = map?.link;
        delete map.name;
        delete map.link;
        return map;
      });
      setValue("webLinks", temp);
      setWebLinks(temp);
    }

    if (providerData?.certifications && providerData?.certifications?.length) {
      setValue("certifications", providerData?.certifications);
      setCertificationRows(providerData?.certifications);
    }

    if (providerData.video) {
      setValue("video", providerData.video);
    }

    if (providerData.leadership_team.length > 0) {
      setleadershipRows(providerData.leadership_team);
      setValue("leadership_team", providerData.leadership_team);
    }
    if (providerData.services.length > 0) {
      setServiceRows(providerData.services);
      setValue("services", providerData.services);
    }
    if (providerData.videoUrl) {
      setVideoUrl(true);
    }
  };

  useEffect(() => {
    setValue("userType", location?.state?.label);
    if (params?.id) getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertification = async () => {
    const { data } = await axios.get("v1/certification?limit=1000");
    setCertification(data?.results);
  };

  useEffect(() => {
    getCertification();
  }, []);

  const FileUploadComponent = ({ fileName, accept }) => {
    const [upload, setUpload] = useState(0);
    const file = useWatch({
      control,
      name: fileName,
    });
    return (
      <div className="image-wraper mb-3 h-100">
        <div className="media-container">
          <MediaPreview
            fileName={file || watch(fileName)}
            type={accept === "image/*" ? "image" : "video"}
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            className="d-none"
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onProgress={(e) => {
              setUpload(e);
            }}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/contents/${file.name}`,
                },
              });

              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName}>
            {!watch(fileName)?.length ? (
              <span className="border btn btn-primary" role="button">
                {fileName === "photo" && "Upload Photo"}
                {fileName === "video" &&
                  `${upload > 0 ? `${upload}% Uploading` : "Upload Video"}`}
                {fileName === "banner" && "Upload banner"}
              </span>
            ) : (
              <></>
            )}
            {getValues(fileName) &&
              (fileName === "photo" ||
                fileName === "video" ||
                fileName === "banner") && (
                <span
                  className="border ml-3 btn btn-danger"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setValue(fileName, "");
                  }}
                >
                  Remove
                </span>
              )}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <form
                onSubmit={handleSubmit(async (formData) => {
                  formData.userType = location.state.value;

                  formData.lastName = "clinic";
                  if (!params.id) {
                    formData.isMOPAdminCreated = true;
                  }
                  if (
                    formData.address !== dataFromApi.address ||
                    formData.state !== dataFromApi.state ||
                    formData.city !== dataFromApi.city ||
                    formData.country !== dataFromApi.country ||
                    formData.zipcode !== dataFromApi.zipcode
                  ) {
                    delete formData.googlePlaceId;
                  }
                  if (
                    typeof formData.phoneNumber === "undefined" ||
                    formData.phoneNumber === null
                  ) {
                    delete formData.phoneNumber;
                  } else {
                    formData.phoneNumber = parseFloat(formData.phoneNumber);
                  }
                  formData.admin = true;
                  formData.leadership_team.forEach((map, ind) => {
                    if (
                      JSON.stringify(leadershipInitValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.leadership_team.splice(ind, 1);
                    }
                  });

                  if (formData?.webLinks?.length) {
                    formData?.webLinks?.forEach((map) => {
                      map.link = map?.__isNew__ ? "" : map?.value;
                      map.name = map?.label;
                      delete map.value;
                      delete map.label;
                      delete map.__isNew__;
                    });
                  }

                  if (formData?.email?.length <= 0) {
                    delete formData?.email;
                  }
                  if (!params.id) {
                    try {
                      await axios.post("/v1/provider/create/admin", {
                        ...formData,
                        profile_claimed_status: "created",
                      });
                      navigate(location.state.pathName);
                      SwalMessage(
                        "success",
                        `${location?.state?.label} created successfully.`
                      );
                    } catch (error) {
                      toast.error(error.response.data.message);
                    }
                  } else {
                    if (formData.clinic) {
                      let clinicResult = _.isEqual(
                        formData.clinic,
                        dataFromApi.clinic
                      );
                      if (clinicResult) {
                        delete formData.clinic;
                      } else {
                        delete formData.googlePlaceId;
                      }
                    }
                    try {
                      const { data } = await axios.patch(
                        `/v1/provider/${params.id}`,
                        formData
                      );
                      if (data && data.id) {
                        navigate(`${location.state.pathName}`, {
                          state: provider.planType,
                        });
                        SwalMessage(
                          "success",
                          `${location?.state?.label} updated successfully.`
                        );
                      }
                    } catch (e) {
                      if (e.response?.data?.message) {
                        toast.error(e.response?.data?.message);
                      } else {
                        toast.error(unknownErrorMessage);
                      }
                    }
                  }
                })}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Basic Information</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"photo"}
                                accept={"image/*"}
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <FileUploadComponent
                                fileName={"banner"}
                                accept={"image/*"}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Clinic Name"
                                mandatory={true}
                                name="firstName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Address"
                                name="address"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="City"
                                name="city"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="State"
                                name="state"
                              />
                            </div>

                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Zipcode"
                                name="zipcode"
                                maxlength={5}
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                maxlength={10}
                                errors={errors}
                                register={register}
                                label="Phone Number"
                                name="phoneNumber"
                              />
                            </div>
                            {params.id ? (
                              <div className="col-md-4 mb-3">
                                <TextInput
                                  errors={errors}
                                  register={register}
                                  label="Email"
                                  name="email"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="Website"
                                name="website"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="User Type"
                                name="userType"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="NPI #"
                                name="npiId"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="">About Clinic</h5>
                        </div>
                        <div className="row container-fluid py-4 px-5">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label for="" className="form-label ">
                              About Clinic
                            </label>
                            <textarea
                              name=""
                              id=""
                              cols="3"
                              rows="1"
                              className="form-control flex-fill"
                              {...register("biography")}
                            />
                          </div>
                          {videoUrl === false &&
                            (getValues("videoUrl") === undefined ||
                              getValues("videoUrl")?.length === 0) && (
                              <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">
                                  Short video about me
                                </label>
                                <div className=" p-4 text-center text-light rounded">
                                  <FileUploadComponent
                                    fileName={"video"}
                                    accept={"video/*"}
                                  />
                                </div>
                              </div>
                            )}
                          <div className="col-sm-6 mb-3">
                            <label for="" className="form-label fw-bold">
                              VideoURL
                            </label>
                            <TextInput
                              errors={errors}
                              register={register}
                              mandatory={false}
                              name="videoUrl"
                              setValue={setValue}
                              setVideoUrl={setVideoUrl}
                              disabled={getValues("video") ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                      <WebLinksComponent
                        setWebLinks={setWebLinks}
                        setValue={setValue}
                        webLinks={webLinks}
                        getValues={getValues}
                        providers={provider}
                      />
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Leadership team</h5>
                        </div>
                        <div className="p-4">
                          <div className="row container-fluid">
                            {leadershipRows.map((leadershipRow, ind) => {
                              return (
                                <>
                                  <div className="col-md-12 mb-3">
                                    <div className="row mb-3">
                                      <div className="col-md-6 mb-3">
                                        <TextInput
                                          errors={errors}
                                          register={register}
                                          label="Title"
                                          name={`leadership_team[${ind}].teamTitle`}
                                        />
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <TextInput
                                          errors={errors}
                                          register={register}
                                          label="First Name"
                                          name={`leadership_team[${ind}].firstName`}
                                        />
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <TextInput
                                          errors={errors}
                                          register={register}
                                          label="Last Name"
                                          name={`leadership_team[${ind}].lastName`}
                                        />
                                      </div>
                                    </div>

                                    <div className="row mt-3 mb-5">
                                      {leadershipRows.length - 1 === ind && (
                                        <div
                                          className="col-md-4 text-primary cursor-pointer"
                                          onClick={() => {
                                            const rows = [
                                              ...getValues("leadership_team"),
                                              leadershipInitValue,
                                            ];
                                            setleadershipRows(rows);
                                          }}
                                        >
                                          Add new
                                        </div>
                                      )}
                                      <div
                                        className="col-md-1 text-danger cursor-pointer"
                                        onClick={() => {
                                          let rows = [...leadershipRows];
                                          if (rows.length > 1) {
                                            rows.splice(ind, 1);
                                            setleadershipRows(rows);
                                            setValue("leadership_team", rows);
                                          } else {
                                            setleadershipRows([
                                              leadershipInitValue,
                                            ]);
                                            setValue("leadership_team", [
                                              leadershipInitValue,
                                            ]);
                                          }
                                        }}
                                      >
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <SocialMedia.Certifications
                        certificationIntitialValue={certificationIntitialValue}
                        setValue={setValue}
                        setCertificationRows={setCertificationRows}
                        getValues={getValues}
                        certificationRows={certificationRows}
                        register={register}
                        watch={watch}
                        certification={certification}
                        errors={errors}
                      />
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Service Lines</h5>
                        </div>
                        {serviceRows.map((serviceRow, ind) => {
                          return (
                            <div className="p-4">
                              <div className="row container-fluid">
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Service Lines"
                                    name={`services[${ind}].serviceLines`}
                                  />
                                </div>

                                <div className="row">
                                  {serviceRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("services"),
                                          serviceInitValue,
                                        ];
                                        setServiceRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...getValues("services")];

                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setServiceRows(rows);
                                        setValue("services", rows);
                                      } else {
                                        setServiceRows([serviceInitValue]);
                                        setValue("services", [
                                          serviceInitValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Social Media</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <SocialMedia.SocialMedia
                              errors={errors}
                              register={register}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      {params.id && (
                        <div className="row">
                          <div className="col-3">
                            <CheckBox
                              register={register}
                              id={"active"}
                              value={"active"}
                              label={"Active"}
                            />
                          </div>
                          <div className="col-3">
                            <CheckBox
                              register={register}
                              id={"mopRecommended"}
                              value={"mopRecommended"}
                              label={"MOP Recommended"}
                            />
                          </div>
                        </div>
                      )}
                      <Button
                        className="btn btn-primary mt-4"
                        value={!params.id ? "Submit" : "Update"}
                        type="submit"
                      />
                      <Button
                        className="btn btn-secondary mt-4 ms-2"
                        onClick={() => {
                          navigate(`${location.state.pathName}`, {
                            state: provider?.planType,
                          });
                        }}
                        value="Cancel"
                        type="button"
                      />
                      <div className="row mt-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClinicForm;
