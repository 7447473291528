import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";
import { useNavigate } from "react-router-dom";
import { BsClipboardData } from "react-icons/bs";

const { useState } = React;

const UserInformation = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("managepatients");
  const [contents, setContents] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [excelsort, setexcelsort] = useState("updatedAt:desc");
  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();
  const downloadExcel = async () => {
    let response;
    if (key === "education") {
      response = await axios.get(
        `/v1/blog/viewed-users?type=education&limit=${totalRecord}${contents.length < 1 ? '' : '&search=' + contents}&sortBy=${excelsort}`
      );
    } else if (key === "managepatients") {
      response = await axios.get(`/v1/user?role=patient&&limit=${totalRecord}${contents.length < 1 ? '' : '&name=' + contents}&sortBy=${excelsort}`);
    } else if (key === "results") {
      response = await axios.get(`v1/evaluation/history-report?&sortBy=${excelsort}&&limit=100000`);
    } else {
      response = await axios.get(
        `/v1/blog/viewed-users?type=evaluation&limit=${totalRecord}${contents.length < 1 ? '' : '&search=' + contents}&sortBy=${excelsort}`
      );
    }
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ firstName, lastName, email, zipcode, createdAt, active, age, gender, result, updatedAt }) =>
          key === "managepatients"
            ? {
              Name: firstName + " " + lastName,
              Email: email,
              "Created Date": moment(createdAt).format("lll"),
              Active: active === true ? "YES" : "NO",
            }
            : key === "results" ? {
              Age: age,
              Gender: gender,
              Condition: result.conditions.name,
              "Confidence %": result.conditions.confidence,
              "Created Date": moment(updatedAt).format("lll")
            } : {
              Name: firstName + " " + lastName,
              Email: email,
              "Zip Code": zipcode,
              "Created Date": moment(createdAt).format("lll"),
            }
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD")}.xlsx`);
  };

  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    {
      dataField: "firstName",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {row.firstName} {row.lastName}
            </span>
          </>
        );
      },
    },
    { dataField: "email", text: "Email", sort: true },
    { dataField: "zipcode", text: "Zip code", sort: true },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("lll")}</>;
      },
    },
  ];
  const manage_patients_column = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "name", text: "Name", sort: true },
    { dataField: "email", text: "Email", sort: true },
    {
      dataField: "createdAt",
      text: "Created Date",
      formatter: (date) => {
        return <>{moment(date).format("lll")}</>;
      },
      sort: true,
    },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
    {
      dataField: "",
      text: "Results",

      headerStyle: { width: 100 },
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <span
              role="button"
              onClick={() =>
                navigate(`/results/${row._id}`, { state: window.location.pathname })
              }
            >
              <BsClipboardData className="mr-3 icon" />
            </span>
          </>
        );
      },
    },
  ];
  const results_columns = [
    {
      dataField: "age",
      text: "Age",
      sort: false,
    },
    {
      dataField: "gender",
      text: "Gender",
      sort: false,
    },
    {
      dataField: "result.conditions.name",
      text: "Condition",
      sort: false,
    },
    {
      dataField: "result.conditions.confidence",
      text: "Confidence %",
      sort: false,
    },
    {
      dataField: "updatedAt",
      text: "Created Date",
      formatter: (date) => {
        return <>{moment(date).format("lll")}</>;
      },
      sort: true,
    },
  ];
  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const tabchange = (k) => {
    setKey(k);
    setexcelsort("updatedAt:desc")
  }
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="User Information" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="d-flex justify-content-end">
                {key === "results" ?
                  <button
                    type="button"
                    className="btn btn-primary rounded mb-3 me-2"
                    onClick={() => downloadExcel()}
                    style={{ paddingTop: "8px", paddingBottom: "8px" }}
                  >
                    Download as Excel
                  </button>
                  :
                  <div
                    className="input-group rounded me-2 ms-2 mb-3"
                    style={{ width: "50%" }}
                  >

                    <input
                      type="text"
                      className={"form-control bg-transparent p-2"}
                      placeholder="Search User... "
                      style={{
                        maxWidth: "100%",
                        borderTop: "1px solid #b5b5b5",
                        borderLeft: "1px solid #b5b5b5",
                        borderRadius: "5px 0 0 5px",
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          setContents(getValues("searchQuery"));
                        }
                      }}
                      {...register("searchQuery")}
                    />
                    <button
                      className="input-group-text text-white btn btn-primary me-3"
                      onClick={() => {
                        setContents(getValues("searchQuery"));
                      }}
                      style={{
                        borderRadius: "0 5px 5px 0",
                      }}
                    >
                      <i className="fa fa-solid fa-search"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary rounded"
                      onClick={() => downloadExcel()}
                    >
                      Download as Excel
                    </button>
                  </div>
                }
              </div>
              <div className="box">
                <div className="box-content">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => tabchange(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="managepatients" title="Signed Up">
                      {key === "managepatients" && (
                        <CustomDataTable
                          columns={manage_patients_column}
                          searchQuery={contents}
                          editUrl="/editpatient"
                          deleteUrl={null}
                          setTotalRecord={setTotalRecord}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`/v1/user?role=patient&&`}
                          setexcelsort={setexcelsort}
                        />
                      )}
                    </Tab>
                    <Tab eventKey="results" title="User Results">
                      {key === "results" && (
                        <CustomDataTable
                          columns={results_columns}
                          searchQuery={contents}
                          fetchContentsUrl={`v1/evaluation/history-report?`}
                          setexcelsort={setexcelsort}
                        />
                      )}
                    </Tab>

                    <Tab eventKey="evaluation" title="Evaluation">
                      {key === "evaluation" && (
                        <CustomDataTable
                          columns={columns}
                          setTotalRecord={setTotalRecord}
                          editUrl={null}
                          deleteUrl={null}
                          value="user_information"
                          label="User Information"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/blog/viewed-users?type=evaluation&`}
                          setexcelsort={setexcelsort}
                        />
                      )}
                    </Tab>
                    <Tab eventKey="education" title="Education">
                      {key === "education" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl={null}
                          setTotalRecord={setTotalRecord}
                          deleteUrl={null}
                          value="user_information"
                          label="User Information"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/blog/viewed-users?type=education&`}
                          setexcelsort={setexcelsort}
                        />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformation;
