import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";

export default function AdminStaff() {
  const navigate = useNavigate();
  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "name", text: "Name", sort: true },
    { dataField: "email", text: "Email", sort: true },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Admin Staff List" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-title">
                  <button
                    type="button"
                    className="btn mb-1 btn-success"
                    onClick={() => {
                      navigate("/createadmin");
                    }}
                  >
                    Add Admin Staff
                  </button>
                </div>
                <div></div>
                <div className="box-content">
                  <CustomDataTable
                    columns={columns}
                    editUrl="/editadmin"
                    deleteUrl="v1/user"
                    fetchContentsUrl={`/v1/user?role=admin&&deleted=false&&`}
                    role="admin"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
