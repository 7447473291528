export const classesInitValue = {
  taught: "",
  location: "",
  timings: [{ days: [], start_time: "", end_time: "" }],
};

export const certificationIntitialValue = {
  label: "",
  value: "",
};
export const affiliatedGymsInitValue = {
  affiliatedGymsName: "",
};
