export const classesInitValue = {
  taught: "",
  instructor: "",
  location: "",
  timings: [{ days: [], start_time: "", end_time: "" }],
};

export const certificationInitValue = {
  label: "",
  value: "",
};

export const affiliatedGymsInitValue = {
  affiliatedGymsName: "",
};

export const modalitiesInitValue = {
  modalities_Name: "",
};
export const programInitValue = {
  programName: "",
};
export const leadershipInitValue = {
  teamName: "",
  teamTitle: "",
  teamEmail: "",
};
export const trainersAvailableInitValue = {
  trainers_Available: "",
};
export const hoursOfOperationInitValue = {
  days: [],
  start_time: "",
  end_time: "",
};
