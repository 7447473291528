import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useNavigate,useParams } from "react-router-dom";

const { useState } = React;

const Newsletter = () => {
  const { tab } = useParams();
  const [key, setKey] = useState(tab==="list" ? "newsletter" : "subscribed");
  const [contents, setContents] = useState([]);
  const navigate = useNavigate();
  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "email", text: "Email"},
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("ll")}&nbsp; {moment(date).format("hh:mm A")}</>;
      },
    },
  ];
  const columns_newletter = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "title", text: "Title" },
    { dataField: "categoryId.name", text: "Category"},
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("ll")}&nbsp;{moment(date).format("hh:mm A")}</>;
      },
    },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];
  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Newsletter" />
          <div className="row mt-4">
            <div className="col-sm-12">
            <div className="d-flex justify-content-end mb-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      navigate("/add-newsletter", {
                        state: {
                          value: "add-newsletter",
                          label: "add-newsletter",
                          pathName: window.location.pathname,
                        },
                      })
                    }
                  >
                    Add Newsletter
                  </button>
                </div>
              <div className="box">
                <div className="box-content">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="subscribed" title="Subscribed Users">
                      {key === "subscribed" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl={null}
                          deleteUrl={null}
                          value="subscribed"
                          label="Subscribed User"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/news-letter/subscriptions?isSubscribed=true&&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="unsubscribed" title="Unsubscribed Users">
                      {key === "unsubscribed" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl={null}
                          deleteUrl={null}
                          value="unsubscribed"
                          label="Unsubscribed User"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/news-letter/subscriptions?isSubscribed=false&&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="newsletter" title="Newsletters">
                      {key === "newsletter" && (
                        <CustomDataTable
                          columns={columns_newletter}
                          editUrl="/edit-newsletter"
                          deleteUrl={"/v1/news-letter"}
                          value="newsletter"
                          label="News letter"
                          searchQuery={contents}
                          role="admin"
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/news-letter/admin?deleted=false&&"
                        />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
