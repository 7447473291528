import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ClaimReasonModal = ({ setShow, show, onSubmit }) => {
  const handleClose = () => setShow(false);
  const [reason, setReason] = useState("");
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="text-center bg-teal rounded border border-2 border-primary">
        <form>
          <h5>Remarks</h5>
          <textarea
            name="reason"
            id=""
            cols="4"
            rows="4"
            required
            value={reason}
            className="form-control"
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <button
            className="btn btn-secondary mt-2 mx-2"
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            CANCEL
          </button>
          <button
            className="btn btn-primary mt-2"
            type="button"
            onClick={() => {
              onSubmit(false, reason);
              handleClose();
            }}
          >
            SUBMIT
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimReasonModal;
