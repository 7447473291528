import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import axios from "../../util/axiosInst";
import { unknownErrorMessage } from "../../util/constants";
//dev fix
export default function TopNavigation({ title }) {
  const handleLogout = async () => {
    try {
      await axios.post("/v1/auth/logout", {
        refreshToken: JSON.parse(localStorage.getItem("mop-admin-token"))
          .refresh.token,
      });
      localStorage.removeItem("mop-admin-token");
      localStorage.removeItem("mop-admin-user");
      window.location.href = "/login";
    } catch (error) {
      toast.error(unknownErrorMessage);
    }
  };

  const onIdle = () => {
    handleLogout();
  };

  useIdleTimer({ onIdle, timeout: 1000 * 60 * 30 });

  return (
    <div className="top-navigation">
      <div className="row">
        <div className="col-sm-6">
          <h5 className="ml-3 mt-1 mb-0">{title}</h5>
        </div>
        <div className="col-sm-6">
          <div className="dropdown top-profile float-right">
            <button
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="far fa-user"></i> Admin
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              onClick={(e) => {
                handleLogout();
              }}
            >
              <button className="dropdown-item">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
