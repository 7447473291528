import * as yup from "yup";
import {
  EmailRequired,
  FBurl,
  FirstnameRequired,
  insUrl,
  LastnameRequired,
  linkedinUrl,
  TWurl,
  ValidURL,
  WebsiteMessage,
  websiteRegex,
  youtubeUrl,
} from "./constants";

export const AtheleticTrainerSchema = yup.object({
  firstName: yup.string().required(FirstnameRequired),
  lastName: yup.string().required(LastnameRequired),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup
        .string()
        .email()
        .when("isMOPAdminCreated", {
          is: false,
          then: yup.string().email().required(EmailRequired),
        }),
    }),
  isMOPAdminCreated: yup.boolean(),
  suffix: yup.string(),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  training_philosophy: yup.string(),
  biography: yup.string(),
  askAnExpert: yup.boolean(),
  years_of_experience: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  active: yup.boolean(),
  publish: yup.boolean(),
  mopRecommended: yup.boolean(),
  videoUrl: yup.string(),
  certifications: yup.array().of(
    yup.object().shape({
      year: yup.string(),
      certification: yup.string(),
    })
  ),
  affiliations: yup.array().of(
    yup.object().shape({
      teamAffilated: yup.string(),
      schoolAffilated: yup.string(),
    })
  ),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
});

export const ClinicSchema = yup.object({
  firstName: yup.string().required("Clinic Name is a required field"),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
});

export const GymSchema = yup.object({
  firstName: yup.string().required("Gym Name is a required field"),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  suffix: yup.string(),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  photo: yup.string(),
  videoUrl: yup.string(),
  philosophy: yup.string(),
  classes_taught: yup.string(),
  classes_location: yup.string(),
  classes_date_time: yup.string(),
  payment_accepted_methods: yup.string(),
  years_in_business: yup.string(),

  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  about: yup.string(),
  services: yup.string(),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  askAnExpert: yup.boolean(),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  modalities_available: yup.array().of(
    yup.object().shape({
      modalitiesName: yup.string(),
    })
  ),
  programs: yup.array().of(
    yup.object().shape({
      programName: yup.string(),
    })
  ),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamName: yup.string(),
      teamTitle: yup.string(),
      teamEmail: yup.string(),
    })
  ),
  trainers_available: yup.array().of(
    yup.object().shape({
      trainers_Available: yup.string(),
    })
  ),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      instructor: yup.string(),
      location: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),
  hours_of_operation: yup.array().of(
    yup.object({
      days: yup.array().of(yup.string()),
      start_time: yup.string(),
      end_time: yup.string(),
    })
  ),
});

export const HospitalSchema = yup.object({
  firstName: yup.string().required("Hospital Name is a required field"),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
});

export const ProviderSchema = yup.object({
  firstName: yup.string().required(FirstnameRequired),
  lastName: yup.string().required(LastnameRequired),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  prefix: yup.string(),
  suffix: yup.string(),
  gender: yup.string(),
  phoneNumber: yup.string().nullable(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  affiliations: yup.array().of(
    yup.object().shape({
      hospital_name: yup.string(),
      hospital_city: yup.string(),
    })
  ),
  userType: yup.string(),
  biography: yup.string(),
  askAnExpert: yup.boolean(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  clinic: yup.array().of(
    yup.object({
      name: yup.string(),
      addressLine: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      zipcode: yup.string().max(5),
      businessHours: yup.array().of(
        yup.object().shape({
          startDay: yup.string(),
          endDay: yup.string(),
          startTime: yup.string(),
          endTime: yup.string(),
        })
      ),
    })
  ),
  emailIds: yup.array().of(yup.string().email("this must be a valid email")),
  years_of_experience: yup.string(),
  services: yup.string(),
  specilaizations: yup.string(),
  active: yup.boolean(),
  publish: yup.boolean(),
  mopRecommended: yup.boolean(),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  training: yup.array().of(
    yup.object().shape({
      trainingType: yup.string(),
      location: yup.string(),
      completionyear: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
});

export const PersonalTrainerSchema = yup.object({
  firstName: yup.string().required(FirstnameRequired),
  lastName: yup.string().required(LastnameRequired),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  suffix: yup.string(),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  biography: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  training_philosophy: yup.string(),
  payment_accepted_methods: yup.string(),
  years_of_experience: yup.string(),
  services: yup.string(),
  specilaizations: yup.string(),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  askAnExpert: yup.boolean(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      location: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),
});

export const PhysicalTherapistSchema = yup.object({
  firstName: yup.string().required(FirstnameRequired),
  lastName: yup.string().required(LastnameRequired),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  suffix: yup.string(),
  userType: yup.string(),
  biography: yup.string(),
  photo: yup.string(),
  videoUrl: yup.string(),
  philosophy: yup.string(),
  payment_accepted_methods: yup.string(),
  years_of_experience: yup.string(),
  services: yup.string(),
  specilaizations: yup.string(),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  askAnExpert: yup.boolean(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  rehab_info: yup.object({
    name: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string().max(5),
    phoneNumber: yup.string().nullable(),
  }),
});

export const RehabilitationCenterSchema = yup.object({
  firstName: yup.string().required("Rehabilitation Name is a required field"),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
});

export const WellnessProviderSchema = yup.object({
  firstName: yup.string().required(FirstnameRequired),
  lastName: yup.string().required(LastnameRequired),
  email: yup
    .string()
    .email()
    .when("isMOPAdminCreated", {
      is: false,
      then: yup.string().email().required(EmailRequired),
    }),
  suffix: yup.string(),
  phoneNumber: yup.string().nullable(),
  userType: yup.string(),
  title: yup.string(),
  biography: yup.string(),
  photo: yup.string(),
  videoUrl: yup.string(),
  philosophy: yup.string(),
  classes_taught: yup.string(),
  classes_location: yup.string(),
  classes_date_time: yup.string(),
  payment_accepted_methods: yup.string(),
  years_of_experience: yup.string(),
  services: yup.string(),
  specilaizations: yup.string(),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: ValidURL,
  }),
  askAnExpert: yup.boolean(),

  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: WebsiteMessage,
  }),

  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  business_info: yup.object({
    name: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string().max(5),
    phoneNumber: yup.string().nullable(),
  }),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      location: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),
});

export const AddVideoSchema = (video) => {
  return yup
    .object({
      banner: yup.string().nullable(),
      active: yup.boolean(),
      title: yup.string().required("Title is required"),
      categoryId: yup.string().required("Category is required"),
      video:
        video === ""
          ? yup.string().required("Please Upload Video or Video URL")
          : yup.string().nullable(),
      link:
        video === ""
          ? yup.string().required("Please Upload Video or Video URL")
          : yup.string().nullable(),
    })
    .required();
};
