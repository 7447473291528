import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";

const { useState } = React;

const Providers = () => {
  const { tab } = useParams();
  const [key, setKey] = useState("claimprofile");
  const [contents, setContents] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);

  const navigate = useNavigate();
  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const capitalizeWords = (inputString) => {
    const words = inputString.split('_');
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  const downloadExcel = async () => {
    let response;
    if (key === "claimprofile") {
      response = await axios.get(
        `v1/claimProfile?userType=&active=true&limit=${totalRecord}${contents.length<1?'':'&name='+contents}&sortBy=createdAt:desc`
      );
    } else if(key === "premium") {
      response = await axios.get(
        `v1/provider/adminlist?deleted=false&planType=premium&limit=${totalRecord}${contents.length<1?'':'&name='+contents}&sortBy=createdAt:desc`
      );
    } else if(key === "mopcreated") {
      response = await axios.get(
        `v1/provider/adminlist?publish=true&isMOPAdminCreated=true&profile_claimed_status=created,rejected&deleted=false&limit=${totalRecord}${contents.length<1?'':'&name='+contents}&sortBy=createdAt:desc`
      );
    } else if(key === "basic") {
      response = await axios.get(
        `v1/provider/adminlist?deleted=false&planType=basic&limit=${totalRecord}${contents.length<1?'':'&name='+contents}&sortBy=createdAt:desc`
      );
    }
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ firstName, lastName, email, createdAt, first_name, last_name, publish, active, userType, userInfo, approved }) =>
          key === "claimprofile"
            ? {
              Name: first_name + " " + last_name,
              Email: email,
              "Created Date": moment(createdAt).format("lll"),
              "Provider Type": capitalizeWords(userInfo[0].userType),
              "Approved": approved ? "YES" : "NO"
            }
            : {
              Name: firstName + " " + lastName,
              Email: email,
              "Provider Type": capitalizeWords(userType),
              "Created Date": moment(createdAt).format("lll"),
              "Published": publish ? "YES" : "NO",
              "Active": active ? "YES" : "NO"
            }
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD")}.xlsx`);
  };

  const claimProfileColumns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    {
      dataField: "first_name",
      text: "Name",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {row.first_name} {row.last_name}
            </span>
          </>
        );
      },
    },
    { dataField: "email", text: "Email", sort: true },
    {
      dataField: "userInfo[0].userType", text: "Provider Type",headerStyle:{width:220},
      formatter: (cell) => {
        return (
          <>
            {capitalizeWords(cell)==="Athletic Trainers"?"Athletic Trainer": capitalizeWords(cell)==="Neuro Surgeon"?"Neurosurgeon":capitalizeWords(cell)}

          </>
        );
      }
    },
    {
      dataField: "createdAt",
      headerStyle: { width: 180 },
      text: "Created Date",
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("lll")}</>;
      },
    },
    {
      dataField: "approved",
      text: "Approved",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];

  const Premiumcolumns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {row.first_name || row.firstName} {row.last_name || row.lastName}
            </span>
          </>
        );
      },
    },
    { dataField: "email", text: "Email", sort: true },
    {
      dataField: "userType", text: "Provider Type",headerStyle:{width:220},
      formatter: (cell) => {
        return (
          <>
            {capitalizeWords(cell)==="Athletic Trainers"?"Athletic Trainer": capitalizeWords(cell)==="Neuro Surgeon"?"Neurosurgeon":capitalizeWords(cell)}
          </>
        );
      }
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      headerStyle: { width: 180 },
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("lll")}</>;
      },
    },
    {
      dataField: "publish",
      text: "Published",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Providers" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="d-flex justify-content-end">
                <div
                  className="input-group rounded me-2 ms-2 mb-3"
                  style={{ width: "50%" }}
                >
                  <input
                    type="text"
                    className={"form-control bg-transparent p-2"}
                    placeholder="Search User... "
                    style={{
                      maxWidth: "100%",
                      borderTop: "1px solid #b5b5b5",
                      borderLeft: "1px solid #b5b5b5",
                      borderRadius: "5px 0 0 5px",
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        setContents(getValues("searchQuery"));
                      }
                    }}
                    {...register("searchQuery")}
                  />
                  <button
                    className="input-group-text text-white btn btn-primary"
                    onClick={() => {
                      setContents(getValues("searchQuery"));
                    }}
                    style={{
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    <i className="fa fa-solid fa-search"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-3 rounded"
                    onClick={() => downloadExcel()}
                  >
                    Download as Excel
                  </button>
                </div>
              </div>
              <div className="box">
                <div className="box-content">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="claimprofile" title="Claim Profile Requests">
                      {key === "claimprofile" && (
                        <CustomDataTable
                          columns={claimProfileColumns}
                          value="claimprofile"
                          label="Claim Profile Requests"
                          setTotalRecord={setTotalRecord}
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/claimProfile?active=true&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="mopcreated" title="MOP Created">
                      {key === "mopcreated" && (
                        <CustomDataTable
                          columns={Premiumcolumns}
                          value="mopcreated"
                          label="MOP Created"
                          setTotalRecord={setTotalRecord}
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/provider/adminlist?publish=true&isMOPAdminCreated=true&profile_claimed_status=created,rejected&deleted=false&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="basic" title="Basic Users">
                      {key === "basic" && (
                        <CustomDataTable
                          columns={Premiumcolumns}
                          value="basic"
                          label="Basic Users"
                          setTotalRecord={setTotalRecord}
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/provider/adminlist?deleted=false&planType=basic&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="premium" title="Premium Users">
                      {key === "premium" && (
                        <CustomDataTable
                          columns={Premiumcolumns}
                          value="premium"
                          label="Premium users"
                          setTotalRecord={setTotalRecord}
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="v1/provider/adminlist?deleted=false&planType=premium&"
                        />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providers;
