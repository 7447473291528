import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../util/axiosInst";
import ReactPaginate from "react-paginate";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import LoadingScreen from "../Components/custom/LoadingScreen";

export default function SymptomCheckerResults() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [results_data, setresults_data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, SetTotalPage] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const getResults_data = () => {
    setLoading(true);
    axios
      .get(
        `v1/evaluation/history?patientId=${params.id}&limit=10&page=${pageNo}&sortBy=createdAt`
      )
      .then(({ data }) => {
        setresults_data(data?.results);
        SetTotalPage(data?.totalPages);
        setLoading(false);
      });
  };
  useEffect(() => {
    getResults_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <TopNavigation title="Symptom Checker Results" />
        <div className="wrapper mb-4 flex-fill">
          <section className="h-100">
            <div className="container">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="d-md-flex justify-content-between">
                    <h4
                      className="text-decoration-underline cursor-pointer"
                      onClick={() => navigate(location.state)}
                    >
                      <i className="fa fa-arrow-left" />
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="bg-white rounded p-3">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>Evaluation Muscle Group</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Conditions & Confidence</th>
                          <th>Evaluation Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {results_data && results_data.length ? (
                          results_data?.map((data, index) => (
                            <tr id={"data-" + index}>
                              <td className="text-break">
                                {data?.result?.label}
                              </td>
                              <td>{data?.request?.responses?.age}</td>
                              <td>{data?.request?.responses?.gender}</td>
                              <td>
                                {data?.result?.conditions?.map((i) => (
                                  <>
                                    {i.name} - {i.confidence}%<br />
                                  </>
                                ))}
                              </td>
                              <td>{moment(data.createdAt).format("lll")}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              {loading ? <LoadingScreen /> : "No Results"}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={(event) => setPageNo(event.selected + 1)}
                      pageRangeDisplayed={5}
                      pageCount={totalPage}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
