import Swal from "sweetalert2";

export const formatPhoneNumber = (phoneNumber) => {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

export const SwalMessage = (icon, title, text) => {
  Swal.fire({
    icon: icon,
    title: title,
    // text: text,
  });
};
