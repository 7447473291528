import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import TextInput from "../../common/TextInput";
import MediaPreview from "../../custom/MediaPreview";
import Time from "../Time";
import SideNavigation from "../../layout/SideNavigation";
import TopNavigation from "../../layout/TopNavigation";
import axios from "../../../util/axiosInst";
import _ from "lodash";
import WebLinksComponent from "../../custom/WebLinksComponent";
import { timeArray, unknownErrorMessage } from "../../../util/constants";
import { ProviderSchema } from "../../../util/schema";
import SocialMedia from "../../custom/SocialMedia";
import MaleDrImage from "../../../images/Male-Dr-Avatar.jpg";
import ProviderImg from "../../../images/dummyuserProfilemop.png";
import FemaleDrImage from "../../../images/Female-Dr-Avatar.jpg";
import {
  BusinessHoursInitValue,
  ClinicInitValue,
  certificationIntitialValue,
  educationInitValue,
  hospitalAffiliatedInitialValue,
  trainingInitValue,
} from "./init";
import Button from "../../common/Button";
import { SwalMessage } from "../../../util/utils";

const ProviderProfile = () => {
  const [videoUrl, setVideoUrl] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [hospitalAffiliationsRows, setHospitalAffiliationsRows] = useState([
    hospitalAffiliatedInitialValue,
  ]);
  const [educationRows, setEducationRows] = useState([educationInitValue]);
  const [trainingRows, setTrainingRows] = useState([trainingInitValue]);
  const [clinicRows, setClinicRows] = useState([ClinicInitValue]);
  const [select, setSelect] = useState("No");
  const [dataFromApi, setDataFromApi] = useState({});
  const [webLinks, setWebLinks] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [certificationRows, setCertificationRows] = useState([
    certificationIntitialValue,
  ]);
  const [certification, setCertification] = useState();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ProviderSchema),
  });
  const location = useLocation();

  const [city, setcity] = useState([]);
  const [selectedcity, setselectedcity] = useState([]);

  const selectcity_handler = (i, ind) => {
    setValue(`clinic[${ind}].addressLine`, city[ind][i].description);
    let selectedcity_temp = selectedcity;
    selectedcity_temp[ind] = city[i];
    setselectedcity(selectedcity_temp);
    setcity([]);
  };

  const onchangehandler_city = (e, ind) => {
    setcity([]);
    setValue(`clinic[${ind}].addressLine`, e.target.value);
    if (e.target.value.length > 3) {
      axios
        .get(`/v1/public/search/address?place=${e.target.value} `)
        .then(({ data }) => {
          let city_temp = city;
          city_temp[ind] = data.predictions;
          setcity(city_temp);
        });
    }
  };

  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (params.id) {
      getProvider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [provider, setProvider] = useState(null);

  const getProvider = async () => {
    const { data: providerData } = await axios.get(`/v1/provider/${params.id}`);

    setProvider(providerData);
    setDataFromApi(providerData);
    setValue("firstName", providerData.firstName);
    setValue("lastName", providerData.lastName);
    setValue("email", providerData.email);
    setValue("prefix", providerData.prefix);
    setValue(
      "userType",
      providerData.userType === "neuro_surgeon"
        ? providerData.userType.replace("_", "")
        : providerData.userType.replace("_", " ")
    );
    setValue("suffix", providerData.suffix);
    setValue("gender", providerData.gender);
    setValue("phoneNumber", providerData?.phoneNumber);
    setValue("website", providerData.website);
    setValue("videoUrl", providerData?.videoUrl ? providerData.videoUrl : "");
    setValue("biography", providerData.biography);
    setValue("googlePlaceId", providerData?.googlePlaceId || "");
    setValue("googleProfile", providerData?.googleProfile);
    setValue("askAnExpert", providerData.askAnExpert);
    setValue("active", providerData?.active);
    setValue("years_of_experience", providerData.years_of_experience);
    setValue("mopRecommended", providerData?.mopRecommended);
    setValue("facebook", providerData.facebook);
    setValue("twitter", providerData?.twitter);
    setValue("instagram", providerData?.instagram);
    setValue("youtube", providerData?.youtube);
    setValue("linkedin", providerData?.linkedin);
    setValue("npiId", providerData?.npiId);
    if (providerData.askAnExpert === true) {
      setSelect("Yes");
    } else {
      setSelect("No");
    }
    if (providerData?.webLinks && providerData?.webLinks?.length) {
      const temp = providerData?.webLinks.map((map) => {
        map.label = map?.name;
        map.value = map?.link;
        delete map.name;
        delete map.link;
        return map;
      });
      setValue("webLinks", temp);
      setWebLinks(temp);
    }
    if (providerData?.certifications && providerData?.certifications?.length) {
      setValue("certifications", providerData?.certifications);
      setCertificationRows(providerData?.certifications);
    }

    if (providerData?.photo) {
      setValue("photo", providerData?.photo);
    } else {
      setValue(
        "photo",
        providerData?.gender?.toUpperCase() === "MALE"
          ? MaleDrImage
          : providerData?.gender?.toUpperCase() === "FEMALE"
          ? FemaleDrImage
          : ProviderImg
      );
    }

    if (providerData?.banner) {
      setValue("banner", providerData?.banner);
    }

    if (providerData?.video) {
      setValue("video", providerData?.video);
    }

    if (providerData?.emailIds?.length > 0) {
      setValue("emailIds", providerData.emailIds);
    }

    if (providerData?.services?.length > 0) {
      setValue("services", providerData.services.join(", "));
    }

    if (providerData?.specilaizations?.length > 0) {
      setValue("specilaizations", providerData.specilaizations.join(", "));
    }

    if (providerData?.education?.length > 0) {
      setEducationRows(providerData.education);
      setValue("education", providerData.education);
    }
    if (providerData?.training?.length > 0) {
      setTrainingRows(providerData.training);
      setValue("training", providerData.training);
    }
    if (providerData?.affiliations?.length > 0) {
      setHospitalAffiliationsRows(providerData.affiliations);
      setValue("affiliations", providerData.affiliations);
    }
    if (providerData?.clinic?.length > 0) {
      providerData?.clinic?.forEach((cli, ind) => {
        if (!cli.businessHours || !cli.businessHours.length) {
          cli.businessHours = [
            {
              startDay: "",
              endDay: "",
              startTime: "",
              endTime: "",
            },
          ];
        }
      });
      setClinicRows(providerData.clinic);
      setValue("clinic", providerData.clinic);
    }
    if (providerData.videoUrl) {
      setVideoUrl(true);
    }
  };

  const getCertification = async () => {
    const { data } = await axios.get("v1/certification?limit=1000");
    setCertification(data?.results);
  };

  useEffect(() => {
    getCertification();
  }, []);

  useEffect(() => {
    setValue("userType", location?.state?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const watchStartTime = useWatch({
    control,
    name: "clinic",
  });

  const getEndTime = (ind, index) => {
    let tempArray = [...timeArray];
    if (watchStartTime) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTime[ind]?.businessHours[index]?.startTime
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (
        itemToBeRemoved.includes(
          watchStartTime[ind]?.businessHours[index]?.endTime
        )
      ) {
        setValue(`clinic[${ind}].businessHours[${index}].endTime`, "");
      }
      return [...itemToBeRemoved];
    }
    return null;
  };

  const FileUploadComponent = ({ fileName, accept }) => {
    const [upload, setUpload] = useState(0);

    const file = useWatch({
      control,
      name: fileName,
    });

    return (
      <div className="image-wraper mb-3 h-100">
        <div className="media-container">
          <MediaPreview
            fileName={file || watch(fileName)}
            type={accept === "image/*" ? "image" : "video"}
            gender={watch("gender")}
          />
        </div>
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={fileName}
            className="d-none"
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onProgress={(e) => {
              setUpload(e);
            }}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/contents/${file.name}`,
                },
              });
              setValue(fileName, data.url);
            }}
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
            autoUpload={true}
          />
          <label htmlFor={fileName}>
            {!watch(fileName)?.length ||
            getValues(fileName)?.includes("ale-Dr-Avatar") ||
            getValues(fileName)?.includes("dummyuserProfilemop") ? (
              <span className="border btn btn-primary" role="button">
                {fileName === "photo" && "Upload Photo"}
                {fileName === "video" &&
                  `${upload > 0 ? `${upload}% Uploading` : "Upload Video"}`}
                {fileName === "banner" && "Upload banner"}
              </span>
            ) : (
              <></>
            )}
            {getValues(fileName) &&
              (fileName === "photo" ||
                fileName === "video" ||
                fileName === "banner") &&
              !(
                getValues(fileName)?.includes("ale-Dr-Avatar") ||
                getValues(fileName)?.includes("dummyuserProfilemop")
              ) && (
                <span
                  className="border btn btn-danger"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (fileName === "photo")
                      setValue(
                        "photo",
                        provider?.gender?.toUpperCase() === "MALE"
                          ? MaleDrImage
                          : provider?.gender?.toUpperCase() === "FEMALE"
                          ? FemaleDrImage
                          : ProviderImg
                      );
                    else setValue(fileName, "");
                  }}
                >
                  Remove
                </span>
              )}
          </label>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title={location?.state?.label} />
          <div className="wrapper mt-4">
            <section className="pb-3">
              <form
                onSubmit={handleSubmit(async (formData) => {
                  if (select === "No") {
                    formData.askAnExpert = false;
                  }
                  if (!params.id) {
                    formData.isMOPAdminCreated = true;
                  }
                  if (select === "Yes") {
                    formData.askAnExpert = true;
                  }
                  if (formData.phoneNumber === null) {
                    delete formData.phoneNumber;
                  } else {
                    formData.phoneNumber = parseFloat(formData.phoneNumber);
                  }
                  if (
                    formData?.photo?.includes("Female-Dr-Avatar") ||
                    formData?.photo?.includes("Male-Dr-Avatar") ||
                    formData?.photo?.includes("dummyuserProfilemop")
                  ) {
                    formData.photo = "";
                  }
                  if (formData?.webLinks?.length) {
                    formData?.webLinks?.forEach((map) => {
                      map.link = map?.__isNew__ ? "" : map?.value;
                      map.name = map?.label;
                      delete map.value;
                      delete map.label;
                      delete map.__isNew__;
                    });
                  }

                  formData.services = formData.services
                    .split(",")
                    .map((item) => item.trim());
                  formData.specilaizations = formData.specilaizations
                    ?.split(",")
                    ?.map((item) => item.trim());
                  formData.education.forEach((map, ind) => {
                    if (
                      JSON.stringify(educationInitValue) === JSON.stringify(map)
                    ) {
                      formData.education.splice(ind, 1);
                    }
                  });
                  formData.training.forEach((map, ind) => {
                    if (
                      JSON.stringify(trainingInitValue) === JSON.stringify(map)
                    ) {
                      formData.training.splice(ind, 1);
                    }
                  });
                  formData.services.forEach((map, ind) => {
                    if (map === "") {
                      formData.services.splice(ind, 1);
                    }
                  });
                  formData.specilaizations?.forEach((map, ind) => {
                    if (map === "") {
                      formData.specilaizations.splice(ind, 1);
                    }
                  });
                  formData.affiliations.forEach((map, ind) => {
                    if (
                      JSON.stringify(hospitalAffiliatedInitialValue) ===
                      JSON.stringify(map)
                    ) {
                      formData.affiliations.splice(ind, 1);
                    }
                  });
                  if (formData?.email?.length <= 0) {
                    delete formData?.email;
                  }
                  formData.userType = location.state.value;
                  formData.admin = true;

                  if (!params.id) {
                    try {
                      await axios.post("/v1/provider/create/admin", {
                        ...formData,
                        profile_claimed_status: "created",
                      });
                      navigate(location.state.pathName);
                      SwalMessage(
                        "success",
                        `${location?.state?.label} created Successfully.`
                      );
                    } catch (error) {
                      toast.error(error.response.data.message);
                    }
                  } else {
                    if (formData.clinic) {
                      let clinicResult = _.isEqual(
                        formData.clinic,
                        dataFromApi.clinic
                      );
                      if (clinicResult) {
                        delete formData.clinic;
                      } else {
                        delete formData.googlePlaceId;
                      }
                    }
                    try {
                      const { data } = await axios.patch(
                        `/v1/provider/${params.id}`,
                        formData
                      );
                      if (data && data.id) {
                        navigate(`${location.state.pathName}`, {
                          state: provider.planType,
                        });
                        SwalMessage(
                          "success",
                          `${location?.state?.label} updated Successfully.`
                        );
                      }
                    } catch (e) {
                      if (e.response?.data?.message) {
                        toast.error(e.response?.data?.message);
                      } else {
                        toast.error(unknownErrorMessage);
                      }
                    }
                  }
                })}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="bg-white border rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Basic Information</h5>
                        </div>
                        <div className="p-4">
                          {/* <div className="row">
                            <div className="col-sm-10 align-self-center mt-3 mt-sm-0">
                              <div className="mb-3">
                                <FileUploadComponent
                                  fileName={"photo"}
                                  accept={"image/*"}
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="row">
                            <div className="col-md-6 align-self-center mt-3 mt-sm-0">
                              <FileUploadComponent
                                fileName={"photo"}
                                accept={"image/*"}
                              />
                            </div>
                            <div className="col-md-6 align-self-center mt-3 mt-sm-0">
                              <FileUploadComponent
                                fileName={"banner"}
                                accept={"image/*"}
                              />
                            </div>
                          </div>

                          <div className="row mt-3">
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="First Name"
                                mandatory={true}
                                name="firstName"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Last Name"
                                mandatory={true}
                                name="lastName"
                              />
                            </div>
                            {params.id ? (
                              <div className="col-md-4 mb-3">
                                <TextInput
                                  errors={errors}
                                  register={register}
                                  label="Email"
                                  name="email"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Prefix"
                                name="prefix"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                label="Suffix"
                                name="suffix"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Gender</label>
                              <select
                                className="form-control "
                                {...register("gender")}
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male - He, Him</option>
                                <option value="female">
                                  Female - She, Her
                                </option>
                              </select>
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                maxlength={10}
                                errors={errors}
                                register={register}
                                label="Phone Number"
                                name="phoneNumber"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="custom">Website</label>

                              <input
                                type="text"
                                className={clsx(
                                  "form-control",
                                  errors.website && "is-invalid"
                                )}
                                {...register("website")}
                              />

                              <p className="error">{errors.website?.message}</p>
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="User Type"
                                name="userType"
                                disabled
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <TextInput
                                errors={errors}
                                register={register}
                                className="text-capitalize"
                                label="NPI #"
                                name="npiId"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border mt-3 rounded border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">About</h5>
                        </div>
                        <div className="row container-fluid p-4">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <label for="" className="form-label fw-bold">
                              About
                            </label>
                            <textarea
                              name=""
                              id=""
                              cols="3"
                              rows="3"
                              className="form-control flex-fill"
                              {...register("biography")}
                            ></textarea>
                          </div>
                          {videoUrl === false &&
                            (getValues("videoUrl") === undefined ||
                              getValues("videoUrl")?.length === 0) && (
                              <div className="col-md-6 mb-3">
                                <label className="form-label fw-bold">
                                  Short video about me
                                </label>
                                <div className=" p-4 text-center text-light rounded">
                                  <FileUploadComponent
                                    fileName={"video"}
                                    accept={"video/*"}
                                  />
                                </div>
                              </div>
                            )}

                          <div className="col-sm-6 mb-3">
                            <label for="" className="form-label fw-bold">
                              VideoURL
                            </label>
                            <TextInput
                              errors={errors}
                              register={register}
                              mandatory={false}
                              name="videoUrl"
                              setVideoUrl={setVideoUrl}
                              setValue={setValue}
                              disabled={getValues("video")}
                            />
                          </div>
                        </div>
                      </div>
                      <WebLinksComponent
                        setWebLinks={setWebLinks}
                        setValue={setValue}
                        webLinks={webLinks}
                        getValues={getValues}
                        providers={provider}
                      />
                      <div className="bg-white border rounded mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">
                            Top Procedures and Conditions
                          </h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <label for="" className="form-label">
                                Add Top Procedures and Conditions
                                <span data-tip data-for="service">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="service"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    To add mulitple Top procedures and
                                    Conditions, add each Procedures and
                                    Conditions seperated by Comma.
                                  </span>
                                </ReactTooltip>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register("services")}
                              />
                            </div>
                            {(provider?.userType === "rehabilitation_center" ||
                              provider?.userType === "personal_trainer" ||
                              provider?.userType === "physical_therapist" ||
                              provider?.userType === "wellness_provider" ||
                              provider?.userType === "athletic_trainers") && (
                              <div className="col-sm-6 mb-3">
                                <label for="" className="form-label">
                                  Specilaizations
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  {...register("specilaizations")}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <SocialMedia.Certifications
                        certificationIntitialValue={certificationIntitialValue}
                        setValue={setValue}
                        setCertificationRows={setCertificationRows}
                        getValues={getValues}
                        certificationRows={certificationRows}
                        register={register}
                        watch={watch}
                        certification={certification}
                        errors={errors}
                      />
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Education</h5>
                        </div>
                        {educationRows.map((educationRow, ind) => {
                          return (
                            <div className="p-4">
                              <div className="row container-fluid">
                                <div className="col-md-4">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Degree"
                                    name={`education[${ind}].degree`}
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="College Institute"
                                    name={`education[${ind}].college`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Year of Completion"
                                    name={`education[${ind}].completionyear`}
                                  />
                                </div>
                                <div className="row">
                                  {educationRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("education"),
                                          educationInitValue,
                                        ];
                                        setEducationRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...educationRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setEducationRows(rows);
                                        setValue("education", rows);
                                      } else {
                                        setEducationRows([educationInitValue]);
                                        setValue("education", [
                                          educationInitValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Training</h5>
                        </div>
                        {trainingRows.map((trainingRow, ind) => {
                          return (
                            <div className="p-4">
                              <div className="row container-fluid">
                                <div className="col-md-4">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Training type"
                                    name={`training[${ind}].trainingType`}
                                  />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Location"
                                    name={`training[${ind}].location`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Year of Completion"
                                    name={`training[${ind}].completionyear`}
                                  />
                                </div>
                                <div className="row">
                                  {trainingRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("training"),
                                          trainingInitValue,
                                        ];
                                        setTrainingRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...trainingRows];
                                      rows.splice(ind, 1);
                                      setTrainingRows(rows);
                                      setValue("training", rows);
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setTrainingRows(rows);
                                        setValue("training", rows);
                                      } else {
                                        setTrainingRows([trainingInitValue]);
                                        setValue("training", [
                                          trainingInitValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="bg-white border rounded mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Clinic Info</h5>
                        </div>
                        <div className="p-4">
                          {clinicRows.map((clinicRow, ind) => {
                            return (
                              <div className="row mb-3">
                                <div className="col-md-4 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Clinic Name"
                                    name={`clinic[${ind}].name`}
                                  />
                                </div>
                                <div className="col-md-4 mb-3 position-relative">
                                  <label className="form-label">
                                    Clinic Address Line
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control text-black`}
                                    value={selectedcity[ind]?.description}
                                    name={selectedcity[ind]?.description}
                                    {...register(`clinic[${ind}].addressLine`)}
                                    onChange={(e) => onchangehandler_city(e, ind)}
                                  />
                                  {city[ind]?.length > 0 && (
                                    <div className="dropdown-menu d-block provider-select-div w-100 ">
                                      <ul className="provider-select-ul">
                                        <ReactTooltip
                                          id="service"
                                          place="top"
                                          effect="solid"
                                        >
                                          <span>Close the Suggestion</span>
                                        </ReactTooltip>
                                        <p
                                          className="float-right cursor-pointer p-0 fw-bold"
                                          onClick={() => setcity([])}
                                        >
                                          <span data-tip data-for="service">
                                            X
                                          </span>
                                        </p>
                                        {city[ind].map((data, i) => {
                                          return (
                                            <li
                                              key={i}
                                              onClick={() => selectcity_handler(i, ind)}
                                              className="provider-select-li"
                                            >
                                              {" "}
                                              {data.description}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Clinic City"
                                    name={`clinic[${ind}].city`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Clinic State"
                                    name={`clinic[${ind}].state`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Clinic Country"
                                    name={`clinic[${ind}].country`}
                                  />
                                </div>
                                <div className="col-md-4 mb-1">
                                  <TextInput
                                    errors={errors}
                                    maxlength={5}
                                    register={register}
                                    label="Clinic Zipcode"
                                    name={`clinic[${ind}].zipcode`}
                                  />
                                </div>
                                <div className="py-3">
                                  <h5 className="mb-0">Business Hours</h5>
                                </div>
                                {clinicRow.businessHours.map(
                                  (businessHour, index) => {
                                    return (
                                      <>
                                        <div className="col-md-3 mb-1">
                                          <label>Start Day</label>
                                          <select
                                            className="form-select"
                                            {...register(
                                              `clinic[${ind}].businessHours[${index}].startDay`
                                            )}
                                          >
                                            <option value="">
                                              Select Start Day
                                            </option>
                                            <option value="Sunday">
                                              Sunday
                                            </option>
                                            <option value="Monday">
                                              Monday
                                            </option>
                                            <option value="Tuesday">
                                              Tuesday
                                            </option>
                                            <option value="Wednesday">
                                              Wednesday
                                            </option>
                                            <option value="Thursday">
                                              Thursday
                                            </option>
                                            <option value="Friday">
                                              Friday
                                            </option>
                                            <option value="Saturday">
                                              Saturday
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-md-3 mb-1">
                                          <label>End Day</label>
                                          <select
                                            className="form-select"
                                            {...register(
                                              `clinic[${ind}].businessHours[${index}].endDay`
                                            )}
                                          >
                                            <option value="">
                                              Select End Day
                                            </option>
                                            <option value="Sunday">
                                              Sunday
                                            </option>
                                            <option value="Monday">
                                              Monday
                                            </option>
                                            <option value="Tuesday">
                                              Tuesday
                                            </option>
                                            <option value="Wednesday">
                                              Wednesday
                                            </option>
                                            <option value="Thursday">
                                              Thursday
                                            </option>
                                            <option value="Friday">
                                              Friday
                                            </option>
                                            <option value="Saturday">
                                              Saturday
                                            </option>
                                          </select>
                                        </div>
                                        <div className="col-md-3 mb-1">
                                          <label>Start Time</label>
                                          <Time
                                            register={register}
                                            options={[...timeArray]}
                                            name={`clinic[${ind}].businessHours[${index}].startTime`}
                                          />
                                        </div>
                                        <div className="col-md-3 mb-1">
                                          <label>End Time</label>
                                          <Time
                                            register={register}
                                            disabledEndTimes={getEndTime(
                                              ind,
                                              index
                                            )}
                                            options={[...timeArray]}
                                            name={`clinic[${ind}].businessHours[${index}].endTime`}
                                          />
                                        </div>
                                        <div className="row">
                                          {clinicRows.length - 1 === ind && (
                                            <div
                                              className="col-md-3 text-primary cursor-pointer"
                                              onClick={() => {
                                                const rows = {
                                                  ...clinicRow,
                                                  businessHours: [
                                                    ...getValues("clinic")[ind]
                                                      .businessHours,
                                                    BusinessHoursInitValue,
                                                  ],
                                                };
                                                let businessHoursRow =
                                                  clinicRows;
                                                businessHoursRow[ind] = rows;
                                                setClinicRows([
                                                  ...businessHoursRow,
                                                ]);
                                              }}
                                            >
                                              Add Business Hours
                                            </div>
                                          )}
                                          <div
                                            className="col-md-3 text-danger cursor-pointer"
                                            onClick={() => {
                                              let rows = [
                                                ...clinicRows[ind]
                                                  .businessHours,
                                              ];
                                              if (rows.length > 1) {
                                                rows.splice(index, 1);
                                                let businessHoursRow =
                                                  clinicRows;
                                                businessHoursRow[
                                                  ind
                                                ].businessHours = rows;
                                                setClinicRows([
                                                  ...businessHoursRow,
                                                ]);
                                                setValue(
                                                  `clinic`,
                                                  businessHoursRow
                                                );
                                              } else {
                                                let businessHoursRow =
                                                  clinicRows;
                                                businessHoursRow[
                                                  ind
                                                ].businessHours = [
                                                  BusinessHoursInitValue,
                                                ];
                                                setClinicRows([
                                                  ...businessHoursRow,
                                                ]);
                                                setValue(
                                                  `clinic[${ind}].businessHours`,
                                                  [BusinessHoursInitValue]
                                                );
                                              }
                                            }}
                                          >
                                            Remove Business Hours
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                                <div className="row">
                                  {clinicRows.length - 1 === ind && (
                                    <div
                                      className="col-md-2 p-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("clinic"),
                                          ClinicInitValue,
                                        ];
                                        setClinicRows(rows);
                                      }}
                                    >
                                      Add Clinic Info
                                    </div>
                                  )}
                                  <div
                                    className="col-md-3 p-2 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...clinicRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setClinicRows(rows);
                                        setValue("clinic", rows);
                                      } else {
                                        setClinicRows([ClinicInitValue]);
                                        setValue("clinic", [ClinicInitValue]);
                                      }
                                    }}
                                  >
                                    Remove Clinic Info
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-3">Hospitals Affiliated</h5>
                          {hospitalAffiliationsRows.map((trainingRow, ind) => {
                            return (
                              <div className="row container-fluid">
                                <div className="col-md-6">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Hospital Name"
                                    name={`affiliations[${ind}].hospital_name`}
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <TextInput
                                    errors={errors}
                                    register={register}
                                    label="Hospital City"
                                    name={`affiliations[${ind}].hospital_city`}
                                  />
                                </div>
                                <div className="row">
                                  {hospitalAffiliationsRows.length - 1 ===
                                    ind && (
                                    <div
                                      className="col-md-2 text-primary cursor-pointer"
                                      onClick={() => {
                                        const rows = [
                                          ...getValues("affiliations"),
                                          hospitalAffiliatedInitialValue,
                                        ];
                                        setHospitalAffiliationsRows(rows);
                                      }}
                                    >
                                      Add new
                                    </div>
                                  )}
                                  <div
                                    className="col-md-1 text-danger cursor-pointer"
                                    onClick={() => {
                                      let rows = [...hospitalAffiliationsRows];
                                      if (rows.length > 1) {
                                        rows.splice(ind, 1);
                                        setHospitalAffiliationsRows(rows);
                                        setValue("affiliations", rows);
                                      } else {
                                        setHospitalAffiliationsRows([
                                          hospitalAffiliatedInitialValue,
                                        ]);
                                        setValue("affiliations", [
                                          hospitalAffiliatedInitialValue,
                                        ]);
                                      }
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Email ID</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Email ID 1
                                <span data-tip data-for="infoEmail">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="infoEmail"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    Email addresses below are for receiving
                                    correspondence from MyOrthopedicProblem.com.
                                    Please add team member’s email addresses who
                                    are responsible for fielding these request.
                                  </span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="emailIds[0]"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Email ID 2
                                <span data-tip data-for="infoEmail">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="infoEmail"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    Email addresses below are for receiving
                                    correspondence from MyOrthopedicProblem.com.
                                    Please add team member’s email addresses who
                                    are responsible for fielding these request.
                                  </span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="emailIds[1]"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Email ID 3
                                <span data-tip data-for="infoEmail">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="infoEmail"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    Email addresses below are for receiving
                                    correspondence from MyOrthopedicProblem.com.
                                    Please add team member’s email addresses who
                                    are responsible for fielding these request.
                                  </span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="emailIds[2]"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Email ID 4
                                <span data-tip data-for="infoEmail">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="infoEmail"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    Email addresses below are for receiving
                                    correspondence from MyOrthopedicProblem.com.
                                    Please add team member’s email addresses who
                                    are responsible for fielding these request.
                                  </span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="emailIds[3]"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded container-fluid py-3 mt-3 border-primary">
                        <div className="row">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <TextInput
                              errors={errors}
                              register={register}
                              label="Location URL"
                              name="googleProfile"
                            />
                          </div>
                          {/* <div className="col-md-6 mb-3">
                            <div className="check-box">
                              <label
                                className="form-radio-label"
                                for="inlineRadio1"
                              >
                                Ask an Expert
                                <span data-tip data-for="info">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="info"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>
                                    Ask an expert is a unique feature for
                                    providers to receive questions and
                                    communicate directly with patients regarding
                                    their conditions. Please select “No” if you
                                    do not wish to communicate with patients in
                                    this way
                                  </span>
                                </ReactTooltip>
                              </label>
                              <div className="radio-buttons">
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    name="askAnExpert"
                                    className="align-middle me-1"
                                    value="Yes"
                                    checked={select === "Yes"}
                                    onChange={(e) => setSelect("Yes")}
                                  />
                                  <label
                                    className="form-check-label me-4"
                                    for="inlineRadio1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    type="radio"
                                    name="askAnExpert"
                                    className="align-middle me-1"
                                    value="No"
                                    checked={select === "No"}
                                    onChange={(e) => setSelect("No")}
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineRadio2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="bg-white border rounded mt-3 border-primary">
                        <div className="row container-fluid py-4 px-4">
                          <div className="col-md-6 mb-3 d-flex flex-column">
                            <TextInput
                              errors={errors}
                              register={register}
                              label="Years of Experience"
                              name="years_of_experience"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bg-white border rounded  mt-4 border-primary">
                        <div className="bg-teal py-3 px-4">
                          <h5 className="mb-0">Social Media</h5>
                        </div>
                        <div className="p-4">
                          <div className="row">
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Facebook
                                <span data-tip data-for="facebook">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="facebook"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Facebook URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="facebook"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Twitter
                                <span data-tip data-for="twitter">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="twitter"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Twitter URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="twitter"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Instagram
                                <span data-tip data-for="instagram">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="instagram"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Instagram URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="instagram"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                Youtube
                                <span data-tip data-for="youtube">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="youtube"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Youtube URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="youtube"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <label for="" className="form-label">
                                LinkedIn
                                <span data-tip data-for="linkedin">
                                  <i
                                    className="fa fa-info-circle me-2 text-primary"
                                    style={{ paddingLeft: "5px" }}
                                    aria-hidden="true"
                                  />
                                </span>
                                <ReactTooltip
                                  id="linkedin"
                                  place="right"
                                  effect="solid"
                                >
                                  <span>Linkedin URL</span>
                                </ReactTooltip>
                              </label>
                              <TextInput
                                errors={errors}
                                register={register}
                                name="linkedin"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      {params.id && (
                        <div className="row">
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("active")}
                                type="checkbox"
                                id="active"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                {...register("mopRecommended")}
                                type="checkbox"
                                id="mopRecommended"
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="mopRecommended"
                              >
                                MOP Recommended
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <Button
                        className="btn btn-primary mt-4"
                        value={!params.id ? "Submit" : "Update"}
                        type="submit"
                      />
                      <Button
                        className="btn btn-secondary mt-4 ms-2"
                        onClick={() => {
                          navigate(`${location.state.pathName}`, {
                            state: provider?.planType,
                          });
                        }}
                        value="Cancel"
                        type="button"
                      />
                      <div className="row mt-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderProfile;
