import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "../util/axiosInst";
import { toast } from "react-toastify";
import { loginSuccessMessage, unknownErrorMessage } from "../util/constants";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required().min(8),
  })
  .required();

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    formData.role = "admin";
    try {
      const { data } = await axios.post("/v1/auth/login", formData);
      if (data.user.role === "admin") {
        localStorage.setItem("mop-admin-token", JSON.stringify(data.tokens));
        localStorage.setItem("mop-admin-user", JSON.stringify(data.user));
        toast.success(loginSuccessMessage);
        window.location.href = "/adminstaff";
      } else {
        toast.error("Only admin users are allowed", {
          position: "top-right",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };

  return (
    <div className="login-table">
      <div className="login-cell">
        <div className="login-box">
          <div
            className="card"
            style={{
              width: "18rem",
            }}
          >
            <div className="card-body">
              <h5 className="card-title text-center">Login</h5>
              <form className="mt-4 mb-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group-swing ">
                  <input
                    type="text"
                    placeholder="Email"
                    {...register("email")}
                  />
                  <label>Email</label>
                  <p className="error">{errors.email?.message}</p>
                </div>
                <div className="input-group-swing ">
                  <input
                    type="password"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <label>Password</label>
                  <p className="error">{errors.password?.message}</p>
                </div>
                <div className="text-center">
                  <button
                    href="#"
                    className="card-link w-100 btn btn-primary"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
