import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import { map } from "lodash";
import * as XLSX from "xlsx";
import axios from "../util/axiosInst";
import { useState } from "react";

export default function EducationContents() {
  const navigate = useNavigate();
  const [key, setKey] = useState("education_contents");

  const downloadExcel = async () => {
    let response;
    response = await axios.get(
      `/v1/content?&limit=100000`
    );
    const worksheet = XLSX.utils.json_to_sheet(
      map(
        response?.data?.results,
        ({ title, keywords, active, createdAt }) => ({
          Title: title,
          Keywords: keywords.join(" "),
          "Active": active === true ? "YES" : "NO",
          "Created Date": moment(createdAt).format("ll")
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${key}_${moment().format("YYYY_MM_DD")}.xlsx`);
  };

  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    { dataField: "title", text: "Title", sort: true },
    { dataField: "keywords", text: "Keywords", sort: true },
    {
      dataField: "active",
      text: "Active",
      headerStyle: { width: 100 },
      sort: true,
      formatter: (cell) => {
        return (
          <>
            {cell ? (
              <span className="badge badge-success">YES</span>
            ) : (
              <span className="badge badge-danger">NO</span>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Educational Contents" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="d-flex justify-content-end mb-3">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      navigate("/createcontent");
                    }}
                  >
                    Add Content
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ms-3 rounded"
                    onClick={() => downloadExcel()}
                    style={{ borderRadius: 0 }}
                  >
                    Download as Excel
                  </button>
                </div>
                <div></div>
                <div className="box-content">
                  <CustomDataTable
                    columns={columns}
                    role="admin"
                    editUrl="/editcontent"
                    deleteUrl="/v1/content/admin"
                    fetchContentsUrl="/v1/content?"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
