import React, { useState, useEffect } from "react";
import Creatable from "react-select/creatable";
import axios from "../../util/axiosInst";
import Remove from "../common/Remove";
import AddNew from "../common/Addnew";
import { xorWith, isEqual } from "lodash";
import LabelWithTooltip from "../common/LabelWithTooltip";

const WebLinksComponent = ({
  setWebLinks,
  setValue,
  webLinks,
  getValues,
  providers,
}) => {
  const [providerList, setProviderList] = useState(webLinks);
  const getAllProviders = (e) => {
    try {
      if (e?.length >= 3) {
        axios.get(`/v1/provider/all?active=1&name=/^${e}/`).then((response) => {
          if (response.status === 200) {
            let providerOptions = [];
            response.data.results &&
              response.data.results?.forEach((item) => {
                if (item.userId !== providers.userId) {
                  providerOptions.push({
                    value: item.slug,
                    label: item.name,
                    userType: item.userType,
                  });
                }
              });
            setProviderList(xorWith(providerOptions, webLinks, isEqual));
          }
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    setProviderList([]);
  }, [webLinks]);

  return (
    <div className="bg-white border rounded mt-3 border-primary">
      <div className="bg-teal py-3 px-4">
        <LabelWithTooltip
          label="Recommendations"
          TooltipText="Link your recommended Providers"
          id="connection"
          labelClassName="h5"
        />
      </div>
      <div className="py-4">
        <div className="row container-fluid">
          {webLinks?.map((webLink, ind) => (
            <div className="col-md-6 mb-3">
              <Creatable
                options={providerList}
                placeholder={
                  <div>
                    <i className="fa fa-solid fa-magnifying-glass pe-2" />
                    Search a provider
                  </div>
                }
                isMulti={false}
                onFocus={() => {
                  webLink.label = "";
                  webLink.value = "";
                  setProviderList([]);
                }}
                onInputChange={(e) => getAllProviders(e)}
                onChange={(e) => {
                  webLink.label = e.label;
                  webLink.value = e.__isNew__ ? "" : e.value;
                  webLink.userType = e.userType;
                  setValue("webLinks", webLinks);
                }}
                value={webLink}
              />
              <div className="row mt-4">
                {webLinks?.length - 1 === ind && (
                  <AddNew
                    value={webLinks}
                    setValue={setWebLinks}
                    connections={true}
                    initValue={{
                      label: "",
                      value: "",
                    }}
                    setHook={setValue}
                  />
                )}
                <Remove
                  setRows={setWebLinks}
                  setValue={setValue}
                  ind={ind}
                  getValues={getValues}
                  name="webLinks"
                  initValue={{
                    label: "",
                    value: "",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WebLinksComponent;
