import React, { useState } from "react";
import axios from "../../util/axiosInst";
import Select from "react-select";
import { differenceWith } from "lodash";

const CertificationSelect = ({
  setValue,
  name,
  certificationRows,
  index,
  setCertificationRows,
  watch,
  certifications,
}) => {
  const [providerList, setProviderList] = useState([]);
  const getAllProviders = (e) => {
    try {
      if (e?.length >= 3) {
        axios.get(`v1/certification?certification=${e}`).then((response) => {
          if (response.status === 200) {
            const result = differenceWith(
              response.data.results,
              certificationRows,
              (obj1, obj2) =>
                `${obj1?.governingBody}-${obj1?.certification}` === obj2.label
            );
            const providerOptions =
              result &&
              result?.map((item) => {
                return {
                  value: item.id,
                  label: `${item?.governingBody}-${item?.certification}`,
                };
              });
            setProviderList(providerOptions);
          }
        });
      }
    } catch (e) {}
  };

  const onChange = (e) => {
    setValue(name, { ...watch(name), label: e.label });
    const array = [...certificationRows];
    array[index] = e;
    setCertificationRows(array);
  };

  return (
    <>
      <label>Certification</label>
      <Select
        options={providerList}
        placeholder={
          <div>
            <i className="fa fa-solid fa-magnifying-glass pe-2" />
            Search a certification
          </div>
        }
        onInputChange={(e) => getAllProviders(e)}
        onChange={onChange}
        value={certifications}
      />
    </>
  );
};

export default CertificationSelect;
