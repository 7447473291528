import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../util/axiosInst";
import SideNavigation from "../layout/SideNavigation";
import TopNavigation from "../layout/TopNavigation";

const ListVideo = () => {
  const params = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const deleteBlog = async (id, event) => {
    event.preventDefault();
    await axios.delete(`/v1/exercise/${id}`);
    Swal.fire({
      icon: "warning",
      title: "Deleted successfully",
    });
    getBlogArticles();
  };

  const [blogArticles, setBlogArticles] = useState([]);
  const [totalPage, SetTotalPage] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const getBlogArticles = () => {
    axios
      .get(
        `/v1/exercise?limit=10&page=${pageNo}&createdBy=${params.id}&type=post`
      )
      .then(({ data }) => {
        setBlogArticles(data?.results);
        SetTotalPage(data?.totalPages);
      });
  };
  useEffect(() => {
    getBlogArticles();
  }, [pageNo]);

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <TopNavigation title="Videos" />
        <div className="wrapper mb-4 flex-fill">
          <section className="h-100">
            <div className="container">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="d-md-flex justify-content-between">
                    <h4
                      className="text-decoration-underline"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(location.state);
                      }}
                    >
                      <i className="fa fa-arrow-left" />
                    </h4>
                    <div className="d-flex">
                      <Link
                        className="ms-3 text-nowrap btn btn-primary"
                        to="/add/video"
                        style={{ textDecoration: "none" }}
                        state={{ id: params.id, pathName: location.state }}
                      >
                        Create Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="bg-white rounded p-3">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Category</th>
                          <th>Created Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blogArticles && blogArticles.length ? (
                          blogArticles?.map((blog, index) => {
                            return (
                              <tr id={"video-" + index}>
                                <td className="text-break">{blog.title}</td>
                                <td>{blog.categories[0].name}</td>
                                <td className="text-nowrap">
                                  {moment(blog.createdAt).format("lll")}
                                </td>
                                <td className="d-flex">
                                  <Link
                                    className="align-self-center"
                                    to={"/edit/video/" + blog._id}
                                    state={{
                                      id: params.id,
                                      pathName: location.state,
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Edit
                                  </Link>
                                  <span className="mx-3 align-self-center">
                                    |
                                  </span>
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                    className="align-self-center"
                                    onClick={(event) =>
                                      deleteBlog(blog._id, event)
                                    }
                                  >
                                    Delete
                                  </a>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No Videos
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={(event) => {
                        setPageNo(event.selected + 1);
                      }}
                      pageRangeDisplayed={5}
                      pageCount={totalPage}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ListVideo;
