export const classesInitValue = {
  taught: "",
  location: "",
  timings: [{ days: [], start_time: "", end_time: "" }],
};
export const certificationInitValue = {
  label: "",
  value: "",
};
export const affiliatedGymsInitValue = {
  affiliatedGyms: "",
};
export const educationInitValue = {
  degree: "",
  college: "",
  completionyear: "",
};
