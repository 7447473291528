import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../util/axiosInst";
import SideNavigation from "../layout/SideNavigation";
import TopNavigation from "../layout/TopNavigation";
import { toast } from "react-toastify";
import {
  createSuccessMessage,
  unknownErrorMessage,
  updateSuccessMessage,
} from "../../util/constants";
export default function UserForm() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: params?.id ? yup.string() : yup.string().required().min(8),
      firstName: yup.string().required().min(1),
      lastName: yup.string().required().min(1),
      isEmailVerified: yup.boolean(),
      active: yup.boolean(),
    })
    .required();
  const [editId, setEditId] = useState(null);
  const [active, setactive] = useState(false);
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { isValid } = useFormState({
    control,
  });
  const getUserDetails = async (userId) => {
    setEditId(userId);
    const { data } = await axios.get(`/v1/user/${userId}`);
    setactive(data.active);
    delete data.id;
    reset(data);
  };
  const onSubmit = async (formData) => {
    const dataToSend = {
      ...formData,
    };
    if (!dataToSend.password) {
      delete dataToSend.password;
    }
    try {
      if (editId) {
        const { data } = await axios.patch(`/v1/user/${editId}`, dataToSend);
        if (data && data.id) {
          toast.success(updateSuccessMessage);
          if (window.location.href.includes("editpatient")) {
            navigate("/users-information");
          } else {
            navigate("/adminstaff");
          }
        }
      } else {
        const { data } = await axios.post("/v1/user/admin", dataToSend);
        toast.success(createSuccessMessage);
        if (data && data.id) {
          navigate("/adminstaff");
        }
      }
    } catch (e) {
      if (e.response?.data?.message) {
        toast.error(e.response?.data?.message);
      } else {
        toast.error(unknownErrorMessage);
      }
    }
  };
  useEffect(() => {
    if (params?.id) {
      getUserDetails(params.id);
    } else {
      unregister("active");
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation
            title={
              editId
                ? location.pathname.includes("/editpatient")
                  ? "Edit Patient"
                  : "Edit Admin Staff"
                : "Create Admin Staff"
            }
          />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group-swing">
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        {...register("firstName")}
                      />
                      <label>First Name:</label>
                      <p className="error">{errors.name?.message}</p>
                    </div>
                    <div className="input-group-swing">
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        {...register("lastName")}
                      />
                      <label>Last Name:</label>
                      <p className="error">{errors.name?.message}</p>
                    </div>
                    <div className="input-group-swing">
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email")}
                      />
                      <label>Email:</label>
                      <p className="error">{errors.email?.message}</p>
                    </div>
                    <div className="input-group-swing">
                      <input
                        type="text"
                        name="password"
                        placeholder={
                          editId ? "Enter Password to update" : "Enter Password"
                        }
                        {...register("password")}
                      />
                      <label>Password:</label>
                      <p className="error">{errors.password?.message}</p>
                    </div>
                    {editId && (
                      <>
                        <label className="switch">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              setValue("active", e.target.checked);
                              setactive(e.target.checked);
                            }}
                            checked={active}
                          />
                          <span className="slider round" />
                        </label>
                        <span className="m-3">Active</span>
                      </>
                    )}
                    <div className="mt-3">
                      <button className="btn btn-primary" disabled={!isValid}>
                        {editId ? "Update" : "Create"}
                      </button>
                      <button
                        type="button"
                        className="btn ml-1 btn-secondary"
                        onClick={() => {
                          if (window.location.href.includes("editpatient")) {
                            navigate("/users-information");
                          } else {
                            navigate("/adminstaff");
                          }
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
