import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import { claimProfileColumns, columns } from "../util/constants";
const Massagetherapist = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [key, setKey] = useState(
    location?.state?.length ? location?.state : "mop_created"
  );
  const [contents, setContents] = useState([]);
  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="Massage Therapist" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <div className="d-flex justify-content-end">
                    <div
                      className="input-group rounded me-2"
                      style={{ width: "25%" }}
                    >
                      <input
                        type="text"
                        className={"form-control bg-transparent rounded p-2"}
                        placeholder="Search Provider... "
                        style={{
                          maxWidth: "100%",
                          borderTop: "1px solid #b5b5b5",
                          borderLeft: "1px solid #b5b5b5",
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            setContents(getValues("searchQuery"));
                          }
                        }}
                        {...register("searchQuery")}
                      />
                      <button
                        className="input-group-text text-white rounded btn btn-primary"
                        onClick={() => {
                          setContents(getValues("searchQuery"));
                        }}
                      >
                        <i className="fa fa-solid fa-search"></i>
                      </button>
                    </div>
                    {key === "mop_created" ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          navigate("/add-Massage-therapist", {
                            state: {
                              value: "massage_therapist",
                              label: "Massage Therapist",
                              pathName: window.location.pathname,
                            },
                          })
                        }
                      >
                        Add Massage Therapist
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="mop_created" title="MOP Created">
                      {key === "mop_created" && (
                        <CustomDataTable
                          columns={columns}
                          type="provider"
                          editUrl="/massage_therapist"
                          deleteUrl="v1/user"
                          value="massage_therapist"
                          label="Massage Therapist"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/provider/adminlist?userType=massage_therapist&publish=${
                            key === "request" ? false : true
                          }&isMOPAdminCreated=true&profile_claimed_status=created,rejected&deleted=${false}&`}
                        />
                      )}
                    </Tab>
                    <Tab
                      eventKey="claimed_profile"
                      title="Claimed Profile requests "
                    >
                      {key === "claimed_profile" && (
                        <CustomDataTable
                          columns={claimProfileColumns}
                          editUrl="/claimrequestprofile"
                          deleteUrl={null}
                          label="Claim Profile Request"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`v1/claimProfile?active=true&userType=massage_therapist&`}
                        />
                      )}
                    </Tab>
                    <Tab eventKey="pending" title="Signup Pending">
                      {key === "pending" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl="/massage_therapist"
                          deleteUrl="v1/user"
                          type="provider"
                          value="massage_therapist"
                          label="Massage Therapist"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl="/v1/provider/adminlist?userType=massage_therapist&deleted=false&pending_singup=true&profile_claimed_status=approved&sortingType=1&"
                        />
                      )}
                    </Tab>
                    <Tab eventKey="basic" title="Basic Users">
                      {key === "basic" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl="/massage_therapist"
                          deleteUrl="v1/user"
                          type="provider"
                          value="massage_therapist"
                          label="Massage Therapist"
                          searchQuery={contents}
                          pathName={window.location.pathname}
                          fetchContentsUrl={`/v1/provider/adminlist?userType=massage_therapist&deleted=false&planType=basic&`}
                        />
                      )}
                    </Tab>
                    <Tab eventKey="premium" title="Premium Users">
                      {key === "premium" && (
                        <CustomDataTable
                          columns={columns}
                          editUrl="/massage_therapist"
                          deleteUrl="v1/user"
                          searchQuery={contents}
                          type="provider"
                          value="massage_therapist"
                          label="Massage Therapist"
                          pathName={window.location.pathname}
                          fetchContentsUrl={`/v1/provider/adminlist?userType=massage_therapist&deleted=false&planType=premium&`}
                        />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Massagetherapist;
