import clsx from "clsx";
import _ from "lodash";
import React from "react";

export default function TextInput({
  errors,
  label,
  register,
  isEmail,
  className,
  dataChange,
  setValue,
  name,
  mandatory,
  maxlength,
  placeholder,
  disabled,
  type,
  setVideoUrl,
}) {
  return (
    <>
      {mandatory ? (
        <>
          <label for="" className="form-label">
            {label} <span className="text-danger">*</span>
          </label>
          <input
            maxLength={maxlength}
            placeholder={placeholder}
            type={type !== undefined ? type : "text"}
            className={clsx(
              `form-control ${className}`,
              _.get(errors, name) && "is-invalid"
            )}
            disabled={disabled}
            {...register(name)}
          />
          <div className="invalid-feedback">{_.get(errors, name)?.message}</div>
        </>
      ) : (
        <>
          <label for="" className="form-label">
            {label}
          </label>
          <input
            maxLength={maxlength}
            placeholder={placeholder}
            type={type !== undefined ? type : "text"}
            className={
              className ? `${className} form-control` : "form-control "
            }
            onChange={(e) => {
              if (e.target.value !== "") {
                setVideoUrl(true);
              } else {
                setVideoUrl(false);
              }
              setValue(name, e.target.value);
            }}
            disabled={disabled}
            {...register(name, {
              onChange: (e) => {
                if (e.target.value !== "") {
                  setVideoUrl(true);
                } else {
                  setVideoUrl(false);
                }
                if (isEmail) {
                  dataChange(name, e.target.value);
                }
              },
            })}
          />
        </>
      )}
    </>
  );
}
