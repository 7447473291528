import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomDataTable from "../Components/custom/CustomDataTable";
import SideNavigation from "../Components/layout/SideNavigation";
import TopNavigation from "../Components/layout/TopNavigation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const { useState } = React;
const PressReleasesList = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("request");
  const [contents] = useState([]);

  const schema = yup
    .object({
      searchQuery: yup.string(),
    })
    .required();

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });
  const columns = [
    { dataField: "id", text: "Id", sort: false, hidden: true },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span>{row.title}</span>
          </>
        );
      },
    },
    {
      dataField: "text",
      text: "Content",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div
              className="press"
              dangerouslySetInnerHTML={{
                __html: row.text,
              }}
            ></div>
            <div className="px-3">
              <h5>...</h5>
            </div>
          </>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (date) => {
        return <>{moment(date).format("ll")}</>;
      },
    },
  ];
  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <div className="container-fluid">
          <TopNavigation title="News" />
          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="box">
                <div className="box-content">
                  <div className="d-flex justify-content-end p-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        navigate("/add-news", {
                          state: {
                            value: "news",
                            label: "News",
                            pathName: window.location.pathname,
                          },
                        })
                      }
                    >
                      Add New News
                    </button>
                  </div>
                  <CustomDataTable
                    columns={columns}
                    editUrl="/edit-news"
                    deleteUrl={"v1/press-release"}
                    value="news"
                    label="News"
                    role="admin"
                    searchQuery={contents}
                    pathName={window.location.pathname}
                    fetchContentsUrl={`v1/press-release?&sortBy=createdAt:desc`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressReleasesList;
