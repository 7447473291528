import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import Swal from "sweetalert2";
import * as yup from "yup";
import axios from "../../util/axiosInst";
import LoadingScreen from "../custom/LoadingScreen copy";
import MediaPreview from "../custom/MediaPreview";
import SideNavigation from "../layout/SideNavigation";
import TopNavigation from "../layout/TopNavigation";
import ImageCropModal from "../image/ImageCropModal";

const AddPost = () => {
  // variables and constants
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [editId, setEditId] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [fileSrc, setFileSrc] = useState("");
  const [blogCategories, setBlogCategories] = useState([]);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(
    location.pathname.includes("edit") ? true : false
  );

  //validation schema
  const schema = yup
    .object({
      banner: yup.string().nullable(),
      title: yup.string().required("Title is required field"),
      categoryId: yup.string(),
      content: yup.string().required("Content is a required field"),
      active: yup.boolean(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const isCategoryAvailable = watch("categoryId");
  // getting list of categories
  const getBlogCategories = () => {
    axios.get(`v1/public/blog/categories/no-blogs`).then(({ data }) => {
      setBlogCategories(data?.results);
    });
  };

  //modules and formatting of editor.
  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["link", "image"],
      ["clean"],
    ],

    clipboard: {
      matchVisual: false,
    },
  };

  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  let showTooltip = (el) => {
    let tool = el.className.replace("ql-", "");
    let tooltips = {
      1: "Header H1",
      2: "Header H2",

      font: "font",
      size: "size",
      bold: "Bold ",
      italic: "Italic ",
      underline: "Underline ",
      strike: "Strike",

      blockquote: "Blockquote",
      ordered: "Numbering",
      bullet: "Bullets",
      "-1": " Decrease indent",
      "+1": " Increase indent",
      link: "Link",
      image: "Image",
      clean: "Clear Text Formats",
    };
    let heading = el.value;
    if (tooltips[heading]) {
      el.setAttribute("aria-label", tooltips[heading]);
    }

    if (tooltips[tool]) {
      el.setAttribute("aria-label", tooltips[tool]);
    }
  };

  let toolbarElement = document.querySelector(".ql-toolbar");
  if (toolbarElement) {
    let matches = toolbarElement.querySelectorAll("button");
    for (let el of matches) {
      showTooltip(el);
    }
  }

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setFileSrc(e.target.files[0]);
      document.querySelector("#imgCrop").classList.add("show");
      document.querySelector("#imgCrop").style.display = "block";
    }
  };
  //modules and formatting of editor end here.
  // image uploading is finish
  const onUploadFinish = (response, file, fieldName) => {
    const uploadElement = document.getElementById(fieldName);
    uploadElement.labels[0].innerHTML = `
      <span className="border" role="button">
        Change
      </span>`;
    setValue(fieldName, `/blogs/${file.name}`);
  };

  // image uploading inprocess
  const onProgress = (percent, status, file, id) => {
    const uploadElement = document.getElementById(id);
    uploadElement.labels[0].textContent = `${status} - ${percent}%`;
  };

  //get the image url
  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/blogs/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => {
        callback({ signedUrl: data.url });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //form submit
  const onSubmit = async (formData) => {
    try {
      const dataToSend = {
        ...formData,
        createdBy: location.state.id,
      };
      if (editId) {
        await axios.patch(`/v1/blog/${editId}`, dataToSend);
        Swal.fire({
          icon: "success",
          title: "Updated successfully",
        });
      } else {
        await axios.post("/v1/blog", dataToSend);
        Swal.fire({
          icon: "success",
          title: "Created successfully",
          wordbreak: "break-word",
        });
      }
      navigate(`/post/${location.state.id}`, {
        state: location.state.pathName,
      });
    } catch (e) {}
  };

  //getting details of blog
  const getBlogDetails = async () => {
    if (params && params.id) {
      setEditId(params.id);
      const { data } = await axios.get(`/v1/blog/${params.id}`);
      setContent(data.content);
      delete data.id;
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      setContent(data.content);
      setValue("content", data.content);
      setValue("title", data.title);
      setValue("active", data.active);
      setValue("banner", data.banner);
      setValue("categoryId", data?.categoryId);
    }
  };

  useEffect(() => {
    getBlogCategories();
    reset();
    getBlogDetails();
  }, []);

  //show the banner image
  const BannerImage = ({ control }) => {
    const banner = useWatch({
      control,
      name: "banner",
    });
    return (
      <div>
        <div>
          <span role="button">
            <MediaPreview fileName={banner} type="image" blog={true} />
          </span>
          <div>
            {!banner && (
              <label
                htmlFor="banner"
                className={`w-100 ${banner ? "" : "min-h-75"}`}
              >
                <span
                  role="button"
                  type="button"
                  className={`w-100 ${banner ? "" : "min-h-75"}`}
                >
                  <>
                    <label className="mb-1 w-100" style={{ cursor: "pointer" }}>
                      Set Thumnail
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept={"image/*"}
                        onChange={(e) => onSelectFile(e)}
                      />
                      <div className="border-dotted min-h-75  border rounded p-3 d-flex align-items-center justify-content-center flex-fill">
                        <div className="text-center text-light">
                          <h1>
                            <i className="fa fa-image" />
                          </h1>
                          <p className="mb-0">Upload an image</p>
                        </div>
                      </div>
                    </label>
                  </>
                </span>
              </label>
            )}
            {banner && (
              <span
                className=" text-danger mt-3 cursor-pointer"
                onClick={() => {
                  setValue("banner", "");
                }}
                type={"button"}
              >
                Remove
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SideNavigation />
      <div className="main-content">
        <TopNavigation title="Posts" />
        <div>
          <ImageCropModal
            setValue={setValue}
            imgSrc={imgSrc}
            fileSrc={fileSrc}
          />
          <div className="wrapper mb-4 flex-fill">
            <section className="h-100">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="d-flex justify-content-between">
                        {params && params.id ? (
                          <h4>Edit Posts</h4>
                        ) : (
                          <h4>Create Posts</h4>
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="bg-white border rounded p-3">
                      <LoadingScreen />
                    </div>
                  ) : (
                    <div className="row mt-3">
                      <div className="col-md-9">
                        <div className="bg-white border rounded p-3">
                          <div className="mb-3 col-sm-6">
                            <label className="mb-1">Title</label>
                            <input
                              type="text"
                              className={clsx(
                                "form-control",
                                errors.title && "is-invalid"
                              )}
                              placeholder="Title"
                              {...register("title")}
                            />
                            <div className="invalid-feedback">
                              {errors.title?.message}
                            </div>
                          </div>
                          {params?.id ? (
                            watch("categoryId") !== undefined &&
                            watch("categoryId") !== "" ? (
                              <div className="mb-3 col-sm-6">
                                <label className="mb-1">Categories</label>
                                <select
                                  className={clsx(
                                    "form-control px-2",
                                    errors.categoryId &&
                                      !isCategoryAvailable &&
                                      "is-invalid"
                                  )}
                                  {...register("categoryId")}
                                >
                                  <option value="">Select Categories</option>

                                  {blogCategories.map((cat, index) => {
                                    return (
                                      <option value={cat._id}>
                                        {cat.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="invalid-feedback">
                                  {errors.categoryId?.message}
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            <div className="mb-3 col-sm-6">
                              <label className="mb-1">Categories</label>
                              <select
                                className={clsx(
                                  "form-control px-2",
                                  errors.categoryId &&
                                    !isCategoryAvailable &&
                                    "is-invalid"
                                )}
                                {...register("categoryId")}
                              >
                                <option value="">Select Categories</option>

                                {blogCategories.map((cat, index) => {
                                  return (
                                    <option value={cat._id}>{cat.name}</option>
                                  );
                                })}
                              </select>
                              <div className="invalid-feedback">
                                {errors.categoryId?.message}
                              </div>
                            </div>
                          )}
                          <div className="mb-3">
                            <label className="mb-1">Content</label>
                            <ReactQuill
                              theme="snow"
                              {...register("content")}
                              modules={editorModules}
                              formats={editorFormats}
                              onChange={(value) => {
                                setContent(value);
                                setValue("content", value);
                              }}
                              className={clsx(errors.content && "is-invalid")}
                              value={content}
                            />
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.content?.message}
                            </div>
                          </div>
                          <div className="mb-3 p-3">
                            <input
                              id="flexCheckDefault"
                              className="form-check-input"
                              type="checkbox"
                              {...register("active")}
                            />
                            <label className="mb-1" for="flexCheckDefault">
                              Active
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="border rounded bg-white mb-3 p-3">
                          <div className="d-flex flex-column">
                            <BannerImage control={control} />
                            <Link
                              to={`/post/${location.state.id}`}
                              className="btn btn-outline-danger mt-3"
                              state={location.state.pathName}
                            >
                              Cancel
                            </Link>
                            <button
                              type="submit"
                              className="btn btn-outline-primary mt-3"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddPost;
